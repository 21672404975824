.sidebar-menu li{
    cursor: pointer;
}

.sidebar-submenu .selected{
    color: #fff;
    background: #fff;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%);
    transition: .3s;
    border-radius: 5px;
    position: relative;
}

.sidebar-header.selected {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%);
    color: #fff;
  }
/* .sidebar-submenu li.selected {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%) !important;
  }
   */
.search-icon{
    
    position: absolute;
    /* content: "\f002"; */
    font-family: FontAwesome;
    top: 3px;
    left: 22px;
    color: #8e8e8e;
}

a {
    text-decoration: none !important;
}

.flag-icon-nz {
    background-image: url(../../assets/nz.svg);
}

.flag-icon-is {
    background-image: url(../../assets/is.svg);
}

.flag-icon-um {
    background-image: url(../../assets/um.svg);
}

.page-main-header{
    position: relative;
    z-index: 1;
}
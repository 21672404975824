.box{
    border: 2px solid black;
    height: 30rem;
    max-height: 30rem;
    overflow-y: scroll;
    padding: 10px 20px;
}
.arrow{
    justify-content: center;
    display: flex;
    align-items: center;
}
.arrow svg{
    font-size: 2rem;
    cursor: pointer;
}
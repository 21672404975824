.theme-color {
    --theme-color: #5b8213
}

:root {
    --theme-color: #5b8213
}

@-webkit-keyframes textNoise1 {
    0% {
        -webkit-clip-path: inset(54% 0 42% 0);
        clip-path: inset(54% 0 42% 0)
    }

    10% {
        -webkit-clip-path: inset(31% 0 44% 0);
        clip-path: inset(31% 0 44% 0)
    }

    20% {
        -webkit-clip-path: inset(73% 0 54% 0);
        clip-path: inset(73% 0 54% 0)
    }

    30% {
        -webkit-clip-path: inset(34% 0 33% 0);
        clip-path: inset(34% 0 33% 0)
    }

    40% {
        -webkit-clip-path: inset(81% 0 99% 0);
        clip-path: inset(81% 0 99% 0)
    }

    50% {
        -webkit-clip-path: inset(76% 0 93% 0);
        clip-path: inset(76% 0 93% 0)
    }

    60% {
        -webkit-clip-path: inset(27% 0 16% 0);
        clip-path: inset(27% 0 16% 0)
    }

    70% {
        -webkit-clip-path: inset(40% 0 34% 0);
        clip-path: inset(40% 0 34% 0)
    }

    80% {
        -webkit-clip-path: inset(20% 0 100% 0);
        clip-path: inset(20% 0 100% 0)
    }

    90% {
        -webkit-clip-path: inset(75% 0 25% 0);
        clip-path: inset(75% 0 25% 0)
    }

    100% {
        -webkit-clip-path: inset(83% 0 78% 0);
        clip-path: inset(83% 0 78% 0)
    }
}

@keyframes textNoise1 {
    0% {
        -webkit-clip-path: inset(54% 0 42% 0);
        clip-path: inset(54% 0 42% 0)
    }

    10% {
        -webkit-clip-path: inset(31% 0 44% 0);
        clip-path: inset(31% 0 44% 0)
    }

    20% {
        -webkit-clip-path: inset(73% 0 54% 0);
        clip-path: inset(73% 0 54% 0)
    }

    30% {
        -webkit-clip-path: inset(34% 0 33% 0);
        clip-path: inset(34% 0 33% 0)
    }

    40% {
        -webkit-clip-path: inset(81% 0 99% 0);
        clip-path: inset(81% 0 99% 0)
    }

    50% {
        -webkit-clip-path: inset(76% 0 93% 0);
        clip-path: inset(76% 0 93% 0)
    }

    60% {
        -webkit-clip-path: inset(27% 0 16% 0);
        clip-path: inset(27% 0 16% 0)
    }

    70% {
        -webkit-clip-path: inset(40% 0 34% 0);
        clip-path: inset(40% 0 34% 0)
    }

    80% {
        -webkit-clip-path: inset(20% 0 100% 0);
        clip-path: inset(20% 0 100% 0)
    }

    90% {
        -webkit-clip-path: inset(75% 0 25% 0);
        clip-path: inset(75% 0 25% 0)
    }

    100% {
        -webkit-clip-path: inset(83% 0 78% 0);
        clip-path: inset(83% 0 78% 0)
    }
}

@-webkit-keyframes textNoise2 {
    0% {
        -webkit-clip-path: inset(26% 0 38% 0);
        clip-path: inset(26% 0 38% 0)
    }

    10% {
        -webkit-clip-path: inset(24% 0 62% 0);
        clip-path: inset(24% 0 62% 0)
    }

    20% {
        -webkit-clip-path: inset(45% 0 36% 0);
        clip-path: inset(45% 0 36% 0)
    }

    30% {
        -webkit-clip-path: inset(81% 0 10% 0);
        clip-path: inset(81% 0 10% 0)
    }

    40% {
        -webkit-clip-path: inset(80% 0 8% 0);
        clip-path: inset(80% 0 8% 0)
    }

    50% {
        -webkit-clip-path: inset(43% 0 48% 0);
        clip-path: inset(43% 0 48% 0)
    }

    60% {
        -webkit-clip-path: inset(77% 0 31% 0);
        clip-path: inset(77% 0 31% 0)
    }

    70% {
        -webkit-clip-path: inset(45% 0 30% 0);
        clip-path: inset(45% 0 30% 0)
    }

    80% {
        -webkit-clip-path: inset(22% 0 58% 0);
        clip-path: inset(22% 0 58% 0)
    }

    90% {
        -webkit-clip-path: inset(81% 0 64% 0);
        clip-path: inset(81% 0 64% 0)
    }

    100% {
        -webkit-clip-path: inset(34% 0 55% 0);
        clip-path: inset(34% 0 55% 0)
    }
}

@keyframes textNoise2 {
    0% {
        -webkit-clip-path: inset(26% 0 38% 0);
        clip-path: inset(26% 0 38% 0)
    }

    10% {
        -webkit-clip-path: inset(24% 0 62% 0);
        clip-path: inset(24% 0 62% 0)
    }

    20% {
        -webkit-clip-path: inset(45% 0 36% 0);
        clip-path: inset(45% 0 36% 0)
    }

    30% {
        -webkit-clip-path: inset(81% 0 10% 0);
        clip-path: inset(81% 0 10% 0)
    }

    40% {
        -webkit-clip-path: inset(80% 0 8% 0);
        clip-path: inset(80% 0 8% 0)
    }

    50% {
        -webkit-clip-path: inset(43% 0 48% 0);
        clip-path: inset(43% 0 48% 0)
    }

    60% {
        -webkit-clip-path: inset(77% 0 31% 0);
        clip-path: inset(77% 0 31% 0)
    }

    70% {
        -webkit-clip-path: inset(45% 0 30% 0);
        clip-path: inset(45% 0 30% 0)
    }

    80% {
        -webkit-clip-path: inset(22% 0 58% 0);
        clip-path: inset(22% 0 58% 0)
    }

    90% {
        -webkit-clip-path: inset(81% 0 64% 0);
        clip-path: inset(81% 0 64% 0)
    }

    100% {
        -webkit-clip-path: inset(34% 0 55% 0);
        clip-path: inset(34% 0 55% 0)
    }
}



.text-overline {
    text-decoration: overline
}

.text-line-through {
    text-decoration: line-through
}

.text-underline {
    text-decoration: underline
}

.text-dashed {
    -webkit-text-decoration: dashed;
    text-decoration: dashed
}

.text-blink {
    text-decoration: blink
}

.text-dotted {
    -webkit-text-decoration: dotted;
    text-decoration: dotted
}

.text-initial {
    text-decoration: initial
}

.text-none {
    text-decoration: none
}

.text-solid {
    -webkit-text-decoration: solid;
    text-decoration: solid
}

.text-wavy {
    -webkit-text-decoration: wavy;
    text-decoration: wavy
}

.text-inherit {
    text-decoration: inherit
}

.text-double {
    -webkit-text-decoration: double;
    text-decoration: double
}

.baseline {
    vertical-align: baseline
}

.sub {
    vertical-align: sub
}

.super {
    vertical-align: super
}

.top {
    vertical-align: top
}

.text-top {
    vertical-align: text-top
}

.middle {
    vertical-align: middle
}

.bottom {
    vertical-align: bottom
}

.text-bottom {
    vertical-align: text-bottom
}

.initial {
    vertical-align: initial
}

.inherit {
    vertical-align: inherit
}

.p-static {
    position: static
}

.p-absolute {
    position: absolute
}

.p-fixed {
    position: fixed
}

.p-relative {
    position: relative
}

.p-initial {
    position: initial
}

.p-inherit {
    position: inherit
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.f-none {
    float: none
}

.o-hidden {
    overflow: hidden
}

.o-visible {
    overflow: visible
}

.o-auto {
    overflow: auto
}

.img-20 {
    width: 20px !important
}

.img-30 {
    width: 30px !important
}

.img-40 {
    width: 40px !important
}

.img-50 {
    width: 50px !important
}

.img-60 {
    width: 60px !important
}

.img-70 {
    width: 70px !important
}

.img-80 {
    width: 80px !important
}

.img-90 {
    width: 90px !important
}

.img-100 {
    width: 100px !important
}

.img-110 {
    width: 110px !important
}

.font-primary {
    color: #ff4c3b !important
}

.font-secondary {
    color: #13c9ca !important
}

.font-success {
    color: #81ba00 !important
}

.font-danger {
    color: #a5a5a5 !important
}

.font-info {
    color: #00a8ff !important
}

.font-light {
    color: #f8f8f9 !important
}

.font-dark {
    color: #2a3142 !important
}

.font-warning {
    color: #ffbc58 !important
}

.label {
    border-radius: 2px;
    color: #fff;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
    text-transform: capitalize
}

.label-theme {
    background-color: #ff4c3b
}

.label-primary {
    background-color: #ff4c3b
}

.label-secondary {
    background-color: #13c9ca
}

.label-success {
    background-color: #81ba00
}

.label-danger {
    background-color: #a5a5a5
}

.label-info {
    background-color: #00a8ff
}

.label-light {
    background-color: #f8f8f9
}

.label-dark {
    background-color: #2a3142
}

.label-warning {
    background-color: #ffbc58
}

.badge-primary {
    background-color: var(--theme-color)
}

.badge-secondary {
    background-color: #13c9ca
}

.badge-success {
    background-color: #81ba00
}

.badge-danger {
    background-color: #a5a5a5
}

.badge-info {
    background-color: #00a8ff
}

.badge-light {
    background-color: #f8f8f9
}

.badge-dark {
    background-color: #2a3142
}

.badge-warning {
    background-color: #ffbc58
}

.bg-primary {
    background-color: var(--theme-color);
    color: #fff
}

.bg-secondary {
    background-color: #13c9ca !important;
    color: #fff
}

.bg-success {
    background-color: #81ba00 !important;
    color: #fff
}

.bg-danger {
    background-color: #a5a5a5 !important;
    color: #fff
}

.bg-info {
    background-color: #00a8ff !important;
    color: #fff
}

.bg-light {
    background-color: #f8f8f9 !important;
    color: #fff
}

.bg-dark {
    background-color: #2a3142 !important;
    color: #fff
}

.bg-warning {
    background-color: #ffbc58 !important;
    color: #fff
}

.btn-primary {
    background-color:var(--theme-color) !important;
    border-color: var(--theme-color) !important
}

.btn-primary.disabled {
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important
}
/* 
.btn-primary:disabled {
    background-color: #ff4c3b !important;
    border-color: #ff4c3b !important
} */

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important
}

.btn-primary:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #ffd8d4;
    box-shadow: 0 0 0 0.2rem #ffd8d4
}

.btn-secondary {
    background-color: #13c9ca !important;
    border-color: #13c9ca !important
}

.btn-secondary.disabled {
    background-color: #13c9ca !important;
    border-color: #13c9ca !important
}

.btn-secondary:disabled {
    background-color: #13c9ca !important;
    border-color: #13c9ca !important
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
    background-color: #0f9b9b !important;
    border-color: #0f9b9b !important
}

.btn-secondary:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #83f3f3;
    box-shadow: 0 0 0 0.2rem #83f3f3
}

.btn-success {
    background-color: #81ba00 !important;
    border-color: #81ba00 !important
}

.btn-success.disabled {
    background-color: #81ba00 !important;
    border-color: #81ba00 !important
}

.btn-success:disabled {
    background-color: #81ba00 !important;
    border-color: #81ba00 !important
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active {
    background-color: #5e8700 !important;
    border-color: #5e8700 !important
}

.btn-success:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #cbff54;
    box-shadow: 0 0 0 0.2rem #cbff54
}

/* .btn-danger {
    background-color: #a5a5a5 !important;
    border-color: #a5a5a5 !important
} */



.btn-info {
    background-color: #00a8ff !important;
    border-color: #00a8ff !important
}

.btn-info.disabled {
    background-color: #00a8ff !important;
    border-color: #00a8ff !important
}

.btn-info:disabled {
    background-color: #00a8ff !important;
    border-color: #00a8ff !important
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active {
    background-color: #0086cc !important;
    border-color: #0086cc !important
}

.btn-info:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #99dcff;
    box-shadow: 0 0 0 0.2rem #99dcff
}

.btn-light {
    background-color: #f8f8f9 !important;
    border-color: #f8f8f9 !important
}

.btn-light.disabled {
    background-color: #f8f8f9 !important;
    border-color: #f8f8f9 !important
}

.btn-light:disabled {
    background-color: #f8f8f9 !important;
    border-color: #f8f8f9 !important
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active {
    background-color: #dddde1 !important;
    border-color: #dddde1 !important
}

.btn-light:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #fff;
    box-shadow: 0 0 0 0.2rem #fff
}

.btn-dark {
    background-color: #2a3142 !important;
    border-color: #2a3142 !important
}

.btn-dark.disabled {
    background-color: #2a3142 !important;
    border-color: #2a3142 !important
}

.btn-dark:disabled {
    background-color: #2a3142 !important;
    border-color: #2a3142 !important
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active {
    background-color: #161a23 !important;
    border-color: #161a23 !important
}

.btn-dark:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #67779e;
    box-shadow: 0 0 0 0.2rem #67779e
}

.btn-warning {
    background-color: #ffbc58 !important;
    border-color: #ffbc58 !important
}

.btn-warning.disabled {
    background-color: #ffbc58 !important;
    border-color: #ffbc58 !important
}

.btn-warning:disabled {
    background-color: #ffbc58 !important;
    border-color: #ffbc58 !important
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active {
    background-color: #ffa825 !important;
    border-color: #ffa825 !important
}

.btn-warning:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #fff9f1;
    box-shadow: 0 0 0 0.2rem #fff9f1
}

.btn-outline-primary-2x {
    border-width: 2px;
    border-color: #ff4c3b;
    color: #ff4c3b;
    background-color: transparent
}

.btn-outline-primary-2x:hover,
.btn-outline-primary-2x:focus,
.btn-outline-primary-2x:active,
.btn-outline-primary-2x.active {
    color: #fff;
    background-color: #ff1d08 !important;
    border-color: #ff1d08 !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-secondary-2x {
    border-width: 2px;
    border-color: #13c9ca;
    color: #13c9ca;
    background-color: transparent
}

.btn-outline-secondary-2x:hover,
.btn-outline-secondary-2x:focus,
.btn-outline-secondary-2x:active,
.btn-outline-secondary-2x.active {
    color: #fff;
    background-color: #0f9b9b !important;
    border-color: #0f9b9b !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-success-2x {
    border-width: 2px;
    border-color: #81ba00;
    color: #81ba00;
    background-color: transparent
}

.btn-outline-success-2x:hover,
.btn-outline-success-2x:focus,
.btn-outline-success-2x:active,
.btn-outline-success-2x.active {
    color: #fff;
    background-color: #5e8700 !important;
    border-color: #5e8700 !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-danger-2x {
    border-width: 2px;
    border-color: #a5a5a5;
    color: #a5a5a5;
    background-color: transparent
}

.btn-outline-danger-2x:hover,
.btn-outline-danger-2x:focus,
.btn-outline-danger-2x:active,
.btn-outline-danger-2x.active {
    color: #fff;
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-info-2x {
    border-width: 2px;
    border-color: #00a8ff;
    color: #00a8ff;
    background-color: transparent
}

.btn-outline-info-2x:hover,
.btn-outline-info-2x:focus,
.btn-outline-info-2x:active,
.btn-outline-info-2x.active {
    color: #fff;
    background-color: #0086cc !important;
    border-color: #0086cc !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-light-2x {
    border-width: 2px;
    border-color: #f8f8f9;
    color: #f8f8f9;
    background-color: transparent
}

.btn-outline-light-2x:hover,
.btn-outline-light-2x:focus,
.btn-outline-light-2x:active,
.btn-outline-light-2x.active {
    color: #fff;
    background-color: #dddde1 !important;
    border-color: #dddde1 !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-dark-2x {
    border-width: 2px;
    border-color: #2a3142;
    color: #2a3142;
    background-color: transparent
}

.btn-outline-dark-2x:hover,
.btn-outline-dark-2x:focus,
.btn-outline-dark-2x:active,
.btn-outline-dark-2x.active {
    color: #fff;
    background-color: #161a23 !important;
    border-color: #161a23 !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-warning-2x {
    border-width: 2px;
    border-color: #ffbc58;
    color: #ffbc58;
    background-color: transparent
}

.btn-outline-warning-2x:hover,
.btn-outline-warning-2x:focus,
.btn-outline-warning-2x:active,
.btn-outline-warning-2x.active {
    color: #fff;
    background-color: #ffa825 !important;
    border-color: #ffa825 !important;
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn-outline-primary {
    border-color: #ff4c3b;
    color: #ff4c3b;
    background-color: transparent
}

.btn-outline-primary.disabled {
    color: #ff4c3b
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active {
    color: #fff;
    background-color: #ff1d08 !important;
    border-color: #ff1d08 !important
}

.btn-outline-secondary {
    border-color: #13c9ca;
    color: #13c9ca;
    background-color: transparent
}

.btn-outline-secondary.disabled {
    color: #13c9ca
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active {
    color: #fff;
    background-color: #0f9b9b !important;
    border-color: #0f9b9b !important
}

.btn-outline-success {
    border-color: #81ba00;
    color: #81ba00;
    background-color: transparent
}

.btn-outline-success.disabled {
    color: #81ba00
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active {
    color: #fff;
    background-color: #5e8700 !important;
    border-color: #5e8700 !important
}

.btn-outline-danger {
    border-color: #a5a5a5;
    color: #a5a5a5;
    background-color: transparent
}

.btn-outline-danger.disabled {
    color: #a5a5a5
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active {
    color: #fff;
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important
}

.btn-outline-info {
    border-color: #00a8ff;
    color: #00a8ff;
    background-color: transparent
}

.btn-outline-info.disabled {
    color: #00a8ff
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active {
    color: #fff;
    background-color: #0086cc !important;
    border-color: #0086cc !important
}

.btn-outline-light {
    border-color: #f8f8f9;
    color: #f8f8f9;
    background-color: transparent;
    color: #2a3142
}

.btn-outline-light.disabled {
    color: #f8f8f9
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active {
    color: #fff;
    background-color: #dddde1 !important;
    border-color: #dddde1 !important
}

.btn-outline-dark {
    border-color: #2a3142;
    color: #2a3142;
    background-color: transparent
}

.btn-outline-dark.disabled {
    color: #2a3142
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active {
    color: #fff;
    background-color: #161a23 !important;
    border-color: #161a23 !important
}

.btn-outline-warning {
    border-color: #ffbc58;
    color: #ffbc58;
    background-color: transparent
}

.btn-outline-warning.disabled {
    color: #ffbc58
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active {
    color: #fff;
    background-color: #ffa825 !important;
    border-color: #ffa825 !important
}

.btn-primary:not([disabled]):not(.disabled).active {
    background-color: #ff1d08;
    border-color: #ff1d08;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-primary:not([disabled]):not(.disabled).active:hover,
.btn-primary:not([disabled]):not(.disabled).active:focus,
.btn-primary:not([disabled]):not(.disabled).active:active,
.btn-primary:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #ff1d08;
    border-color: #ff1d08
}

.btn-secondary:not([disabled]):not(.disabled).active {
    background-color: #0f9b9b;
    border-color: #0f9b9b;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-secondary:not([disabled]):not(.disabled).active:hover,
.btn-secondary:not([disabled]):not(.disabled).active:focus,
.btn-secondary:not([disabled]):not(.disabled).active:active,
.btn-secondary:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #0f9b9b;
    border-color: #0f9b9b
}

.btn-success:not([disabled]):not(.disabled).active {
    background-color: #5e8700;
    border-color: #5e8700;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-success:not([disabled]):not(.disabled).active:hover,
.btn-success:not([disabled]):not(.disabled).active:focus,
.btn-success:not([disabled]):not(.disabled).active:active,
.btn-success:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #5e8700;
    border-color: #5e8700
}

.btn-danger:not([disabled]):not(.disabled).active {
    background-color: #8c8c8c;
    border-color: #8c8c8c;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-danger:not([disabled]):not(.disabled).active:hover,
.btn-danger:not([disabled]):not(.disabled).active:focus,
.btn-danger:not([disabled]):not(.disabled).active:active,
.btn-danger:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #8c8c8c;
    border-color: #8c8c8c
}

.btn-info:not([disabled]):not(.disabled).active {
    background-color: #0086cc;
    border-color: #0086cc;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-info:not([disabled]):not(.disabled).active:hover,
.btn-info:not([disabled]):not(.disabled).active:focus,
.btn-info:not([disabled]):not(.disabled).active:active,
.btn-info:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #0086cc;
    border-color: #0086cc
}

.btn-light:not([disabled]):not(.disabled).active {
    background-color: #dddde1;
    border-color: #dddde1;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-light:not([disabled]):not(.disabled).active:hover,
.btn-light:not([disabled]):not(.disabled).active:focus,
.btn-light:not([disabled]):not(.disabled).active:active,
.btn-light:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #dddde1;
    border-color: #dddde1
}

.btn-dark:not([disabled]):not(.disabled).active {
    background-color: #161a23;
    border-color: #161a23;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-dark:not([disabled]):not(.disabled).active:hover,
.btn-dark:not([disabled]):not(.disabled).active:focus,
.btn-dark:not([disabled]):not(.disabled).active:active,
.btn-dark:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #161a23;
    border-color: #161a23
}

.btn-warning:not([disabled]):not(.disabled).active {
    background-color: #ffa825;
    border-color: #ffa825;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

.btn-warning:not([disabled]):not(.disabled).active:hover,
.btn-warning:not([disabled]):not(.disabled).active:focus,
.btn-warning:not([disabled]):not(.disabled).active:active,
.btn-warning:not([disabled]):not(.disabled).active.active {
    color: #fff;
    background-color: #ffa825;
    border-color: #ffa825
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
    background-color: #ff4c3b;
    border-color: #ff4c3b;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #ff1d08 !important;
    border-color: #ff1d08 !important
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
    background-color: #13c9ca;
    border-color: #13c9ca;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #0f9b9b !important;
    border-color: #0f9b9b !important
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
    background-color: #81ba00;
    border-color: #81ba00;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-success-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #5e8700 !important;
    border-color: #5e8700 !important
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
    background-color: #a5a5a5;
    border-color: #a5a5a5;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
    background-color: #00a8ff;
    border-color: #00a8ff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-info-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #0086cc !important;
    border-color: #0086cc !important
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
    background-color: #f8f8f9;
    border-color: #f8f8f9;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-light-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #dddde1 !important;
    border-color: #dddde1 !important
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
    background-color: #2a3142;
    border-color: #2a3142;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #161a23 !important;
    border-color: #161a23 !important
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
    background-color: #ffbc58;
    border-color: #ffbc58;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:active,
.btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
    color: #fff;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background-color: #ffa825 !important;
    border-color: #ffa825 !important
}

table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
    border-bottom: 1px solid #ff4c3b
}

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
    border-bottom: 1px solid #13c9ca
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
    border-bottom: 1px solid #81ba00
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
    border-bottom: 1px solid #a5a5a5
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
    border-bottom: 1px solid #00a8ff
}

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
    border-bottom: 1px solid #f8f8f9
}

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
    border-bottom: 1px solid #2a3142
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
    border-bottom: 1px solid #ffbc58
}

.table-styling .table-primary,
.table-styling.table-primary {
    background-color: #ff4c3b;
    color: #fff;
    border: 3px solid #ff4c3b
}

.table-styling .table-primary thead,
.table-styling.table-primary thead {
    background-color: #ff1d08;
    border: 3px solid #ff1d08
}

.table-styling .table-secondary,
.table-styling.table-secondary {
    background-color: #13c9ca;
    color: #fff;
    border: 3px solid #13c9ca
}

.table-styling .table-secondary thead,
.table-styling.table-secondary thead {
    background-color: #0f9b9b;
    border: 3px solid #0f9b9b
}

.table-styling .table-success,
.table-styling.table-success {
    background-color: #81ba00;
    color: #fff;
    border: 3px solid #81ba00
}

.table-styling .table-success thead,
.table-styling.table-success thead {
    background-color: #5e8700;
    border: 3px solid #5e8700
}

.table-styling .table-danger,
.table-styling.table-danger {
    background-color: #a5a5a5;
    color: #fff;
    border: 3px solid #a5a5a5
}

.table-styling .table-danger thead,
.table-styling.table-danger thead {
    background-color: #8c8c8c;
    border: 3px solid #8c8c8c
}

.table-styling .table-info,
.table-styling.table-info {
    background-color: #00a8ff;
    color: #fff;
    border: 3px solid #00a8ff
}

.table-styling .table-info thead,
.table-styling.table-info thead {
    background-color: #0086cc;
    border: 3px solid #0086cc
}

.table-styling .table-light,
.table-styling.table-light {
    background-color: #f8f8f9;
    color: #fff;
    border: 3px solid #f8f8f9
}

.table-styling .table-light thead,
.table-styling.table-light thead {
    background-color: #dddde1;
    border: 3px solid #dddde1
}

.table-styling .table-dark,
.table-styling.table-dark {
    background-color: #2a3142;
    color: #fff;
    border: 3px solid #2a3142
}

.table-styling .table-dark thead,
.table-styling.table-dark thead {
    background-color: #161a23;
    border: 3px solid #161a23
}

.table-styling .table-warning,
.table-styling.table-warning {
    background-color: #ffbc58;
    color: #fff;
    border: 3px solid #ffbc58
}

.table-styling .table-warning thead,
.table-styling.table-warning thead {
    background-color: #ffa825;
    border: 3px solid #ffa825
}

.b-primary {
    border: 1px solid #ff4c3b !important
}

.b-t-primary {
    border-top: 1px solid #ff4c3b !important
}

.b-b-primary {
    border-bottom: 1px solid #ff4c3b !important
}

.b-l-primary {
    border-left: 1px solid #ff4c3b !important
}

.b-r-primary {
    border-right: 1px solid #ff4c3b !important
}

.b-secondary {
    border: 1px solid #13c9ca !important
}

.b-t-secondary {
    border-top: 1px solid #13c9ca !important
}

.b-b-secondary {
    border-bottom: 1px solid #13c9ca !important
}

.b-l-secondary {
    border-left: 1px solid #13c9ca !important
}

.b-r-secondary {
    border-right: 1px solid #13c9ca !important
}

.b-success {
    border: 1px solid #81ba00 !important
}

.b-t-success {
    border-top: 1px solid #81ba00 !important
}

.b-b-success {
    border-bottom: 1px solid #81ba00 !important
}

.b-l-success {
    border-left: 1px solid #81ba00 !important
}

.b-r-success {
    border-right: 1px solid #81ba00 !important
}

.b-danger {
    border: 1px solid #a5a5a5 !important
}

.b-t-danger {
    border-top: 1px solid #a5a5a5 !important
}

.b-b-danger {
    border-bottom: 1px solid #a5a5a5 !important
}

.b-l-danger {
    border-left: 1px solid #a5a5a5 !important
}

.b-r-danger {
    border-right: 1px solid #a5a5a5 !important
}

.b-info {
    border: 1px solid #00a8ff !important
}

.b-t-info {
    border-top: 1px solid #00a8ff !important
}

.b-b-info {
    border-bottom: 1px solid #00a8ff !important
}

.b-l-info {
    border-left: 1px solid #00a8ff !important
}

.b-r-info {
    border-right: 1px solid #00a8ff !important
}

.b-light {
    border: 1px solid #f8f8f9 !important
}

.b-t-light {
    border-top: 1px solid #f8f8f9 !important
}

.b-b-light {
    border-bottom: 1px solid #f8f8f9 !important
}

.b-l-light {
    border-left: 1px solid #f8f8f9 !important
}

.b-r-light {
    border-right: 1px solid #f8f8f9 !important
}

.b-dark {
    border: 1px solid #2a3142 !important
}

.b-t-dark {
    border-top: 1px solid #2a3142 !important
}

.b-b-dark {
    border-bottom: 1px solid #2a3142 !important
}

.b-l-dark {
    border-left: 1px solid #2a3142 !important
}

.b-r-dark {
    border-right: 1px solid #2a3142 !important
}

.b-warning {
    border: 1px solid #ffbc58 !important
}

.b-t-warning {
    border-top: 1px solid #ffbc58 !important
}

.b-b-warning {
    border-bottom: 1px solid #ffbc58 !important
}

.b-l-warning {
    border-left: 1px solid #ffbc58 !important
}

.b-r-warning {
    border-right: 1px solid #ffbc58 !important
}

.border-2 {
    border-width: 2px !important
}

.border-3 {
    border-width: 3px !important
}

.border-4 {
    border-width: 4px !important
}

.border-5 {
    border-width: 5px !important
}

.border-6 {
    border-width: 6px !important
}

.border-7 {
    border-width: 7px !important
}

.border-8 {
    border-width: 8px !important
}

.border-9 {
    border-width: 9px !important
}

.border-10 {
    border-width: 10px !important
}

.border-11 {
    border-width: 11px !important
}

.order-shape-primary {
    background-color: #ff4c3b;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-shape-secondary {
    background-color: #13c9ca;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-shape-success {
    background-color: #81ba00;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-shape-danger {
    background-color: #a5a5a5;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-shape-info {
    background-color: #00a8ff;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-shape-light {
    background-color: #f8f8f9;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-shape-dark {
    background-color: #2a3142;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-shape-warning {
    background-color: #ffbc58;
    width: 18px;
    height: 8px;
    margin-top: 7px;
    border-radius: 4px
}

.order-color-primary {
    background-color: #ff4c3b;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.order-color-secondary {
    background-color: #13c9ca;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.order-color-success {
    background-color: #81ba00;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.order-color-danger {
    background-color: #a5a5a5;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.order-color-info {
    background-color: #00a8ff;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.order-color-light {
    background-color: #f8f8f9;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.order-color-dark {
    background-color: #2a3142;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.order-color-warning {
    background-color: #ffbc58;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px
}

.btn-air-primary {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-primary:hover,
.btn-air-primary:active,
.btn-air-primary:not([disabled]):not(.disabled):active {
    background-color: #ff1d08;
    border-color: #ff1d08
}

.btn-air-secondary {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-secondary:hover,
.btn-air-secondary:active,
.btn-air-secondary:not([disabled]):not(.disabled):active {
    background-color: #0f9b9b;
    border-color: #0f9b9b
}

.btn-air-success {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-success:hover,
.btn-air-success:active,
.btn-air-success:not([disabled]):not(.disabled):active {
    background-color: #5e8700;
    border-color: #5e8700
}

.btn-air-danger {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-danger:hover,
.btn-air-danger:active,
.btn-air-danger:not([disabled]):not(.disabled):active {
    background-color: #8c8c8c;
    border-color: #8c8c8c
}

.btn-air-info {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-info:hover,
.btn-air-info:active,
.btn-air-info:not([disabled]):not(.disabled):active {
    background-color: #0086cc;
    border-color: #0086cc
}

.btn-air-light {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-light:hover,
.btn-air-light:active,
.btn-air-light:not([disabled]):not(.disabled):active {
    background-color: #dddde1;
    border-color: #dddde1
}

.btn-air-dark {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-dark:hover,
.btn-air-dark:active,
.btn-air-dark:not([disabled]):not(.disabled):active {
    background-color: #161a23;
    border-color: #161a23
}

.btn-air-warning {
    -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
    box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important
}

.btn-air-warning:hover,
.btn-air-warning:active,
.btn-air-warning:not([disabled]):not(.disabled):active {
    background-color: #ffa825;
    border-color: #ffa825
}

.input-air-primary {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-primary:focus {
    border-color: #ff4c3b
}

.input-air-secondary {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-secondary:focus {
    border-color: #13c9ca
}

.input-air-success {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-success:focus {
    border-color: #81ba00
}

.input-air-danger {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-danger:focus {
    border-color: #a5a5a5
}

.input-air-info {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-info:focus {
    border-color: #00a8ff
}

.input-air-light {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-light:focus {
    border-color: #f8f8f9
}

.input-air-dark {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-dark:focus {
    border-color: #2a3142
}

.input-air-warning {
    -webkit-box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
    box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important
}

.input-air-warning:focus {
    border-color: #ffbc58
}

.form-bg-primary {
    background: #ff4c3b;
    border-color: #ff4c3b;
    color: #fff
}

.form-bg-primary:focus {
    border-color: #ff4c3b;
    background: #ff4c3b;
    color: #fff
}

.form-bg-primary:focus:active {
    border-color: #ff4c3b;
    background: #ff4c3b;
    color: #fff
}

.form-bg-secondary {
    background: #13c9ca;
    border-color: #13c9ca;
    color: #fff
}

.form-bg-secondary:focus {
    border-color: #13c9ca;
    background: #13c9ca;
    color: #fff
}

.form-bg-secondary:focus:active {
    border-color: #13c9ca;
    background: #13c9ca;
    color: #fff
}

.form-bg-success {
    background: #81ba00;
    border-color: #81ba00;
    color: #fff
}

.form-bg-success:focus {
    border-color: #81ba00;
    background: #81ba00;
    color: #fff
}

.form-bg-success:focus:active {
    border-color: #81ba00;
    background: #81ba00;
    color: #fff
}

.form-bg-danger {
    background: #a5a5a5;
    border-color: #a5a5a5;
    color: #fff
}

.form-bg-danger:focus {
    border-color: #a5a5a5;
    background: #a5a5a5;
    color: #fff
}

.form-bg-danger:focus:active {
    border-color: #a5a5a5;
    background: #a5a5a5;
    color: #fff
}

.form-bg-info {
    background: #00a8ff;
    border-color: #00a8ff;
    color: #fff
}

.form-bg-info:focus {
    border-color: #00a8ff;
    background: #00a8ff;
    color: #fff
}

.form-bg-info:focus:active {
    border-color: #00a8ff;
    background: #00a8ff;
    color: #fff
}

.form-bg-light {
    background: #f8f8f9;
    border-color: #f8f8f9;
    color: #2a3142
}

.form-bg-light:focus {
    border-color: #f8f8f9;
    background: #f8f8f9;
    color: #2a3142
}

.form-bg-light:focus:active {
    border-color: #f8f8f9;
    background: #f8f8f9;
    color: #2a3142
}

.form-bg-dark {
    background: #2a3142;
    border-color: #2a3142;
    color: #fff
}

.form-bg-dark:focus {
    border-color: #2a3142;
    background: #2a3142;
    color: #fff
}

.form-bg-dark:focus:active {
    border-color: #2a3142;
    background: #2a3142;
    color: #fff
}

.form-bg-warning {
    background: #ffbc58;
    border-color: #ffbc58;
    color: #fff
}

.form-bg-warning:focus {
    border-color: #ffbc58;
    background: #ffbc58;
    color: #fff
}

.form-bg-warning:focus:active {
    border-color: #ffbc58;
    background: #ffbc58;
    color: #fff
}

body {
    font-family: Lato, sans-serif;
    position: relative;
    background: #fff;
    font-size: 14px
}

h1 {
    font-size: 60px;
    color: #222;
    font-weight: 700;
    text-transform: uppercase
}

h1 span {
    font-size: 107px;
    font-weight: 700;
    color: var(--theme-color)
}

h2 {
    font-size: 36px;
    color: #222;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em
}

h3 {
    font-size: 24px;
    font-weight: 400;
    color: #777;
    letter-spacing: 0.03em
}

h4 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1
}

h5 {
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: 24px;
    letter-spacing: 0.05em
}

h6 {
    font-size: 14px;
    font-weight: 400;
    color: #777 !important;
    line-height: 24px
}

ul {
    padding-left: 0;
    margin-bottom: 0
}

li {
    display: inline-block
}

p {
    font-size: 14px;
    color: #777;
    line-height: 1
}

a {
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    text-decoration: none
}

a:hover {
    text-decoration: none;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease
}

a:focus {
    outline: none
}

button:focus {
    outline: none
}

.btn-close:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

label {
    margin-bottom: 0.5rem
}

:focus {
    outline: none
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.text-theme {
    color: var(--theme-color) !important
}

.form-check-input:checked {
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

body {
    background-color: rgba(246, 246, 246, 0.6);
    font-size: 14px;
    overflow-x: hidden;
    font-family: work-Sans, sans-serif;
    color: #313131
}

ul {
    padding-left: 0px;
    list-style-type: none;
    margin-bottom: 0;
    padding-right: 0px
}

* a {
    color: #ff4c3b
}

p {
    font-size: 13px;
    line-height: 1.7;
    letter-spacing: .7px
}

code {
    color: #ff4c3b !important;
    background-color: #f8f8f9;
    padding: 3px;
    margin: 0 3px;
    border-radius: 2px
}

blockquote {
    border-left: 4px solid #e8ebf2;
    padding: 15px
}

blockquote.text-center {
    border: none;
    padding: 15px
}

blockquote.text-end {
    border-left: none;
    border-right: 4px solid #e8ebf2;
    padding: 15px
}

:focus {
    outline-color: #ff4c3b
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5
}

svg {
    vertical-align: baseline
}

input:focus {
    outline-color: rgba(0, 0, 0, 0)
}

section,
.section-t-space {
    padding-top: 70px
}

.section-b-space {
    padding-bottom: 70px
}

.table thead th {
    border-bottom: 1px solid #dee2e6 !important
}

.form-control {
    border-radius: 0
}

.small-section {
    padding-top: 35px;
    padding-bottom: 35px
}

.bg-light0 {
    background-color: #d0edff
}

.bg-light1 {
    background-color: #f1e7e6
}

.bg-light2 {
    background-color: #bfbfbf
}

.bg-loader {
    background-color: #f3f3f3
}

.badge-theme-color {
    background-color: var(--theme-color);
    color: white
}

.badge-grey-color {
    background-color: #929292;
    color: white
}

.overflow-hidden {
    overflow: hidden
}

del {
    font-size: 14px;
    color: 999999;
    font-weight: 400
}

.position-unset {
    position: unset !important
}

[data-notify="progressbar"] {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px
}

.progress-bar {
    background-color: #19a340
}

.progress-bar-info {
    background-color: #00829a
}

.container-fluid.custom-container {
    padding-left: 90px;
    padding-right: 90px
}

.left-sidebar_space {
    padding-left: 300px
}

.space_sm {
    padding-left: 240px;
    padding-top: 74px !important
}

.left-sidebar_space-xl {
    padding-left: 380px
}

.background-transparent {
    background-color: transparent !important
}

.bg-overlay {
    background-blend-mode: overlay;
    background-color: rgba(250, 250, 250, 0.98)
}

.bg-blue-light {
    background-color: rgba(40, 115, 239, 0.06)
}

.bg-theme {
    background-color: var(--theme-color);
    background-color: var(--theme-color2)
}

.blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms
}

.blur-up.lazyloaded {
    -webkit-filter: blur(0);
    filter: blur(0)
}

.form-group {
    margin-bottom: 15px
}

.form-check-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

.media .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.form-check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.breadcrumb {
    padding: 0.75rem 1rem
}

.row div[class*="col-"],
.row .col {
    position: relative
}

.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.txt-dark {
    color: #1b252a !important
}

.txt-success {
    color: #81ba00 !important
}

.txt-danger {
    color: #a5a5a5 !important
}

@-webkit-keyframes animate {
    from {
        background-position: 0 0
    }

    to {
        background-position: 0 500px
    }
}

@keyframes animate {
    from {
        background-position: 0 0
    }

    to {
        background-position: 0 500px
    }
}

@-webkit-keyframes loading {
    0% {
        border: 0 solid white;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease
    }

    20% {
        border: 8px solid white;
        width: 0;
        height: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease
    }

    100% {
        border: 8px solid white;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease
    }
}

@keyframes loading {
    0% {
        border: 0 solid white;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease
    }

    20% {
        border: 8px solid white;
        width: 0;
        height: 0;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease
    }

    100% {
        border: 8px solid white;
        width: 100%;
        height: 100%;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes grow {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes grow {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-webkit-keyframes shiny-btn1 {
    0% {
        -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
        opacity: 0
    }

    80% {
        -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
        opacity: 0.5
    }

    81% {
        -webkit-transform: scale(4) rotate(45deg);
        transform: scale(4) rotate(45deg);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(50) rotate(45deg);
        transform: scale(50) rotate(45deg);
        opacity: 0
    }
}

@keyframes shiny-btn1 {
    0% {
        -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
        opacity: 0
    }

    80% {
        -webkit-transform: scale(0) rotate(45deg);
        transform: scale(0) rotate(45deg);
        opacity: 0.5
    }

    81% {
        -webkit-transform: scale(4) rotate(45deg);
        transform: scale(4) rotate(45deg);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(50) rotate(45deg);
        transform: scale(50) rotate(45deg);
        opacity: 0
    }
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0)
    }

    1% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg)
    }

    3% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg)
    }

    5% {
        -webkit-transform: rotateZ(17deg);
        transform: rotateZ(17deg)
    }

    7% {
        -webkit-transform: rotateZ(-16deg);
        transform: rotateZ(-16deg)
    }

    9% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg)
    }

    11% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg)
    }

    13% {
        -webkit-transform: rotateZ(13deg);
        transform: rotateZ(13deg)
    }

    15% {
        -webkit-transform: rotateZ(-12deg);
        transform: rotateZ(-12deg)
    }

    17% {
        -webkit-transform: rotateZ(12deg);
        transform: rotateZ(12deg)
    }

    19% {
        -webkit-transform: rotateZ(-10deg);
        transform: rotateZ(-10deg)
    }

    21% {
        -webkit-transform: rotateZ(9deg);
        transform: rotateZ(9deg)
    }

    23% {
        -webkit-transform: rotateZ(-8deg);
        transform: rotateZ(-8deg)
    }

    25% {
        -webkit-transform: rotateZ(7deg);
        transform: rotateZ(7deg)
    }

    27% {
        -webkit-transform: rotateZ(-5deg);
        transform: rotateZ(-5deg)
    }

    29% {
        -webkit-transform: rotateZ(5deg);
        transform: rotateZ(5deg)
    }

    31% {
        -webkit-transform: rotateZ(-4deg);
        transform: rotateZ(-4deg)
    }

    33% {
        -webkit-transform: rotateZ(3deg);
        transform: rotateZ(3deg)
    }

    35% {
        -webkit-transform: rotateZ(-2deg);
        transform: rotateZ(-2deg)
    }

    37% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg)
    }

    39% {
        -webkit-transform: rotateZ(-1deg);
        transform: rotateZ(-1deg)
    }

    41% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg)
    }

    43% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0)
    }

    100% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0)
    }
}

@keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0)
    }

    1% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg)
    }

    3% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg)
    }

    5% {
        -webkit-transform: rotateZ(17deg);
        transform: rotateZ(17deg)
    }

    7% {
        -webkit-transform: rotateZ(-16deg);
        transform: rotateZ(-16deg)
    }

    9% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg)
    }

    11% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg)
    }

    13% {
        -webkit-transform: rotateZ(13deg);
        transform: rotateZ(13deg)
    }

    15% {
        -webkit-transform: rotateZ(-12deg);
        transform: rotateZ(-12deg)
    }

    17% {
        -webkit-transform: rotateZ(12deg);
        transform: rotateZ(12deg)
    }

    19% {
        -webkit-transform: rotateZ(-10deg);
        transform: rotateZ(-10deg)
    }

    21% {
        -webkit-transform: rotateZ(9deg);
        transform: rotateZ(9deg)
    }

    23% {
        -webkit-transform: rotateZ(-8deg);
        transform: rotateZ(-8deg)
    }

    25% {
        -webkit-transform: rotateZ(7deg);
        transform: rotateZ(7deg)
    }

    27% {
        -webkit-transform: rotateZ(-5deg);
        transform: rotateZ(-5deg)
    }

    29% {
        -webkit-transform: rotateZ(5deg);
        transform: rotateZ(5deg)
    }

    31% {
        -webkit-transform: rotateZ(-4deg);
        transform: rotateZ(-4deg)
    }

    33% {
        -webkit-transform: rotateZ(3deg);
        transform: rotateZ(3deg)
    }

    35% {
        -webkit-transform: rotateZ(-2deg);
        transform: rotateZ(-2deg)
    }

    37% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg)
    }

    39% {
        -webkit-transform: rotateZ(-1deg);
        transform: rotateZ(-1deg)
    }

    41% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg)
    }

    43% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0)
    }

    100% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0)
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1
    }

    70% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@keyframes blink {
    0% {
        opacity: 1
    }

    70% {
        opacity: 1
    }

    80% {
        opacity: 0
    }

    100% {
        opacity: 0
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    25% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: .1
    }

    50% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: .3
    }

    75% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: .5
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0
    }

    25% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: .1
    }

    50% {
        -webkit-transform: scale(0.5);
        transform: scale(0.5);
        opacity: .3
    }

    75% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        opacity: .5
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.btn-warning {
    color: #fff
}

.btn-warning:hover,
.btn-warning.disabled {
    color: #fff
}

.btn-primary-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#ff7164), color-stop(51%, #ff2712), to(#ff7164));
    background-image: linear-gradient(to right, #ff7164 0%, #ff2712 51%, #ff7164 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-primary-gradien:focus,
.btn-primary-gradien:active,
.btn-primary-gradien.active {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#ff7164), color-stop(0%), color-stop(#ff2712), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #ff7164, 0%, #ff2712, 100%, #fff) !important */
}

.btn-secondary-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#1ce9ea), color-stop(51%, #0fa4a5), to(#1ce9ea));
    background-image: linear-gradient(to right, #1ce9ea 0%, #0fa4a5 51%, #1ce9ea 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-secondary-gradien:hover,
.btn-secondary-gradien:focus,
.btn-secondary-gradien:active,
.btn-secondary-gradien.active,
.btn-secondary-gradien.hover {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#1ce9ea), color-stop(0%), color-stop(#0fa4a5), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #1ce9ea, 0%, #0fa4a5, 100%, #fff) !important */
}

.btn-success-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#9de300), color-stop(51%, #659100), to(#9de300));
    background-image: linear-gradient(to right, #9de300 0%, #659100 51%, #9de300 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-success-gradien:hover,
.btn-success-gradien:focus,
.btn-success-gradien:active,
.btn-success-gradien.active,
.btn-success-gradien.hover {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#9de300), color-stop(0%), color-stop(#659100), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #9de300, 0%, #659100, 100%, #fff) !important */
}

.btn-danger-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#b9b9b9), color-stop(51%, #919191), to(#b9b9b9));
    background-image: linear-gradient(to right, #b9b9b9 0%, #919191 51%, #b9b9b9 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-danger-gradien:hover,
.btn-danger-gradien:focus,
.btn-danger-gradien:active,
.btn-danger-gradien.active,
.btn-danger-gradien.hover {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#b9b9b9), color-stop(0%), color-stop(#919191), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #b9b9b9, 0%, #919191, 100%, #fff) !important */
}

.btn-warning-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#ffcc81), color-stop(51%, #ffac2f), to(#ffcc81));
    background-image: linear-gradient(to right, #ffcc81 0%, #ffac2f 51%, #ffcc81 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-warning-gradien:hover,
.btn-warning-gradien:focus,
.btn-warning-gradien:active,
.btn-warning-gradien.active,
.btn-warning-gradien.hover {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#ffcc81), color-stop(0%), color-stop(#ffac2f), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #ffcc81, 0%, #ffac2f, 100%, #fff) !important */
}

.btn-info-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#29b6ff), color-stop(51%, #008dd6), to(#29b6ff));
    background-image: linear-gradient(to right, #29b6ff 0%, #008dd6 51%, #29b6ff 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-info-gradien:hover,
.btn-info-gradien:focus,
.btn-info-gradien:active,
.btn-info-gradien.active,
.btn-info-gradien.hover {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#29b6ff), color-stop(0%), color-stop(#008dd6), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #29b6ff, 0%, #008dd6, 100%, #fff) !important */
}

.btn-light-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(51%, #e2e2e6), to(#fff));
    background-image: linear-gradient(to right, #fff 0%, #e2e2e6 51%, #fff 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-light-gradien:hover,
.btn-light-gradien:focus,
.btn-light-gradien:active,
.btn-light-gradien.active,
.btn-light-gradien.hover {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#fff), color-stop(0%), color-stop(#e2e2e6), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #fff, 0%, #e2e2e6, 100%, #fff) !important */
}

.btn-dark-gradien {
    /* background-image: -webkit-gradient(linear, left top, right top, from(#3a445b), color-stop(51%, #1a1e29), to(#3a445b));
    background-image: linear-gradient(to right, #3a445b 0%, #1a1e29 51%, #3a445b 100%); */
    border: none;
    color: #fff;
    background-size: auto 200%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.btn-dark-gradien:hover,
.btn-dark-gradien:focus,
.btn-dark-gradien:active,
.btn-dark-gradien.active,
.btn-dark-gradien.hover {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    background-position: right center;
    /* background-image: -webkit-gradient(linear, left top, right top, from(#3a445b), color-stop(0%), color-stop(#1a1e29), color-stop(100%), to(#fff)) !important;
    background-image: linear-gradient(to right, #3a445b, 0%, #1a1e29, 100%, #fff) !important */
}

[class*='-gradien']:hover {
    background-size: 50% 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #fff
}

*.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
}

button {
    cursor: pointer
}

.btn {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out
}

.btn:hover {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out
}

.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none
}

.btn {
    font-size: 14px;
    padding: 0.6rem 1.75rem;
    border-radius: 5px;
    letter-spacing: 1px
}

.btn-lg {
    font-size: 18px
}

.btn-sm {
    font-size: 12px
}

.btn-xs {
    padding: 0.05rem 0.4rem;
    font-size: 11px
}

.large-btn {
    margin-bottom: -20px
}

.large-btn .btn {
    margin-right: 5px;
    margin-bottom: 15px
}

.btn-solid {
    padding: 13px 29px;
    color: #fff;
    letter-spacing: 0.05em;
    border: 2px solid var(--theme-color);
    /* background-image: linear-gradient(30deg, var(--theme-color) 50%, transparent 50%); */
    background-size: 850px;
    background-repeat: no-repeat;
    background-position: 0;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out
}

.btn-solid:hover {
    background-position: 100%;
    color: #000;
    background-color: #fff
}

.btn-solid.black-btn {
    /* background-image: linear-gradient(30deg, #222 50%, transparent 50%); */
    border: 2px solid #222
}

.btn-solid:focus {
    color: #000
}

.btn-solid.btn-sm {
    padding: 9px 16px
}

.btn-solid.btn-xs {
    padding: 5px 8px;
    text-transform: capitalize
}

.btn-solid.hover-solid {
    -webkit-transition: none;
    transition: none
}

.btn-solid.hover-solid:hover,
.btn-solid.hover-solid:focus {
    background-color: var(--theme-color);
    opacity: 0.92;
    color: #fff;
    -webkit-transition: none;
    transition: none
}

.btn-outline {
    display: inline-block;
    padding: 13px 29px;
    letter-spacing: 0.05em;
    border: 2px solid var(--theme-color);
    position: relative;
    color: #000
}

.btn-outline:before {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: "";
    background-color: var(--theme-color);
    z-index: -2
}

.btn-outline:hover,
.btn-outline:focus {
    background-color: var(--theme-color);
    color: #fff !important
}

.btn-outline:hover i,
.btn-outline:focus i {
    color: #fff !important
}

.btn-outline:hover:before,
.btn-outline:focus:before {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1
}

.btn-outline.btn-sm {
    padding: 9px 16px;
    font-size: 12px
}

button.btn.btn-solid:active,
button.btn.btn-outline:active {
    /* background-image: linear-gradient(30deg, var(--theme-color) 50%, transparent 50%); */
    color: #fff;
    background: var(--theme-color)
}

.btn-white {
    background-color: white;
    color: var(--theme-color);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 10px 29px
}

.btn-white:hover {
    color: #000;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.btn-close {
    background: none
}

.ratio_asos .bg-size:before {
    padding-top: 127.7777778%;
    content: "";
    display: block
}

.timer {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    margin-top: 30px;
    background-color: #2d2a25;
    display: inline-block
}

.timer p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 0
}

.timer span {
    width: 70px;
    display: inline-block
}

.timer span .timer-cal {
    font-size: 12px;
    color: #777
}

.timer span .padding-l {
    padding-left: 22px;
    display: inline
}

.background {
    background-color: #f1f5f4;
    padding: 20px 10px;
    margin: 0
}

.background .contain-bg {
    width: 100%;
    background-color: #fff;
    padding-top: 45px;
    padding-bottom: 45px;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    position: relative;
    z-index: 1
}

.background .contain-bg:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--theme-color);
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.background .contain-bg h4 {
    color: #222;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.background .contain-bg:hover:after {
    opacity: 0.07;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.background .contain-bg:hover h4 {
    color: var(--theme-color);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.home.bg-position {
    background-position: top
}

.instagram {
    position: relative
}

.instagram .insta-decor {
    position: absolute;
    top: 0;
    right: 0
}

.onhover-dropdown {
    position: relative;
    font-size: 14px
}

.onhover-dropdown .onhover-show-div {
    top: 50px;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
    min-width: 160px;
    text-align: left;
    -webkit-box-shadow: 0px 1px 2px 0px #cacaca;
    box-shadow: 0px 1px 2px 0px #cacaca;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    right: 0;
    padding: 15px 20px;
    border-radius: 0 !important
}

.onhover-dropdown:hover .onhover-show-div {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    visibility: visible
}

.alert .btn-close {
    /* background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat */
}

.product-box .progress-section {
    margin-top: 10px
}

.product-box .progress-section span {
    margin-top: 6px;
    display: block;
    color: #565656
}

.color-variant li {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 5px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    vertical-align: middle
}

.product-box {
    position: relative;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    vertical-align: middle
}

.product-box .img-block {
    background-color: #f9f9f9;
    position: relative;
    overflow: hidden
}

.product-box .img-block .front {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box .img-block .front a {
    display: block;
    width: 100%
}

.product-box .img-block .back {
    opacity: 0;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    width: 100%
}

.product-box .img-block .back a {
    display: block;
    width: 100%
}

.product-box .img-block .lable-wrapper {
    margin: 0 auto;
    top: 40px;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 2
}

.product-box .img-block .lable-wrapper .lable1,
.product-box .img-block .lable-wrapper .lable2 {
    font-size: 14px;
    padding: 10px 14px 10px 20px;
    display: inline-block;
    text-transform: uppercase;
    text-align: center
}

.product-box .img-block .lable-wrapper .lable1 {
    background-color: var(--theme-color);
    color: #fff;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px
}

.product-box .img-block .lable-wrapper .lable2 {
    background-color: #fff;
    color: #000;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px
}

.product-box .img-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 0
}

.product-box .img-wrapper .front {
    opacity: 1;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box .img-wrapper .back {
    opacity: 0;
    position: absolute;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    width: 100%
}

.product-box .img-wrapper .cart-box {
    position: absolute;
    margin: 0 auto;
    display: inline-block;
    right: 0;
    left: 0;
    border-radius: 50px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 12px 15px;
    -webkit-box-shadow: 0 0 12px 0 #ddd;
    box-shadow: 0 0 12px 0 #ddd;
    bottom: 30px;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease
}

.product-box .img-wrapper .cart-box button {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0
}

.product-box .img-wrapper .cart-box i {
    color: #6f6f6f;
    font-size: 18px;
    padding-left: 8px;
    padding-right: 8px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: inline-block
}

.product-box .img-wrapper .cart-box i:hover {
    color: var(--theme-color);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease
}

.product-box .img-wrapper .cart-box.cart-box-bottom {
    bottom: 0;
    border-radius: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    right: unset
}

.product-box .img-wrapper .lable-block .lable3 {
    border-radius: 100%;
    background-color: var(--theme-color);
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    padding: 12px 6px;
    text-transform: uppercase;
    color: #fff;
    top: 7px;
    left: 7px;
    z-index: 1
}

.product-box .img-wrapper .lable-block .lable4 {
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    top: 7px;
    right: 7px;
    letter-spacing: 0.1em;
    z-index: 1;
    color: #212529
}

.product-box .cart-info,
.product-box .cart-wrap {
    position: absolute;
    bottom: 40px;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    right: 0;
    left: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box .cart-info a [class^="ti-"],
.product-box .cart-info a [class*=" ti-"],
.product-box .cart-wrap a [class^="ti-"],
.product-box .cart-wrap a [class*=" ti-"] {
    display: inline-block
}

.product-box .cart-info.cart-wrap,
.product-box .cart-wrap.cart-wrap {
    bottom: 0;
    text-align: right;
    left: unset
}

.product-box .cart-info.cart-wrap i,
.product-box .cart-wrap.cart-wrap i {
    display: block;
    padding-bottom: 10px;
    padding-top: 10px
}

.product-box .cart-info.cart-wrap.cart-effect-left,
.product-box .cart-wrap.cart-wrap.cart-effect-left {
    left: 0;
    right: unset
}

.product-box .cart-info button,
.product-box .cart-wrap button {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0
}

.product-box .cart-info i,
.product-box .cart-wrap i {
    color: #6f6f6f;
    font-size: 18px;
    padding-right: 10px;
    padding-left: 10px
}

.product-box .cart-info i:hover,
.product-box .cart-wrap i:hover {
    color: var(--theme-color)
}

.product-box .cart-detail {
    position: absolute;
    top: 15px;
    right: 20px;
    opacity: 0
}

.product-box .cart-detail i {
    color: #6f6f6f;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px
}

.product-box .cart-detail button {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0
}

.product-box .product-detail,
.product-box .product-info {
    padding-left: 5px;
    margin-top: 15px
}

.product-box .product-detail .rating i,
.product-box .product-info .rating i {
    padding-right: 5px
}

.product-box .product-detail .rating i:nth-child(-n+4),
.product-box .product-info .rating i:nth-child(-n+4) {
    color: #ffa200
}

.product-box .product-detail .rating i:last-child,
.product-box .product-info .rating i:last-child {
    color: #ddd
}

.product-box .product-detail h6,
.product-box .product-info h6 {
    line-height: 1;
    margin-bottom: 0;
    padding-top: 2px;
    padding-bottom: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-size: 16px;
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.product-box .product-detail h4,
.product-box .product-info h4 {
    font-size: 18px;
    color: #222;
    font-weight: 700;
    margin-bottom: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box .product-detail .color-variant,
.product-box .product-info .color-variant {
    padding-top: 15px
}

.product-box .product-detail .color-variant li,
.product-box .product-info .color-variant li {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 5px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    cursor: pointer
}

.product-box .product-info {
    padding: 0;
    text-align: center;
    position: relative
}

.product-box .product-info .add-btn {
    position: absolute;
    bottom: 110px;
    margin: 0 auto;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    z-index: 1
}

.product-box .product-info .add-btn i {
    color: var(--theme-color)
}

.product-box .product-info .add-btn .btn-outline {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    color: var(--theme-color)
}

.product-box .product-info .add-btn .btn-outline:hover {
    color: #fff
}

.product-box .product-info .add-btn .btn-outline:hover i {
    color: #fff
}

.product-box.effect-center .front img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box.effect-center .img-wrapper .cart-box {
    bottom: 20%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box:hover.effect-center .front img {
    opacity: 0.3;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box:hover.effect-center .img-wrapper .cart-box {
    bottom: 35%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box:hover .img-block .first,
.product-box:hover .img-wrapper .first {
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box:hover .img-block .back,
.product-box:hover .img-wrapper .back {
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.product-box:hover .cart-info {
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box:hover .cart-info button {
    -webkit-animation: fadeInUp 300ms ease-in-out;
    animation: fadeInUp 300ms ease-in-out
}

.product-box:hover .cart-info a:nth-child(2) i {
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out
}

.product-box:hover .cart-info a:nth-child(3) i {
    -webkit-animation: fadeInUp 700ms ease-in-out;
    animation: fadeInUp 700ms ease-in-out
}

.product-box:hover .cart-info a:nth-child(4) i {
    -webkit-animation: fadeInUp 1000ms ease-in-out;
    animation: fadeInUp 1000ms ease-in-out
}

.product-box:hover .cart-wrap button {
    -webkit-animation: fadeInRight 300ms ease-in-out;
    animation: fadeInRight 300ms ease-in-out
}

.product-box:hover .cart-wrap a:nth-child(2) i {
    -webkit-animation: fadeInRight 500ms ease-in-out;
    animation: fadeInRight 500ms ease-in-out
}

.product-box:hover .cart-wrap a:nth-child(3) i {
    -webkit-animation: fadeInRight 700ms ease-in-out;
    animation: fadeInRight 700ms ease-in-out
}

.product-box:hover .cart-wrap a:nth-child(4) i {
    -webkit-animation: fadeInRight 1000ms ease-in-out;
    animation: fadeInRight 1000ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left button {
    -webkit-animation: fadeInLeft 300ms ease-in-out;
    animation: fadeInLeft 300ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(2) i {
    -webkit-animation: fadeInLeft 500ms ease-in-out;
    animation: fadeInLeft 500ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(3) i {
    -webkit-animation: fadeInLeft 700ms ease-in-out;
    animation: fadeInLeft 700ms ease-in-out
}

.product-box:hover .cart-wrap.cart-effect-left a:nth-child(4) i {
    -webkit-animation: fadeInLeft 1000ms ease-in-out;
    animation: fadeInLeft 1000ms ease-in-out
}

.product-box:hover .cart-detail {
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-box:hover .cart-detail button {
    -webkit-animation: fadeInRight 300ms ease-in-out;
    animation: fadeInRight 300ms ease-in-out
}

.product-box:hover .cart-detail a:nth-child(2) i {
    -webkit-animation: fadeInRight 500ms ease-in-out;
    animation: fadeInRight 500ms ease-in-out
}

.product-box:hover .cart-detail a:nth-child(3) i {
    -webkit-animation: fadeInRight 700ms ease-in-out;
    animation: fadeInRight 700ms ease-in-out
}

.product-box:hover .cart-detail a:nth-child(4) i {
    -webkit-animation: fadeInRight 1000ms ease-in-out;
    animation: fadeInRight 1000ms ease-in-out
}

.product-box:hover .product-info .add-btn {
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out
}

.product-box:hover .img-wrapper .cart-box {
    opacity: 1;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    -webkit-animation: fadeInUp 400ms ease-in-out;
    animation: fadeInUp 400ms ease-in-out
}

.card-details-title {
    margin-bottom: 10px;
    background: var(--theme-color);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 76, 59, 0.2)), color-stop(80%, rgba(255, 76, 59, 0)));
    background: linear-gradient(90deg, rgba(255, 76, 59, 0.2) 0%, rgba(255, 76, 59, 0) 80%);
    padding: 20px 10px;
    border-radius: 5px
}

.card-details-title h3 {
    margin-bottom: 0;
    color: #000;
    font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: bolder
}

.card-details-title h3 span {
    color: var(--theme-color)
}

thead,
tbody,
tfoot,
tr,
td,
th {
    vertical-align: middle
}

.table {
    margin-bottom: 0px
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: inherit
}

.table th,
.table td {
    padding: .75rem
}

.table.table-primary th {
    color: #fff
}

.table thead.bg-primary tr th {
    color: #fff
}

.table th {
    color: #313131;
    font-weight: 600;
    font-size: 18px
}

.table td {
    color: #313131
}

.table td a i {
    font-size: 18px;
    color: #707070
}

.table td a span {
    color: #313131
}

.table [class*="bg-"] {
    color: #fff
}

.table .bg-light {
    color: #313131
}

.table tfoot {
    font-weight: bold
}

table tr th {
    font-weight: 600
}

.order-track tbody tr td h6 {
    margin-bottom: 0
}

.order-track tbody tr td:first-child {
    width: 100px
}

.order-track tbody tr td:nth-child(2) {
    width: 100px
}

.order-track tbody tr td:nth-child(3) {
    width: 100px
}

.all-package thead tr th {
    font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: #232323;
    text-align: center;
    font-weight: 700;
    min-width: 180px;
    background-color: #f8f8f9;
    padding: 15px 20px
}

.all-package tbody tr td {
    text-align: center;
    padding: 15px 20px;
    min-width: 150px;
    border-bottom: 1px solid #dee2e6
}

.all-package tbody tr td img {
    width: 50px
}

.all-package tbody tr td.td-price {
    font-weight: 800
}

.all-package tbody tr td:first-child {
    text-transform: uppercase;
    /* font-weight: bold */
}

.all-package tbody tr td:last-child {
    font-size: 18px
}

.all-package tbody tr td:last-child .fas.fa-edit {
    color: #42ba96
}

.all-package tbody tr td:last-child .fas.fa-trash-alt {
    color: var(--theme-color);
    margin-left: 10px
}

.all-package tbody tr td a {
    color: inherit !important
}

.all-package tbody tr td.order-cancle span {
    background-color: rgba(255, 76, 59, 0.1);
    color: var(--theme-color);
    border: 1px solid var(--theme-color);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
    text-transform: capitalize
}

.all-package tbody tr td.order-success span {
    background-color: rgba(66, 186, 150, 0.1);
    border: 1px solid #42ba96;
    color: #42ba96;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
    text-transform: capitalize
}

.all-package tbody tr td.order-warning span {
    background-color: rgba(255, 247, 230, 0.1);
    color: #ffc548;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
    border: 1px solid #ffc548;
    text-transform: capitalize
}

.all-package tbody tr td.order-pending span {
    background-color: rgba(100, 116, 139, 0.1);
    color: #64748b;
    border: 1px solid #64748b;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
    text-transform: capitalize
}

.all-package tbody tr td.order-continue span {
    background-color: rgba(242, 244, 248, 0.1);
    color: #0b3a77;
    border: 1px solid #0b3a77;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
    text-transform: capitalize
}

.all-package tbody tr td:nth-child(6) {
    font-weight: bolder
}

.all-package tbody tr td span img {
    width: 70px
}

.vendor-table thead tr th {
    padding: 14px 18px !important;
    border-bottom-color: transparent !important;
    text-align: left !important;
    min-width: 150px;
    font-size: 15px
}

.vendor-table thead tr th:first-child {
    display: revert !important
}

.vendor-table thead tr th:last-child {
    text-align: left !important
}

.vendor-table thead tr th:nth-child(2),
.vendor-table thead tr th:nth-child(5),
.vendor-table thead tr th:last-child {
    text-align: center !important
}

.vendor-table tbody tr td {
    padding: 10px 18px !important;
    text-align: left !important
}

.vendor-table tbody tr td:first-child {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.vendor-table tbody tr td:first-child a {
    margin-left: 25px
}

.vendor-table tbody tr td:nth-child(2),
.vendor-table tbody tr td:nth-child(5) {
    font-weight: 800;
    color: var(--theme-color)
}

.vendor-table tbody tr td:nth-child(2),
.vendor-table tbody tr td:nth-child(5),
.vendor-table tbody tr td:last-child {
    text-align: center !important
}

.tracker-table {
    padding-top: 0;
    overflow: hidden;
    margin-bottom: 0
}

.tracker-table thead th {
    border-bottom-width: 1px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px !important;
    text-align: left !important;
    padding: 16px;
    border-bottom: 1px solid #eff2f7 !important;
    background-color: #eff2f7
}

[dir="rtl"] .tracker-table thead th {
    text-align: right
}

.tracker-table tbody h2 {
    margin-bottom: 0
}

.tracker-table tbody tr:nth-child(even) {
    background-color: #eff2f7
}

.tracker-table tbody tr td {
    vertical-align: middle;
    color: #777;
    border-top: 0;
    border-bottom: 1px solid #eff2f7 !important;
    text-align: left;
    padding: calc(0px + (16 - 0) * ((100vw - 320px) / (1920 - 320))) calc(6px + (16 - 6) * ((100vw - 320px) / (1920 - 320)));
    min-width: 175px
}

[dir="rtl"] .tracker-table tbody tr td {
    text-align: right
}

.tracker-table tbody tr td:nth-child(odd) h6 {
    color: #232323
}

.tracker-table tbody tr td a {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    display: inline-block
}

.review-table thead tr th {
    text-align: left
}

.review-table thead tr th:first-child {
    display: revert !important
}

.review-table thead tr th:nth-child(n+4) {
    text-align: center
}

.review-table tbody tr td {
    text-align: left
}

.review-table tbody tr td:first-child {
    display: revert !important
}

.review-table tbody tr td:nth-child(n+4) {
    text-align: center
}

.review-table tbody tr td:last-child {
    margin: 0
}

.review-table tbody tr td.td-cross .feather {
    font-size: 20px !important;
    stroke: var(--theme-color) !important
}

.review-table tbody tr td.td-check .feather {
    font-size: 20px !important;
    stroke: #42ba96 !important
}

.ticket-table thead tr th {
    text-align: center;
    font-size: 16px
}

.ticket-table thead tr th:first-child {
    display: revert !important
}

.ticket-table tbody tr td {
    text-align: center;
    min-width: 150px
}

.ticket-table tbody tr td:first-child {
    display: revert !important;
    font-weight: 700
}

.ticket-table tbody tr td.status-danger span {
    background-color: rgba(226, 36, 84, 0.15);
    color: var(--theme-color);
    padding: 5px 10px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px
}

.ticket-table tbody tr td.status-close span {
    background-color: rgba(47, 135, 51, 0.15);
    color: #2f8733;
    padding: 5px 10px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 5px;
    font-size: 12px
}

.trans-table thead tr th {
    padding: 10px 20px !important;
    font-size: 15px;
    text-align: left
}

.trans-table thead tr th:last-child {
    text-align: left
}

.trans-table tbody tr td {
    padding: 18px 20px !important;
    text-align: left
}

.trans-table tbody tr td:first-child {
    display: revert !important;
    font-weight: normal;
    font-size: 14px;
    text-transform: capitalize
}

.trans-table tbody tr td:last-child {
    text-align: left;
    font-size: 14px
}

.user-table thead {
    background-color: #ff4c3b
}

.user-table thead tr th {
    min-width: 110px;
    color: #fff;
    border-bottom: none;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.user-table thead tr th:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.user-table thead tr th:first-child .user-checkbox {
    min-height: unset;
    margin-bottom: 0;
    padding: 0
}

.user-table thead tr th:first-child .user-checkbox .form-check-input {
    width: 19px;
    height: 19px;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    border-color: #ddd
}

.user-table thead tr th:first-child .user-checkbox .form-check-input:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.user-table thead tr th:first-child .user-checkbox .form-check-input:checked[type="checkbox"] {
    background-size: 15px;
    background-repeat: no-repeat;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.user-table thead tr th:first-child .user-checkbox .form-check-input:checked {
    background-color: var(--theme-color)
}

.user-table thead tr th:nth-child(6),
.user-table thead tr th:nth-child(7),
.user-table thead tr th:nth-child(8) {
    text-align: center
}

.user-table thead tr th:nth-child(9) {
    text-align: center
}

.user-table tbody td:nth-child(5) {
    min-width: 140px
}

.user-table tbody tr td:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around
}

.user-table tbody tr td:first-child .user-checkbox {
    min-height: unset;
    margin-bottom: 0;
    padding: 0
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input {
    width: 19px;
    height: 19px;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    border-color: #ddd
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input:checked[type="checkbox"] {
    background-size: 15px;
    background-repeat: no-repeat;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input:checked {
    background-color: var(--theme-color)
}

.user-table tbody tr td:nth-child(6),
.user-table tbody tr td:nth-child(8) {
    text-align: center
}

.user-table tbody tr td:nth-child(6) a i,
.user-table tbody tr td:nth-child(8) a i {
    color: #ff4c3b
}

.user-table tbody tr td:nth-child(7) {
    text-align: center
}

.user-table tbody tr td:nth-child(7) a i {
    color: #ffbc58
}

.user-table tbody tr td:nth-child(9) {
    text-align: center
}

.user-table tbody tr td:nth-child(9) a i {
    color: #13c9ca
}

.user-table tbody tr td span img {
    width: 45px;
    border-radius: 5px;
    height: 45px;
    -o-object-fit: cover;
    object-fit: cover
}

.user-table tbody tr td:last-child ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.user-table tbody tr td:last-child ul li {
    display: inline-block;
    margin: 0 10px
}

.user-table tbody tr td:last-child ul li:first-child {
    margin-left: 0
}

.user-table tbody tr td:last-child ul li:last-child {
    margin-right: 0
}

.tooltip .tooltip-inner {
    background-color: #232323 !important;
    color: #fff !important
}

.tooltip-arrow::before {
    border-left-color: #232323 !important
}

.user-table tbody tr td:nth-child(2) a span:first-child {
    font-weight: 700;
    color: #2b2b2be6 !important
}

.user-table tbody tr td:nth-child(2) a span+span {
    color: #999
}

.user-table tbody tr td:nth-child(2) span:first-child {
    font-weight: 700;
    color: #2b2b2be6
}

.user-table tbody tr td:nth-child(2) span+span {
    color: #999
}

.user-table thead tr {
    background-color: #262932
}

.user-table thead tr th {
    border-bottom: none;
    color: #fff
}

.user-table thead tr th:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-table thead tr th:first-child .user-checkbox {
    min-height: unset;
    margin-bottom: 0;
    padding: 0;
    margin-right: 10px
}

.user-table thead tr th:first-child .user-checkbox .form-check-input {
    width: 19px;
    height: 19px;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    border-color: #ddd
}

.user-table thead tr th:first-child .user-checkbox .form-check-input:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.user-table thead tr th:first-child .user-checkbox .form-check-input:checked[type="checkbox"] {
    background-size: 15px;
    background-repeat: no-repeat;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.user-table thead tr th:first-child .user-checkbox .form-check-input:checked {
    background-color: var(--theme-color)
}

.user-table tbody tr td:first-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.user-table tbody tr td:first-child .user-checkbox {
    margin-right: 10px;
    min-height: unset;
    margin-bottom: 0;
    padding: 0
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input {
    width: 19px;
    height: 19px;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    border-color: #ddd
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input:focus {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input:checked[type="checkbox"] {
    background-size: 15px;
    background-repeat: no-repeat;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out
}

.user-table tbody tr td:first-child .user-checkbox .form-check-input:checked {
    background-color: var(--theme-color)
}

table tbody tr td a .lnr {
    font-size: 16px
}

table tbody tr td a .lnr.lnr-eye {
    color: #ffbc58
}

table tbody tr td a .lnr.lnr-pencil {
    color: #4aa4d9
}

table tbody tr td a .lnr.lnr-trash {
    color: #13c9ca
}

.order-details-table .table-details+.table-details {
    margin-top: 30px
}

.order-details-table .table-details .table-transaction td:last-child {
    text-align: right
}

.order-details-table .table-details .table-transaction td:nth-child(2) {
    text-align: center
}

.order-details-table .table-details .table-balance:last-child td h5 {
    color: var(--theme-color) !important;
    font-weight: 800
}

.order-details-table .table-details .table-balance td:last-child {
    text-align: right
}

.order-details-table .table-details .table-balance td h5 span {
    color: #c7c7c5;
    font-weight: 400
}

.order-details-table .table-details .table tbody {
    border-bottom: 1px solid #ddd
}

.order-details-table .table-details .table tbody tr:first-child td {
    padding-top: 20px
}

.order-details-table .table-details .table tbody tr:last-child td {
    padding-bottom: 20px
}

.order-details-table .table-details .table tbody tr td {
    padding: 10px;
    min-width: 170px;
    max-width: 150px;
    border-bottom: none !important;
    color: #7e7e7e
}

[dir="rtl"] .order-details-table .table-details .table tbody tr td {
    text-align: right
}

.order-details-table .table-details .table tbody tr td:nth-child(1) {
    background-color: transparent;
    min-width: 130px
}

.order-details-table .table-details .table tbody tr td:nth-child(1) a img {
    height: 80px;
    width: 100px;
    border-radius: 5px;
    -o-object-fit: cover;
    object-fit: cover
}

.order-details-table .table-details .table tbody tr td h5 {
    margin-top: 10px;
    color: #232323
}

.order-details-table .table-details .table tfoot tr td {
    padding: 10px 15px
}

.order-details-table .table-details .table tfoot tr td h4 {
    font-weight: 400;
    color: #232323
}

.order-details-table .table-details .table tfoot tr.table-order:first-child td {
    padding: 20px 15px 10px
}

.order-details-table .table-details .table tfoot tr.table-order:nth-child(3) td {
    padding: 10px 15px 20px
}

.order-details-table .table-details .table tfoot tr.table-order:last-child td {
    padding: 20px 15px
}

.order-details-table .table-details .table tfoot tr.table-order h5 {
    color: #232323;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.2;
    font-weight: 400
}

.order-details-table .table-details .table tfoot tr.table-order:nth-child(n+3) {
    border-bottom: 1px solid #ddd
}

.order-details-table .order-success {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    background-color: #eff2f7;
    border: 1px solid #ddd
}

.order-details-table .order-success h4 {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 16px;
    color: #232323
}

.order-details-table .order-success .order-details {
    margin-top: 0
}

.order-details-table .order-success .order-details li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-transform: capitalize;
    line-height: 1.6;
    font-size: 15px;
    margin-bottom: 3px;
    color: #7e7e7e
}

.order-details-table .order-success .order-details li:last-child {
    margin-bottom: -5px
}

.order-details-table .order-success .payment-mode p {
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 0;
    color: #7e7e7e;
    letter-spacing: 0.05rem
}

.order-details-table .order-success .delivery-sec {
    background-color: transparent
}

.order-details-table .order-success .delivery-sec h3 {
    color: #7e7e7e;
    text-transform: capitalize;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 400
}

.order-details-table .order-success .delivery-sec h3 span {
    font-weight: 700;
    margin-left: 5px;
    color: #232323
}

[dir="rtl"] .order-details-table .order-success .delivery-sec h3 span {
    margin-left: 0;
    margin-right: 5px
}

.order-details-table .order-success .delivery-sec a {
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 5px;
    display: block;
    font-weight: 600;
    margin-bottom: -5px
}

.cart-section .count-down {
    margin-bottom: 5px;
    padding: 0;
    letter-spacing: 1.1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #232323
}

.cart-section .count-down h5 {
    margin-right: calc(0px + (10 - 0) * ((100vw - 320px) / (1920 - 320)))
}

.cart-section .table tbody+tbody {
    border-top: none
}

.cart-section .cart-buttons {
    padding-top: 35px
}

.cart-section .cart-buttons>div:last-child {
    text-align: right;
    padding-right: 38px
}

.cart-section .cart-table {
    overflow: hidden;
    margin-bottom: 0;
    border: none
}

.cart-section .cart-table thead tr th {
    font-weight: bolder;
    text-transform: uppercase;
    font-size: 15px;
    padding: 0.75rem 1.25rem;
    color: #232323;
    border-top: 1px solid #232323;
    border-bottom: 1px solid #232323 !important
}

.cart-section .cart-table thead tr th a {
    color: #232323;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600
}

.cart-section tbody h2 {
    margin-bottom: 0
}

.cart-section tbody tr.table-order td {
    min-width: 150px
}

.cart-section tbody tr.table-order:last-child {
    border-bottom: 1px solid #ddd
}

.cart-section tbody tr td {
    vertical-align: middle;
    color: #232323;
    border-top: 0;
    border-bottom: 1px solid #c7c7c5 !important;
    min-width: 175px
}

.cart-section tbody tr td a {
    color: #7e7e7e;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0
}

.cart-section tbody tr td a.icon {
    border: 1px solid rgba(169, 169, 169, 0.5);
    padding: 10px 15px;
    border-radius: 3px
}

.cart-section tbody tr td a img {
    height: 80px
}

.cart-section tbody tr td p {
    color: #777;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0;
    line-height: 18px
}

.cart-section tbody tr td p img {
    height: 90px
}

.cart-section tbody tr td h5 {
    color: #232323;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.2;
    margin: 10px 0 0 0;
    font-weight: 400
}

.cart-section tfoot tr th {
    padding-top: 35px;
    text-align: left
}

.cart-section tfoot tr td {
    text-align: left;
    border: none;
    padding: 10px 15px
}

.cart-section tfoot tr td h4 {
    font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.2;
    margin: 0;
    font-weight: 400
}

/* .table-category thead tr th:nth-child(2) {
    text-align: left
}

.table-category tbody tr td:nth-child(2) {
    text-align: left
}

.table-listdigital thead tr th {
    text-align: left
} */

.table-listdigital thead tr th:last-child {
    text-align: center
}

/* .table-listdigital tbody tr td {
    text-align: left
} */

.table-listdigital tbody tr td:nth-child(3) {
    color: #232323
}

.table-listdigital tbody tr td:first-child {
    font-weight: normal
}

.table-listdigital tbody tr td:last-child {
    text-align: center
}

.button-small .fa-trash-alt {
    color: var(--theme-color);
    margin-left: 10px
}

.button-small .fa-save,
.button-small .fa-edit {
    color: #42ba96
}

.list-digital thead tr th {
    text-align: left
}

.list-digital thead tr th:last-child {
    text-align: center
}

.list-digital tbody tr td {
    text-align: left
}

.list-digital tbody tr td:last-child {
    text-align: center
}

.coupon-table thead tr th {
    padding: 10px
}

.coupon-table tbody tr td {
    padding: 20px 10px
}

.coupon-table tbody tr td:last-child {
    font-size: 14px
}

.current-table thead tr th {
    text-align: left
}

.current-table thead tr th:last-child {
    text-align: center
}

.current-table tbody tr td {
    text-align: left
}

.current-table tbody tr td span {
    color: var(--theme-color)
}

.current-table tbody tr td:first-child {
    font-weight: normal
}

.current-table tbody tr td:last-child {
    text-align: center
}

.report-table thead tr th {
    text-align: left
}

.report-table thead tr th:last-child {
    text-align: center
}

.report-table tbody tr td {
    text-align: left
}

.report-table tbody tr td:first-child {
    font-weight: normal;
    text-transform: capitalize
}

.report-table tbody tr td:last-child {
    text-align: center
}

.trans-table thead tr th {
    text-align: left
}

.trans-table tbody tr td {
    text-align: left;
    font-weight: normal
}

.team {
    position: relative
}

.team img {
    margin: 0 auto;
    padding: 0 10px
}

.team .slick-prev,
.team .slick-next {
    position: absolute;
    top: -45px
}

.team .slick-prev:before,
.team .slick-next:before {
    font: normal normal normal 30px/1 FontAwesome;
    color: #222;
    opacity: 0.6
}

.team .slick-prev {
    left: unset;
    right: 20px
}

.team .slick-prev:before {
    content: "\f104"
}

.team .slick-next {
    right: 0
}

.team .slick-next:before {
    content: "\f105"
}

.team h2 {
    color: #222;
    padding-bottom: 15px;
    border-bottom: 1px solid #dddada;
    margin-bottom: 10px
}

.team h4,
.team h6 {
    text-align: center
}

.team h4 {
    color: var(--theme-color);
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px
}

.team h6 {
    margin-bottom: 0
}

.bundle {
    margin-top: 10px
}

.bundle .bundle_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.bundle .bundle_img .img-box img {
    max-width: 115px;
    border: 1px solid #ddd;
    padding: 2px
}

.bundle .bundle_img .plus {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 7px;
    font-size: 22px
}

.bundle .bundle_detail {
    margin-top: 15px
}

.bundle .bundle_detail .price_product {
    color: #000;
    padding-left: 3px;
    font-weight: 700
}

.qty-box .input-group {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.qty-box .input-group span button {
    background: #fff !important;
    border: 1px solid #ced4da
}

.qty-box .input-group .form-control {
    text-align: center;
    width: 80px;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset
}

.qty-box .input-group button {
    background-color: transparent;
    border: 0;
    color: #777;
    cursor: pointer;
    padding-left: 12px;
    font-size: 12px;
    line-height: 1
}

.qty-box .input-group button i {
    color: #222
}

.qty-box .input-group .icon {
    padding-right: 0
}

.product-right .product-count {
    background-color: #f8f8f8;
    padding: 10px 12px;
    margin-bottom: 12px
}

.product-right .product-count img {
    margin-right: 6px
}

.product-right .product-count ul {
    margin-bottom: -4px
}

.product-right .product-count ul li {
    margin-bottom: 4px
}

.product-right .product-count ul li:first-child {
    margin-right: 14px
}

.product-right p {
    margin-bottom: 0;
    line-height: 1.5em
}

.product-right .product-title {
    color: #222;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 3px;
    font-size: 16px
}

.product-right .shipping-info li {
    display: block;
    font-size: 16px;
    color: #777777;
    line-height: 1.8
}

.product-right .border-product {
    padding-top: 15px;
    padding-bottom: 20px;
    border-top: 1px dashed #ddd
}

.product-right h2 {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 25px;
    line-height: 1.2em
}

.product-right .price-detail span {
    font-size: 16px;
    color: var(--theme-color);
    padding-left: 10px
}

.product-right h3 {
    font-size: 26px;
    color: #222;
    margin-bottom: 15px
}

.product-right h4 {
    font-size: 16px;
    margin-bottom: 7px
}

.product-right h4 del {
    color: #777
}

.product-right h4 span {
    padding-left: 5px;
    color: var(--theme-color)
}

.product-right .color-variant {
    margin-bottom: 10px
}

.product-right .color-variant li {
    height: 30px;
    width: 30px;
    cursor: pointer
}

.product-right .color-variant li.active {
    position: relative
}

.product-right .color-variant li.active:after {
    content: "";
    /* background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>"); */
    top: 10px;
    right: 4px;
    height: 15px;
    width: 15px;
    background-size: 70%;
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0.8
}

.product-right .product-buttons {
    margin-bottom: 20px
}

.product-right .product-buttons .btn-solid,
.product-right .product-buttons .btn-outline {
    padding: 7px 25px
}

.product-right .product-buttons a:last-child {
    margin-left: 10px
}

.product-right .product-description h6 span {
    float: right
}

.product-right .product-description .qty-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 10px
}

.product-right .product-description .qty-box .input-group {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
    width: unset
}

.product-right .product-description .qty-box .input-group .form-control {
    border-right: none
}

.product-right .size-box {
    margin-top: 10px;
    margin-bottom: 10px
}

.product-right .size-box ul li {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #efefef;
    text-align: center
}

.product-right .size-box ul li a {
    color: #222;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
}

.product-right .size-box ul li.active {
    background-color: #efefef
}

.product-right .product-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.product-right .product-icon .product-social {
    margin-top: 5px
}

.product-right .product-icon .product-social li {
    padding-right: 30px
}

.product-right .product-icon .product-social li a {
    color: #333;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.product-right .product-icon .product-social li a i {
    font-size: 18px
}

.product-right .product-icon .product-social li a:hover {
    color: var(--theme-color)
}

.product-right .product-icon .product-social li:last-child {
    padding-right: 0
}

.product-right .product-icon .wishlist-btn {
    background-color: transparent;
    border: none
}

.product-right .product-icon .wishlist-btn i {
    border-left: 1px solid #ddd;
    font-size: 18px;
    padding-left: 10px;
    margin-left: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-right .product-icon .wishlist-btn span {
    padding-left: 10px;
    font-size: 18px
}

.product-right .product-icon .wishlist-btn:hover i {
    color: var(--theme-color);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease
}

.product-right .payment-card-bottom {
    margin-top: 10px
}

.product-right .payment-card-bottom ul li {
    padding-right: 10px
}

.product-right .timer {
    margin-top: 10px;
    background-color: #f7f7f7
}

.product-right .timer p {
    color: #222
}

.product-right .rating-section {
    margin-bottom: 8px;
    margin-top: -6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.product-right .rating-section h6 {
    margin-bottom: 0;
    margin-left: 10px;
    color: #323232;
    font-weight: 700;
    font-size: 15px
}

.product-right .rating-section .rating i {
    padding-right: 2px;
    font-size: 18px
}

.product-right .label-section {
    margin-bottom: 15px
}

.product-right .label-section .badge {
    padding: 6px 11px;
    font-size: 12px
}

.product-right .label-section .label-text {
    text-transform: capitalize;
    padding-left: 5px
}

.product-right.product-form-box {
    text-align: center;
    border: 1px solid #ddd;
    padding: 20px
}

.product-right.product-form-box .product-description .qty-box {
    margin-bottom: 5px
}

.product-right.product-form-box .product-description .qty-box .input-group {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.product-right.product-form-box .product-buttons {
    margin-bottom: 0
}

.product-right.product-form-box .timer {
    margin-bottom: 10px;
    text-align: left
}

.rating {
    margin-top: 0
}

.rating i {
    color: #ddd;
    padding-right: 5px
}

.rating i.theme-color {
    color: #ffa200 !important
}

.rating .three-star {
    padding-bottom: 5px
}

.rating .three-star i {
    color: #acacac
}

.rating .three-star i:nth-child(-n+3) {
    color: #ffd200
}

.order-box {
    position: relative;
    margin-bottom: 50px
}

.order-box .title-box {
    position: relative;
    padding-bottom: 25px;
    color: #444;
    font-weight: 600;
    font-size: 22px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px
}

.order-box .title-box span {
    position: relative;
    width: 35%;
    float: right;
    line-height: 1.2em
}

.order-box .qty {
    position: relative;
    border-bottom: 1px solid #ededed;
    margin-bottom: 30px
}

.order-box .qty li {
    position: relative;
    display: block;
    font-size: 15px;
    color: #444;
    line-height: 20px;
    margin-bottom: 20px
}

.order-box .qty li span {
    float: right;
    font-size: 18px;
    line-height: 20px;
    color: #232323;
    font-weight: 400;
    width: 35%
}

.order-box .sub-total {
    position: relative;
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px
}

.order-box .sub-total li {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    line-height: 20px;
    margin-bottom: 20px;
    width: 100%
}

.order-box .sub-total li .count {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: var(--theme-color);
    font-weight: 400;
    width: 35%;
    float: right
}

.order-box .sub-total .shopping-option label {
    position: relative;
    font-size: 16px;
    line-height: 32px;
    padding-left: 10px;
    color: #6f6f6f
}

.order-box .sub-total .shipping {
    width: 35%;
    float: right
}

.order-box .total {
    position: relative;
    margin-bottom: 40px
}

.order-box .total li {
    position: relative;
    display: block;
    font-weight: 400;
    color: #333;
    line-height: 20px;
    margin-bottom: 10px;
    font-size: 18px
}

.order-box .total li .count {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: var(--theme-color);
    font-weight: 400
}

.order-box .total li span {
    float: right;
    font-size: 15px;
    line-height: 20px;
    color: #444;
    font-weight: 400;
    width: 35%;
    display: block
}

.login-section {
    min-height: 100vh;
    position: relative;
    background: #f0f3f8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: calc(30px + (50 - 10) * ((100vw - 320px) / (1920 - 320)))
}

.login-section .materialContainer {
    width: 100%;
    max-width: 460px;
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translate(0);
    transform: translate(0)
}

.login-section .materialContainer .box {
    position: relative;
    top: 0;
    opacity: 1;
    float: left;
    padding: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320))) calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
    width: 100%;
    background: #fff;
    border-radius: 10px;
    -webkit-transform: scale(1);
    transform: scale(1);
    z-index: 5
}

.login-section .materialContainer .box.back {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    top: -20px;
    opacity: 0.8;
    z-index: -1
}

.login-section .materialContainer .box:before {
    content: "";
    width: 100%;
    height: 30px;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    background-color: rgba(255, 255, 255, 0.6);
    left: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95)
}

.login-section .materialContainer .login-title {
    width: 100%;
    line-height: 46px;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: calc(0px + (2 - 0) * ((100vw - 320px) / (1920 - 320)));
    color: var(--theme-color);
    position: relative
}

.login-section .materialContainer .login-title:before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(-10px + (-10 - 10) * ((100vw - 320px) / (1920 - 320)));
    background: var(--theme-color)
}

.login-section .materialContainer .login-title h2 {
    font-size: calc(27px + (28 - 27) * ((100vw - 320px) / (1920 - 320)));
    margin-left: calc(10px + (0 - 10) * ((100vw - 320px) / (1920 - 320)));
    margin-top: -2px
}

.login-section .materialContainer .social-media {
    padding: 15px calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 0 10px rgba(199, 199, 197, 0.6);
    box-shadow: 0 0 10px rgba(199, 199, 197, 0.6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.login-section .materialContainer .social-media h6 {
    margin-left: 20px;
    margin-bottom: -2px;
    color: #fff
}

.login-section .materialContainer .social-media img {
    width: calc(8% + (18 - 8) * ((100vw - 320px) / (1920 - 320)))
}

.login-section .materialContainer .social-media.fb-media {
    background-color: #43609c
}

.login-section .materialContainer .social-media.fb-media h6 {
    color: #fff;
    font-size: 18px
}

.login-section .materialContainer .social-media.google-media {
    color: #000
}

.login-section .materialContainer .social-media.google-media h6 {
    font-size: 18px;
    color: #000
}

.login-section .materialContainer p {
    width: 100%;
    float: left;
    text-align: center;
    color: rgba(35, 35, 35, 0.4);
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin-top: calc(17px + (23 - 17) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0
}

.login-section .materialContainer p.pass-or {
    margin-top: calc(4px + (12 - 4) * ((100vw - 320px) / (1920 - 320)))
}

.login-section .materialContainer .input {
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1)
}

.login-section .materialContainer .input label,
.login-section .materialContainer .input input,
.login-section .materialContainer .input .spin {
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1)
}

.login-section .materialContainer .button {
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1)
}

.login-section .materialContainer .button button .button.login button i.fas {
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1)
}

.login-section .materialContainer .material-button .shape:before,
.login-section .materialContainer .material-button .shape:after {
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1)
}

.login-section .materialContainer .button.login button {
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1)
}

.login-section .materialContainer .material-button,
.login-section .materialContainer .alt-2,
.login-section .materialContainer .material-button .shape,
.login-section .materialContainer .alt-2 .shape,
.login-section .materialContainer .box {
    -webkit-transition: 400ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: 400ms cubic-bezier(0.4, 0, 0.2, 1)
}

.login-section .materialContainer .input {
    width: 100%;
    float: left
}

.login-section .materialContainer .input label,
.login-section .materialContainer .input input,
.login-section .materialContainer .input .spin {
    width: 100%;
    float: left
}

.login-section .materialContainer .button {
    width: 100%;
    float: left
}

.login-section .materialContainer .button button {
    width: 100%;
    float: left
}

.login-section .materialContainer .input {
    margin-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: 70px
}

.login-section .materialContainer .input {
    position: relative
}

.login-section .materialContainer .input input {
    position: relative
}

.login-section .materialContainer .button {
    position: relative
}

.login-section .materialContainer .button button {
    position: relative
}

.login-section .materialContainer .input input {
    height: 60px;
    top: 10px;
    border: none;
    background: transparent;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #232323;
    font-weight: 300
}

.login-section .materialContainer .input label {
    font-family: "Roboto", sans-serif;
    font-size: calc(15px + (18 - 15) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300
}

.login-section .materialContainer .input label.email-label {
    font-size: calc(15px + (24 - 15) * ((100vw - 320px) / (1920 - 320)))
}

.login-section .materialContainer .button button {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    color: rgba(35, 35, 35, 0.8);
    font-weight: 300
}

.login-section .materialContainer .input:before,
.login-section .materialContainer .input .spin {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0
}

.login-section .materialContainer .input:before {
    content: "";
    background: rgba(35, 35, 35, 0.15);
    z-index: 3
}

.login-section .materialContainer .input .spin {
    background: var(--theme-color);
    z-index: 4;
    width: 0
}

.login-section .materialContainer .overbox .input .spin {
    background: #fff
}

.login-section .materialContainer .overbox .input:before {
    background: rgba(255, 255, 255, 0.5)
}

.login-section .materialContainer .input label {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    cursor: pointer;
    line-height: 60px;
    color: #777
}

.login-section .materialContainer .input label:focus-visible {
    border: none
}

.login-section .materialContainer .button {
    margin-top: 20px;
    margin: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320))) 0;
    overflow: hidden;
    z-index: 2
}

.login-section .materialContainer .button.button-1 {
    margin-bottom: 0
}

.login-section .materialContainer .button.login button {
    width: 100%;
    left: 0%;
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    font-size: 18px;
    color: #fff;
    padding: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 4px
}

.login-section .materialContainer .button.login button span {
    display: block;
    margin-top: -3px
}

.login-section .materialContainer .button button {
    width: 100%;
    left: 0%;
    background-color: transparent;
    border: 1px solid rgba(35, 35, 35, 0.1);
    font-size: 18px;
    color: rgba(35, 35, 35, 0.2);
    padding: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)))
}

.login-section .materialContainer .button button {
    background-color: #fff;
    color: var(--theme-color);
    border: none
}

.login-section .materialContainer .button.login button.active {
    border: 1px solid transparent;
    color: #fff !important;
    background-color: var(--theme-color)
}

.login-section .materialContainer .button.login button.active span {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    display: none
}

.login-section .materialContainer .button.login button.active i.fas {
    opacity: 1;
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    display: block
}

.login-section .materialContainer .button.login button i.fas {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    font-size: 18px;
    -webkit-transform: scale(0) rotate(-45deg);
    transform: scale(0) rotate(-45deg);
    display: none
}

.login-section .materialContainer .button button {
    cursor: pointer;
    position: relative;
    z-index: 2
}

.login-section .materialContainer .pass-forgot {
    width: 100%;
    float: right;
    text-align: right;
    color: rgba(35, 35, 35, 0.4);
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin: 10px auto 0
}

.login-section .materialContainer .pass-forgot:hover {
    color: var(--theme-color)
}

.login-section .materialContainer .sign-category {
    width: 100%;
    text-align: center;
    color: rgba(35, 35, 35, 0.4);
    font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (1920 - 320)));
    margin: 10px auto;
    position: relative
}

.login-section .materialContainer .sign-category span {
    background-color: #fff;
    z-index: 1;
    padding: 0 12px
}

.login-section .materialContainer .sign-category::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    color: rgba(35, 35, 35, 0.2);
    z-index: -1;
    background-color: rgba(35, 35, 35, 0.2)
}

.login-section .materialContainer .overbox {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: inherit;
    border-radius: 10px;
    padding: 60px 50px 40px 50px
}

.login-section .materialContainer .overbox .login-title,
.login-section .materialContainer .overbox .button,
.login-section .materialContainer .overbox .input {
    z-index: 111;
    position: relative;
    color: #fff !important;
    display: none
}

.login-section .materialContainer .overbox .login-title {
    width: 80%
}

.login-section .materialContainer .overbox .input {
    margin-top: 20px
}

.login-section .materialContainer .overbox .input input,
.login-section .materialContainer .overbox .input label {
    color: #fff
}

.login-section .materialContainer .overbox .material-button,
.login-section .materialContainer .overbox .alt-2 {
    display: block
}

.login-section .materialContainer .overbox .material-button .shape,
.login-section .materialContainer .overbox .alt-2 .shape {
    display: block
}

.login-section .materialContainer .material-button,
.login-section .materialContainer .alt-2 {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: var(--theme-color);
    position: absolute;
    top: 40px;
    right: -70px;
    cursor: pointer;
    z-index: 100;
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%)
}

.login-section .materialContainer .material-button .shape,
.login-section .materialContainer .alt-2 .shape {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%
}

.login-section .materialContainer .material-button .shape:before,
.login-section .materialContainer .alt-2 .shape:before,
.login-section .materialContainer .material-button .shape:after,
.login-section .materialContainer .alt-2 .shape:after {
    content: "";
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg)
}

.login-section .materialContainer .material-button .shape:before,
.login-section .materialContainer .alt-2 .shape:before {
    width: 25px;
    height: 4px
}

.login-section .materialContainer .material-button .shape:after,
.login-section .materialContainer .alt-2 .shape:after {
    height: 25px;
    width: 4px
}

.login-section .materialContainer .material-button.active,
.login-section .materialContainer .alt-2.active {
    top: 50%;
    right: 50%;
    -webkit-transform: translate(50%, -50%) rotate(0deg);
    transform: translate(50%, -50%) rotate(0deg)
}

body.dark {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #cfd4da
}

.dark h1 {
    color: #fff
}

.dark h2 {
    color: #f1f3f5
}

.dark h3 {
    color: #eaedef
}

.dark h4 {
    color: #e7eaec
}

.dark h5 {
    color: #dee2e6
}

.dark h6 {
    color: #cfd4da
}

.dark p {
    color: #cfd4da
}

.dark li {
    color: #cfd4da
}

.dark a {
    color: #cbcbcb
}

.dark .border-top {
    border-color: #404040 !important
}

.dark .bg-light {
    background-color: #232323 !important
}

.dark .form-control {
    color: #cfd4da;
    background-color: #2b2b2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border: 1px solid #404040
}

.dark mark,
.dark .mark {
    background-color: #232323;
    color: #fff
}

.dark .btn-solid {
    color: #fff !important
}

.dark .btn-solid:hover {
    color: #222 !important
}

.dark select {
    color: #cfd4da
}

.dark option {
    background-color: #2b2b2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .bg-theme {
    background-color: #232323
}

.dark .pixelstrap a,
.dark .pixelstrap:hover,
.dark .pixelstrap:active {
    color: #f1f3f5
}

.dark .sm-vertical {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .sidenav nav {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .sidenav .sidebar-back {
    color: #f1f3f5;
    border-color: #404040
}

.dark .order-left-image .order-image-contain p span {
    color: #fff
}

.dark .custom-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1)
}

.dark .checkbox_animated::after {
    background: #2b2b2b;
    border-color: #232323
}

.dark .secondary-box .static-top-widget .media-body h3,
.dark .warning-box .static-top-widget .media-body h3,
.dark .primary-box .static-top-widget .media-body h3,
.dark .danger-box .static-top-widget .media-body h3 {
    background-color: #2b2b2b
}

.dark .progtrckr li.progtrckr-todo:before {
    color: silver;
    background-color: #2b2b2b
}

.dark .tracker-table thead th {
    background-color: #232425
}

.dark .tracker-table tbody tr td h6 {
    color: #cfd4da
}

.dark .breadcrumb .breadcrumb-item a .feather {
    stroke: var(--theme-color)
}

.dark .right-sidebar {
    background-color: #232323
}

.dark .all-package thead tr th {
    color: #fff;
    background-color: #232323
}

.dark .all-package tbody tr:nth-child(even) {
    background-color: #232324
}

.dark .all-package tbody tr td {
    border-bottom: 1px solid #404040 !important;
    color: #fff
}

.dark .all-package tbody tr td:last-child .feather:first-child {
    stroke: #42ba96
}

.dark .all-package tbody tr td.order-continue span {
    color: blue;
    border-color: blue
}

.dark .pixelstrap ul {
    background: #232323;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark .pixelstrap ul a {
    color: #e7eaec
}

.dark .pixelstrap ul a:hover,
.dark .pixelstrap ul a:focus,
.dark .pixelstrap ul a:active,
.dark .pixelstrap ul a.highlighted {
    color: #e7eaec
}

.dark .pixelstrap .home-menu,
.dark .pixelstrap .feature-menu,
.dark .pixelstrap .category-menu,
.dark .pixelstrap .full-mega-menu,
.dark .pixelstrap .clothing-menu {
    -webkit-box-shadow: 0 0 1px 0 #2b2b2b;
    box-shadow: 0 0 1px 0 #2b2b2b
}

.dark .breadcrumb-item.active {
    color: #e7eaec
}

.dark .onhover-dropdown .onhover-show-div {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark .team h2 {
    border-color: #404040
}

.dark .pixelstrap.light-font-menu li>a {
    color: #f1f3f5
}

.dark .product-box .product-detail h4,
.dark .product-box .product-info h4 {
    color: #e7eaec
}

.dark .dark-overlay {
    background-blend-mode: overlay;
    background-color: #232323
}

.dark .table tbody+tbody {
    border-color: #404040
}

.dark .table thead th {
    border-color: #404040 !important
}

.dark .irs-from,
.dark .irs-to,
.dark .irs-single {
    color: #cbcbcb
}

.dark .irs-line {
    background: #2b2b2b
}

.dark .order-box .title-box {
    color: #eaedef;
    border-color: #404040
}

.dark .order-box .qty {
    border-color: #404040
}

.dark .order-box .qty li {
    color: #cfd4da
}

.dark .order-box .qty li span {
    color: #dee2e6
}

.dark .order-box .sub-total {
    border-color: #404040
}

.dark .order-box .sub-total li {
    color: #cfd4da
}

.dark .order-box .sub-total .shopping-option label {
    color: #929292
}

.dark .order-box .total li {
    color: #cfd4da
}

.dark .table th,
.dark .table td {
    border-color: #404040;
    color: #fff
}

.dark .dashboard .box-head h2 {
    color: #f1f3f5
}

.dark .dashboard .box .box-title {
    border-color: #404040
}

.dark .dashboard .box .box-title h3 {
    color: #eaedef
}

.dark .card {
    background-color: #2b2b2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .card .card-header {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .bg-white {
    background-color: #2b2b2b !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .product-box .img-wrapper .cart-box {
    background-color: #2b2b2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark .btn-close {
    color: #fff
}

.dark .product-right .product-title {
    color: #cfd4da
}

.dark .product-right .border-product {
    border-color: #404040
}

.dark .product-right .product-icon .product-social li a {
    color: #cbcbcb
}

.dark .product-right .product-icon .wishlist-btn {
    color: #cbcbcb
}

.dark .product-right .product-icon .wishlist-btn i {
    border-color: #404040
}

.dark .product-right.product-form-box {
    border-color: #404040
}

.dark .product-right.product-form-box .timer {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .product-right .timer {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .product-right .timer p {
    color: #cfd4da
}

.dark .product-right .size-box ul li {
    background-color: #2b2b2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-color: #404040
}

.dark .product-right .size-box ul li.active {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .product-right .size-box ul li a {
    color: #cbcbcb
}

.dark .product-right h4 del {
    color: #929292
}

.dark .product-right .product-count {
    background-color: #232323
}

.dark .timer span .timer-cal {
    color: #929292
}

.dark .qty-box .input-group span button {
    background: #2b2b2b !important;
    border-color: #404040
}

.dark .qty-box .input-group button i {
    color: #929292
}

.dark .nav-tabs {
    border-color: #404040
}

.dark .nav-tabs .nav-link.active {
    background-color: #2b2b2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .bundle .bundle_detail .price_product {
    color: #fff
}

.dark .modal-content {
    background-color: #232323;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark button.close {
    color: #fff
}

.dark .background {
    background-color: #404040;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .background .contain-bg {
    background-color: #2b2b2b;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .background .contain-bg h4 {
    color: #e7eaec
}

.dark .background .contain-bg:hover h4 {
    color: var(--theme-color)
}

.dark .footer-title {
    border-color: #404040
}

.dark .demo-right a {
    background-color: #000;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.dark .latest-order-table .btn {
    color: #fff
}

.dark .rtl-btn {
    background-color: #232323;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dark .page-main-header .main-header-right {
    background-color: #2b2b2b
}

.dark .page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(4) {
    border-color: #404040
}

.dark .page-main-header .main-header-right .nav-right .profile-dropdown li a {
    color: #cbcbcb
}

.dark .page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
    stroke: #cbcbcb
}

.dark .page-main-header .main-header-right .nav-right>ul>li {
    border-color: #404040 !important
}

.dark .page-main-header .main-header-right .nav-right>ul>li:nth-child(5) {
    border-color: #404040
}

.dark .page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:before {
    background: #404040 
}

.dark .page-wrapper .page-main-header {
    background-color: #232323
}

.dark .page-wrapper .page-body-wrapper .page-body {
    background-color: #232323
}

.dark .page-wrapper .page-body-wrapper .page-header .row h3 {
    color: #eaedef
}

.dark .page-wrapper .page-body-wrapper .page-sidebar {
    background: #2b2b2b
}

.dark .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
    color: #cbcbcb
}

.dark .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header.active {
    color: #fff
}

.dark .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li.active>a {
    color: #cbcbcb
}

.dark .card {
    background-color: #2b2b2b
}

.dark .card .card-header {
    border-color: #404040;
    background-color: #2b2b2b
}

.dark .card .card-header .btn {
    color: #fff
}

.dark .nav-menus .search-form input {
    background-color: #232323;
    border-color: #404040
}

.dark .nav-menus .onhover-dropdown:hover .onhover-show-div:after {
    border-bottom-color: #404040
}

.dark .nav-menus .onhover-dropdown:hover .onhover-show-div:before {
    border-bottom-color: #404040
}

.dark .ct-label {
    fill: rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.4)
}

.dark .table {
    color: #dee2e6
}

.dark .table td a i {
    color: #dddada
}

.dark footer {
    background-color: #2b2b2b;
    border-color: #404040
}

.dark .sales-carousel .value-graph h3 {
    color: #eaedef
}

.dark .user-status table thead tr th {
    color: #cfd4da
}

.dark ul.notification-dropdown.onhover-show-div li {
    border-color: #404040
}

.dark ul.notification-dropdown.onhover-show-div li+li:hover {
    background-color: #2b2b2b
}

.dark .page-main-header .main-header-right .nav-right .language-dropdown li a {
    color: #cbcbcb
}

.dark .order-graph h6 {
    color: #cfd4da
}

.dark .order-graph .order-graph-bottom h6 span {
    color: #cfd4da
}

.dark .custom-theme li {
    background-color: #232323;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.54);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.54)
}

.dark .custom-theme li.demo-li a {
    color: #cfd4da
}

.dark .jsgrid-grid-header {
    border-color: #404040
}

.dark .jsgrid-cell,
.dark .jsgrid-grid-body {
    background: #404040;
    border-color: #404040
}

.dark .jsgrid-alt-row .jsgrid-cell {
    background: #232323
}

.dark .jsgrid .jsgrid-pager [class*="jsgrid-pager"] {
    border-color: #404040
}

.dark .jsgrid-header-scrollbar::-webkit-scrollbar-track {
    background: #2b2b2b
}

.dark .jsgrid-header-row>.jsgrid-header-cell {
    border-color: #404040
}

.dark .jsgrid-header-row>.jsgrid-cell {
    background-color: #2b2b2b
}

.dark .jsgrid-header-row .jsgrid-header-cell {
    background-color: #232323
}

.dark .add-product ul li .box-input-file {
    background-color: #232323
}

.dark .needs-validation .custom-select,
.dark .needs-validation textarea {
    background: #2b2b2b;
    color: #cfd4da;
    border-color: #404040
}

.dark .add-product-form .qty-box .input-group .btn-primary {
    border-color: #404040 !important
}

.dark .dataTables_wrapper .dataTables_length,
.dark .dataTables_wrapper .dataTables_filter,
.dark .dataTables_wrapper .dataTables_info,
.dark .dataTables_wrapper .dataTables_processing,
.dark .dataTables_wrapper .dataTables_paginate {
    color: #cbcbcb
}

.dark .dataTables_wrapper table.dataTable.row-border tbody th,
.dark .dataTables_wrapper table.dataTable.row-border tbody td {
    border-color: #404040 !important
}

.dark .dataTables_wrapper table.dataTable.display tbody th,
.dark .dataTables_wrapper table.dataTable.display tbody td {
    border-color: #404040 !important
}

.dark .tab2-card .nav-tabs .nav-link {
    color: #cbcbcb
}

.dark .dataTables_wrapper .dataTables_paginate {
    border-color: #404040
}

.dark .dataTables_wrapper .dataTables_paginate .paginate_button {
    color: #cbcbcb !important
}

.dark .dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dark .dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dark .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    color: #cbcbcb !important
}

.dark .dataTables_wrapper table.dataTable {
    border-color: #404040
}

.dark .dataTables_wrapper table.dataTable tbody tr {
    background-color: #2b2b2b
}

.dark .dataTables_wrapper table.dataTable tbody tr .sorting_1 {
    background-color: #2b2b2b !important
}

.dark .dataTables_wrapper table.dataTable tbody tr.odd {
    background-color: #2b2b2b
}

.dark .dataTables_wrapper .dataTables_filter input[type="search"] {
    background-color: #2b2b2b;
    border-color: #404040
}

.dark .dataTables_wrapper table.dataTable th {
    background-color: #232323 !important;
    border-color: #404040
}

.dark .dataTables_wrapper .dataTables_length label select {
    background-color: #2b2b2b;
    border-color: #404040
}

.dark .right-sidebar {
    -webkit-box-shadow: 0 0 9px 2px rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 9px 2px rgba(255, 255, 255, 0.05)
}

.dark .right-sidebar .modal-header {
    border-color: #404040;
    background-color: #232323
}

.dark .right-sidebar svg {
    color: #8e8e8e
}

.dark .right-sidebar .friend-list-search {
    background-color: #232323
}

.dark .right-sidebar .friend-list-search input {
    background-color: #2b2b2b;
    border: 1px solid #232323
}

.dark .right-sidebar .friend-list-search .fas {
    color: #8e8e8e
}

.dark .right-sidebar .friend-list-name {
    background-color: #232323
}

.dark .right-sidebar .friend-list-name .clearfix .status-circle {
    border: 2px solid #232323
}

.dark .right-sidebar .friend-list-name .chat-box .about .name {
    color: #fff
}

.dark .cart-section thead tr th {
    border-top: 1px solid #404040 !important;
    border-bottom: 1px solid #404040 !important;
    color: #fff
}

.dark .cart-section thead tr th a {
    color: #fff
}

.dark .cart-section tbody tr.table-order:last-child {
    border-bottom: 1px solid #404040
}

.dark .cart-section tbody tr th {
    border-top: 1px solid #404040;
    border-bottom: 1px solid #404040 !important
}

.dark .order-details-table .table-details .table tbody tr td h5 {
    color: #fff
}

.dark .order-details-table .table-details .table tfoot tr.table-order:nth-child(n+3) {
    border-bottom: 1px solid #404040
}

.dark .order-details-table .table-details .table tfoot tr.table-order h4 {
    color: #fff
}

.dark .order-details-table .table-details .table tfoot tr.table-order h5 {
    color: #8e8e8e
}

.dark .order-details-table .order-success {
    background-color: #232323;
    border: 1px solid #232323
}

.dark .order-details-table .order-success h4 {
    color: #fff
}

.dark .progress {
    background-color: #232426
}

.dark .modal-header .btn-close {
    margin-right: auto;
    margin-left: unset
}

.dark .rating i {
    color: #404040
}

.dark .needs-validation .checkbox label::before {
    border: 1px solid #404040;
    background-color: #232425
}

.dark .ck.ck-editor__main>.ck-editor__editable {
    background-color: #232324
}

.dark .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: #404040
}

.dark .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #404040
}

.dark .ck.ck-toolbar {
    background: #232324;
    border: 1px solid #404040
}

.dark .ck.ck-toolbar .ck.ck-toolbar__separator {
    background-color: #777
}

.dark .ck.ck-list {
    background-color: #232425
}

.dark .ck.ck-dropdown__panel {
    background: #232425;
    border: 1px solid #404040
}

.dark .ck.ck-reset_all * {
    color: #ddd
}

.dark .ck.ck.ck-button:not(.ck-disabled):hover,
.dark .ck a.ck.ck-button:not(.ck-disabled):hover {
    background-color: #2b2b2b
}

.dark .btn-light {
    background-color: #232324 !important;
    border-color: #232324 !important;
    color: #fff
}

.dark .btn-light:hover {
    background-color: #6d6e70 !important;
    border-color: #6d6e70 !important
}

.dark .radio_animated:after {
    background: #232324;
    border: 2px solid #232324
}

.dark .search-form.search-box .form-group::before {
    background-color: #404040
}

.dark .search-form.search-box .form-group input {
    background-color: #232323;
    border: 1px solid #404040
}

@media (max-width: 1199px) {
    .dark .sm-horizontal {
        background-color: #2b2b2b;
        border-color: #404040
    }

    .dark .sm-horizontal .mobile-back {
        border-color: #404040;
        color: #e7eaec
    }

    .dark .sm-horizontal.pixelstrap ul {
        background-color: #2b2b2b
    }
}

@media (max-width: 577px) {
    .dark .mobile-fix-option {
        background-color: #2b2b2b
    }
}

.warning-box .static-top-widget .icons-widgets .text-center {
    background-color: #ffbc58
}

.warning-box .static-top-widget .icons-widgets .text-center .feather {
    fill: #ffbc58;
    stroke: #fff
}

.warning-box .static-top-widget .media-body {
    color: #ffbc58 !important
}

.warning-box .static-top-widget .media-body h3 {
    color: #ffbc58;
    background-color: #fff
}

.secondary-box .static-top-widget .icons-widgets .text-center {
    background-color: #13c9ca
}

.secondary-box .static-top-widget .icons-widgets .text-center .feather {
    fill: #13c9ca;
    stroke: #fff
}

.secondary-box .static-top-widget .media-body {
    color: #13c9ca !important
}

.secondary-box .static-top-widget .media-body h3 {
    color: #13c9ca;
    background-color: #fff
}

.primary-box .static-top-widget .icons-widgets .text-center {
    background-color: var(--theme-color)
}

.primary-box .static-top-widget .icons-widgets .text-center .feather {
    fill: var(--theme-color);
    stroke: #fff
}

.primary-box .static-top-widget .media-body {
    color: var(--theme-color) !important
}

.primary-box .static-top-widget .media-body h3 {
    color: var(--theme-color);
    background-color: #fff
}

.danger-box .static-top-widget .icons-widgets .text-center {
    background-color: #a5a5a5
}

.danger-box .static-top-widget .icons-widgets .text-center .feather {
    fill: #a5a5a5;
    stroke: #fff
}

.danger-box .static-top-widget .media-body {
    color: #a5a5a5 !important
}

.danger-box .static-top-widget .media-body h3 {
    color: #a5a5a5;
    background-color: #fff
}

label {
    margin-bottom: 0.5rem
}

.form-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.chart-sparkline {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    background: rgba(49, 49, 49, 0.7);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
    color: #fff;
    font: 10px Nunito, sans-serif;
    text-align: left;
    white-space: nowrap;
    padding: 5px;
    border: 1px solid rgba(49, 49, 49, 0.75);
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    z-index: 10000;
    border-radius: 5px
}

.chart-sparkline .jqsfield {
    color: #fff;
    font: 10px Nunito, sans-serif;
    text-align: left
}

.checkbox_animated {
    cursor: pointer;
    position: relative;
    margin: 0 16px 0 0
}

.checkbox_animated:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: rotate(-45deg) scale(0, 0);
    transform: rotate(-45deg) scale(0, 0);
    content: "";
    position: absolute;
    left: 0.25rem;
    top: 0.225rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.375rem;
    border: 2px solid #ff4c3b;
    border-top-style: none;
    border-right-style: none
}

.checkbox_animated:after {
    content: "";
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: #fff;
    border: 2px solid #e8ebf2;
    cursor: pointer
}

.checkbox_animated:checked:before {
    -webkit-transform: rotate(-45deg) scale(1, 1);
    transform: rotate(-45deg) scale(1, 1)
}

.radio_animated {
    position: relative;
    margin: 0 16px 0 0;
    cursor: pointer
}

.radio_animated:before {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    content: "";
    position: absolute;
    top: 0;
    left: 0.125rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.75rem;
    background: #ff4c3b;
    border-radius: 50%
}

.radio_animated:after {
    content: "";
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: #fff;
    border: 2px solid #e8ebf2;
    border-radius: 50%
}

.radio_animated:checked:before {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1)
}

a:hover {
    color: #ff4c3b
}

.btn-popup {
    margin-bottom: 30px
}

.media-table table tr th:last-child,
.media-table table tr td:last-child {
    text-align: left !important
}

.product-physical table tr th:nth-child(2),
.product-physical table tr td:nth-child(2) {
    text-align: left
}

.timer {
    padding-left: 10px;
    padding-right: 10px
}

.timer span {
    text-align: center;
    position: relative
}

.timer span .padding-l {
    padding-left: 0;
    position: absolute;
    left: 35px;
    top: 10px
}

.add-product img {
    width: 500px
}

.add-product ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px
}

.add-product ul li .box-input-file {
    width: 50px;
    height: 50px;
    background-color: #f8f8f9;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle
}

.add-product ul li .box-input-file i {
    color: #ff4c3b
}

.add-product ul li .box-input-file .upload {
    position: absolute;
    width: 70px;
    left: 0;
    right: 0;
    opacity: 0
}

.digital-add .col-form-label {
    font-family: Nunito
}

.digital-add .form-control {
    font-size: 14px
}

.digital-add .form-group .radio_animated {
    margin-right: 8px
}

.digital-add textarea {
    width: 100%
}

.add-product-form .form-group {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.add-product-form .form-group label {
    font-size: 16px;
    font-weight: 600
}

.add-product-form .form-group:last-child {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end
}

.add-product-form .form-group .form-control {
    font-size: 14px
}

.add-product-form .qty-box .input-group {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.add-product-form .qty-box .input-group button {
    padding: 12px
}

.add-product-form .qty-box .input-group .btn-primary {
    background-color: rgba(0, 0, 0, 0) !important;
    border: 1px solid #ddd !important
}

.add-product-form .qty-box .input-group .btn-primary.bootstrap-touchspin-down {
    border-right: none !important
}

.ck-editor .ck-editor__main .ck-editor__editable {
    height: 140px !important
}

.zoomContainer {
    top: 225px !important
}

.chart-block canvas {
    width: 100%
}

.sell-graph canvas {
    width: 100% !important;
    height: 300px !important
}

.sales-carousel .value-graph h3 {
    color: #313131;
    font-weight: 600
}

.sales-carousel .media .small-box {
    width: 50px;
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.small-chartjs canvas {
    width: 100% !important;
    height: 60px !important
}

.order-graph {
    overflow: hidden
}

.order-graph h6 {
    color: #313131;
    font-family: Nunito;
    font-weight: 700;
    margin-bottom: 20px
}

.order-graph .order-graph-bottom {
    margin-top: 30px
}

.order-graph .order-graph-bottom.sales-location {
    margin-top: 25px
}

.order-graph .order-graph-bottom h6 {
    color: #999;
    margin-left: 15px;
    margin-right: 30px
}

.order-graph .order-graph-bottom h6 span {
    color: #313131
}

.order-graph .order-graph-bottom .media {
    margin-bottom: 20px
}

.order-graph .order-graph-bottom .media:last-child {
    margin-bottom: 0
}

.datepickers-container {
    top: -86px
}

.datepicker--nav {
    color: #ff4c3b
}

.datepicker--nav-action {
    color: #ff4c3b;
    background-color: #ff4c3b
}

.datepicker--nav-action:hover {
    color: #ff4c3b
}

.datepicker--nav-title i {
    color: #ff4c3b
}

.table thead th {
    border-bottom: 2px solid #e8ebf2
}

.switch-sm .switch {
    width: 25px;
    height: 16px;
    margin-top: 10px;
    margin-bottom: 0px
}

.page-wrapper .page-body-wrapper .page-sidebar.open~.page-body .activity .media .gradient-round.gradient-line-1:after {
    bottom: -66px
}

.page-wrapper .page-body-wrapper footer .footer-copyright {
    text-align: left
}

.page-wrapper .page-body-wrapper footer .pull-right {
    text-align: right
}

.page-wrapper .page-body-wrapper footer p {
    line-height: 1.7
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li {
    width: 100%
}

 .page-header .row h3 {
    color: #000
}

.widget-cards {
    border-radius: 10px !important
}

.static-top-widget div.align-self-center svg {
    width: 25px;
    height: 25px;
    vertical-align: middle
}

.static-top-widget .media-body {
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.static-top-widget .media-body.media-body {
    margin-left: 20px
}

.static-top-widget .media-body h3 {
    font-family: work-Sans, sans-serif;
    color: #fff
}

.static-top-widget .media-body h3 small {
    font-size: 11px
}

.static-top-widget .icons-widgets .text-center {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.flot-chart-container {
    height: 400px
}

.user-status table tbody tr td {
    vertical-align: middle
}

.user-status table tbody tr td .d-inline-block {
    margin-top: 11px
}

.user-status table tbody tr td .image-sm-size img {
    width: 41px
}

.user-status table thead tr th {
    border-top: 0;
    font-size: 16px;
    color: #2a3142;
    font-weight: 600;
    padding-top: 0
}

.card-block .table-responsive .table caption {
    padding-left: 10px
}

.card-block .table-border-radius {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}

.card-block .default-checkbox-align #checkbox1 {
    margin-right: 10px
}

.card-block .default-checkbox-align #radio {
    margin-right: 5px
}

.chart-vertical-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.chart-vertical-center #myDoughnutGraph,
.chart-vertical-center #myPolarGraph {
    width: auto !important
}

.products-admin .product-box {
    padding: 20px
}

.products-admin .product-box .front .product-hover {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    opacity: 0;
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.products-admin .product-box .front .product-hover ul li {
    display: inline-block;
    -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
    padding: 9px 14px;
    background-color: #fff;
    font-size: 18px;
    border-radius: 100%;
    line-height: 1.6;
    height: 45px;
    width: 45px;
    margin: 0 3px
}

.products-admin .product-box .front .product-hover ul li .btn {
    padding: 0;
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    
}


.products-admin .product-box .front:hover .product-hover {
    opacity: 1;
    border-radius: 0%;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease
}

.product-page-main .product-slider .owl-stage-outer {
    margin-top: 0
}

.product-page-main .product-slider .owl-carousel .owl-stage-outer .owl-stage .owl-item.active.current {
    border: 1px solid #ff4c3b
}

.product-page-main .owl-stage-outer {
    margin-top: 30px
}

.product-page-main .owl-stage-outer .owl-stage .owl-item.active.current {
    border: 1px solid #ff4c3b !important
}

.br-theme-fontawesome-stars-o .br-widget a {
    color: #ffa800
}

.br-theme-fontawesome-stars-o .br-widget a .br-selected,
.br-theme-fontawesome-stars-o .br-widget a .br-active:after {
    color: #ffa800;
    font: normal normal normal 14px/1 FontAwesome
}

.nav-pills .nav-link.active,
.nav-pills .nav-link>.nav-link,
.nav-pills .show.active,
.nav-pills .show>.nav-link {
    background-color: #ff4c3b
}

.deactivate-account {
    margin-top: 30px
}

.deactivate-account .btn {
    margin-top: 20px
}

.account-setting h5 {
    margin-bottom: 20px
}

.tab2-card ul {
    margin-bottom: 30px;
    border-bottom: 1px solid #dee2e6
}

.tab2-card ul li a svg {
    width: 18px;
    vertical-align: middle
}

.tab2-card .nav-tabs .nav-link {
    color: #333
}

.tab2-card .nav-tabs .nav-link.active,
.tab2-card .nav-tabs .nav-link:focus,
.tab2-card .nav-tabs .nav-link:hover {
    color: #ff4c3b;
    border-color: rgba(0, 0, 0, 0);
    border-bottom: 2px solid #ff4c3b
}

.tab2-card .nav-tabs .nav-item.show .nav-link {
    border-color: rgba(0, 0, 0, 0);
    border-bottom: 2px solid #ff4c3b
}

.profile-details img {
    margin-bottom: 20px
}

.profile-details .social {
    margin-top: 15px
}

.profile-details .social .btn-showcase {
    margin-bottom: -10px
}

.profile-details .social .btn-showcase .btn {
    padding: 8px 13px;
    margin: 0 3px 20px;
    border-radius: 100%;
    width: 40px;
    height: 40px
}

.profile-details .social .btn-showcase .btn-fb {
    background-color: #50598e;
    color: #fff
}

.profile-details .social .btn-showcase .btn-google {
    background-color: #c64e40;
    color: #fff
}

.profile-details .social .btn-showcase .btn-twitter {
    background-color: #6fa2d8;
    color: #fff
}

.project-status {
    margin-top: 20px
}

.project-status .media {
    margin-top: 20px
}

.sm-progress-bar {
    height: 6px
}

.profile-table table tbody tr td:first-child {
    width: 250px
}

.profile-table table th,
.profile-table table td {
    border-top: none
}

.report-employee .card-header {
    border-bottom: none !important
}

.report-employee .flot-chart-container {
    height: 323px
}

.sales-chart {
    height: 307px
}

.sales-chart svg .ct-series-b .ct-point,
.sales-chart svg .ct-series-b .ct-line,
.sales-chart svg .ct-series-b .ct-bar,
.sales-chart svg .ct-series-b .ct-slice-donut {
    stroke: #a5a5a5
}

.expense-chart #area-chart1 {
    height: 307px
}

.tab2-card .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.tab2-card .media img {
    margin-right: 20px
}

footer.blockquote-footer {
    bottom: unset
}

.bg-black {
    background-color: #000
}

.datepicker {
    -webkit-box-shadow: 0 4px 14px rgba(255, 76, 59, 0.15);
    box-shadow: 0 4px 14px rgba(255, 76, 59, 0.15)
}

.datepicker--day-name {
    color: #000;
    font-weight: bold
}

.datepicker--cell.-current- {
    color: #000;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid #ff4c3b
}

.datepicker--cell.-focus- {
    background: #ff4c3b;
    color: #fff
}

.datepicker--cell.-selected- {
    background: #ff4c3b
}

.dropzone .dz-preview {
    -webkit-box-shadow: 0px 0px 3px #ff4c3b;
    box-shadow: 0px 0px 3px #ff4c3b
}

.dropzone .dz-preview .dz-error-message {
    color: #ff4c3b !important;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #ff4c3b !important
}

.dropzone .dz-preview .dz-error-message:after {
    border-bottom: 6px solid #ff4c3b !important
}

.tab-coupon {
    margin-bottom: 30px
}

.needs-validation .permission-block .attribute-blocks {
    padding-left: 15px
}

.needs-validation .permission-block .attribute-blocks .row {
    padding-left: 20px
}

.needs-validation .permission-block .attribute-blocks+.attribute-blocks h5 {
    margin-top: 30px
}

.needs-validation .permission-block .attribute-blocks h6 {
    border-bottom: 1px solid #eff0f1;
    margin-bottom: 15px;
    padding-bottom: 5px
}

.needs-validation h4 {
    font-weight: 600;
    margin-bottom: 20px
}

.needs-validation input {
    font-size: 14px
}

.needs-validation .form-group {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.needs-validation .form-group label {
    margin-bottom: 0
}

 label span {
    color: red
}

.needs-validation .editor-label {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: end
}

.needs-validation .editor-space {
    padding: 0
}

.needs-validation .checkbox input {
    opacity: 0
}

.needs-validation .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px
}

.needs-validation .checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    margin-left: -16px;
    border: 1px solid #e8ebf2;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out
}

.needs-validation .checkbox input[type="checkbox"]:checked+label::before {
    font-family: IcoFont;
    content: "\efab";
    text-align: center;
    line-height: 1;
    font-size: 18px
}

.needs-validation .checkbox-primary input[type="checkbox"]:checked+label::before {
    border-color: #ff4c3b;
    color: #ff4c3b
}

.needs-validation .radio-animated label {
    margin-right: 20px
}

.needs-validation textarea {
    border: 1px solid #ced4da;
    border-radius: 0.25rem
}

.badge-warning {
    color: #fff
}

div.dataTables_wrapper div.dataTables_paginate {
    margin-top: 25px
}

.dataTables_wrapper .dataTables_length {
    margin-bottom: 30px
}

.dataTables_wrapper .dataTables_length label select {
    border-color: #eff0f1
}

.dataTables_wrapper .dataTables_filter input[type="search"] {
    border: 1px solid #eff0f1;
    padding: 0 15px;
    margin-left: 10px;
    height: 37px;
    border-radius: 0
}

.dataTables_wrapper .dataTables_paginate {
    margin-left: 15px !important;
    border: 1px solid #f6f7fb;
    border-radius: 0.25rem;
    padding-top: 0
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:active {
    background: #ff4c3b;
    color: #fff !important;
    border: 1px solid #ff4c3b;
    -webkit-box-shadow: none;
    box-shadow: none
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    border: 1px solid #ff4c3b;
    color: #2a3142 !important;
    background: transparent !important
}

.dataTables_wrapper table.dataTable {
    border: 1px solid #ebf1ff;
    width: 100%;
    overflow-x: auto
}

.dataTables_wrapper table.dataTable.row-border tbody th,
.dataTables_wrapper table.dataTable.row-border tbody td,
.dataTables_wrapper table.dataTable.display tbody th,
.dataTables_wrapper table.dataTable.display tbody td {
    border-top: 1px solid #ebf1ff !important
}

.dataTables_wrapper table.dataTable tbody tr {
    background-color: rgba(241, 244, 251, 0.5)
}

.dataTables_wrapper table.dataTable tbody tr:hover {
    background-color: rgba(241, 244, 251, 0.7)
}

.dataTables_wrapper table.dataTable tbody tr .sorting_1 {
    background-color: rgba(241, 244, 251, 0.2) !important
}

.dataTables_wrapper table.dataTable tbody tr.odd {
    background-color: #fff
}

.dataTables_wrapper table.dataTable .vendor-list {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.dataTables_wrapper table.dataTable .vendor-list img {
    margin-right: 20px;
    height: 40px;
    width: auto !important
}

.dataTables_wrapper table.dataTable td {
    padding: 0.75rem !important
}

.dataTables_wrapper table.dataTable th {
    background-color: #f1f4fb !important;
    padding: 24px 0.75rem !important
}

.dataTables_wrapper table.dataTable thead .sorting:before,
.dataTables_wrapper table.dataTable thead .sorting:after,
.dataTables_wrapper table.dataTable thead .sorting_asc:before,
.dataTables_wrapper table.dataTable thead .sorting_asc:after,
.dataTables_wrapper table.dataTable thead .sorting_desc:before,
.dataTables_wrapper table.dataTable thead .sorting_desc:after,
.dataTables_wrapper table.dataTable thead .sorting_asc_disabled:before,
.dataTables_wrapper table.dataTable thead .sorting_asc_disabled:after,
.dataTables_wrapper table.dataTable thead .sorting_desc_disabled:before,
.dataTables_wrapper table.dataTable thead .sorting_desc_disabled:after {
    bottom: 25px
}

.category-table tbody tr td .category-date {
    display: block
}

.category-table tbody tr td .category-date h6 {
    font-size: 15px;
    color: #232323;
    line-height: 1;
    margin-bottom: 3px
}

.category-table tbody tr td .category-date span {
    color: #777;
    font-size: 14px;
    margin-top: 5px;
    display: block
}

.order-table table tr td:nth-child(3),
.order-table table tr th:nth-child(3) {
    text-align: center !important
}

.product-list table tr td,
.product-list table tr th,
.report-table table tr td,
.report-table table tr th {
    text-align: left !important
}

.product-list table tr td:last-child,
.product-list table tr th:last-child,
.report-table table tr td:last-child,
.report-table table tr th:last-child {
    text-align: center !important
}

.user-list img {
    border-radius: 100%
}

.card {
    margin-bottom: 30px;
    border: 0px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 8px;
    -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05)
}

.card.card-load .card-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 8;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.card.card-load .card-loader i {
    margin: 0 auto;
    color: #ff4c3b;
    font-size: 20px
}

.card.full-card {
    position: fixed;
    top: 80px;
    z-index: 99999;
    -webkit-box-shadow: none;
    box-shadow: none;
    right: 0;
    border-radius: 0;
    border: 1px solid #eff0f1;
    width: calc(100vw - 272px);
    height: calc(100vh - 80px)
}

.card.full-card .card-body {
    overflow: auto
    
}

.card .card-header {
    background-color: #fff;
    border-bottom: none;
    border-bottom: 1px solid #f8f8f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: end
    /* justify-content: space-between */
}

.card .card-header.b-header {
    display: block
}

.card .card-header .card-header-right {
    border-radius: 0 0 0 7px;
    right: 20px;
    top: 25px;
    display: inline-block;
    padding: 7px 0;
    position: absolute
}

.card .card-header .card-header-right .card-option {
    text-align: right;
    width: 35px;
    height: 20px;
    overflow: hidden;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out
}

.card .card-header .card-header-right .card-option li {
    display: inline-block
}

.card .card-header .card-header-right i {
    margin: 0 5px;
    cursor: pointer;
    color: #2a3142;
    line-height: 20px
}

.card .card-header .card-header-right i.icofont-refresh {
    font-size: 13px
}

.card .card-header h5 {
    font-size: 18px;
    margin-bottom: 0;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 24px
}

.card .card-header>span {
    font-size: 12px;
    color: #777;
    margin-top: 5px;
    display: block;
    letter-spacing: 1px
}

 .card .card-body {
    padding: 120px 70px
}

.card .card-body p:last-child {
    margin-bottom: 0
}

.card .sub-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px
}

.card .card-footer {
    background-color: #fff;
    border-top: 1px solid #f8f8f9;
    padding: 30px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px
}

.card-header .nav-material {
    margin-bottom: -13px
}

footer {
    background-color: #fff;
    border-top: 1px solid #f8f8f9;
    padding: 15px;
    bottom: 0;
    left: 0
}

footer a {
    font-weight: 600
}

footer.footer-starter-kit {
    bottom: -52px
}

.blockquote-footer {
    margin-left: 0 !important;
    width: 885px !important
}

.page-wrapper .page-body-wrapper footer {
    margin-left: 255px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    bottom: 0;
    z-index: 8
}

.page-wrapper .page-body-wrapper footer p {
    color: #a5a5a5
}

.page-wrapper .page-body-wrapper footer p i {
    color: var(--theme-color);
    margin-left: 5px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li:hover>a,
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li.active>a {
    -webkit-transition: .3s;
    transition: .3s
}

.onhover-show-div {
    -webkit-box-shadow: 0 0 2px 2px #f8f8f9;
    box-shadow: 0 0 2px 2px #f8f8f9;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    left: 0
}

.nav-menus .onhover-dropdown .onhover-show-div {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    visibility: visible
}

.nav-menus .onhover-dropdown:hover .onhover-show-div:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2
}

.nav-menus .onhover-dropdown:hover .onhover-show-div:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #898989;
    content: "";
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1
}

.page-wrapper .page-main-header {
    border-radius: 0 !important;
    /* background-color: rgba(255, 255, 255, 0.149); */
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    height: 80px;
    /* position: fixed; */
    top: 0;
    z-index: 10;
    -webkit-box-shadow: none;
    box-shadow: none;
    /* width: calc(100% - 255px); */
    height: auto !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /* margin-left: 255px; */
    -webkit-transition: .3s;
    transition: .3s;
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) calc(5px + (27 - 5) * ((100vw - 320px) / (1920 - 320))) 0;
    border-radius: 10px;
    height: auto
}

.page-wrapper .page-main-header.open {
    margin-left: 0;
    width: 100%;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper  .page-body {
    min-height: calc(100vh - 80px);
    /* margin-top: 100px; */
    margin-top: 16px;
    padding: 0 15px;
    position: relative;
    background-color: #f8f8f9
}

.page-header {
    padding-top: 30px;
    padding-bottom: 30px
}

 .page-header .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.page-wrapper .page-header .row h3 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 800;
    text-transform: uppercase;
    font-family: Nunito
}

.page-wrapper  .page-header .row h3 small {
    display: block;
    font-size: 12px;
    margin-top: 7px;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: #777
}

.page-wrapper .page-header .breadcrumb {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    margin-bottom: 0
}

.page-wrapper  .page-header .breadcrumb.pull-right {
    float: right
}

.page-wrapper  .page-header .breadcrumb .breadcrumb-item {
    font-family: Nunito
}

.page-wrapper  .page-header .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
    content: "/"
}

.page-wrapper .page-header .breadcrumb .breadcrumb-item a svg {
    width: 14px;
    height: 14px;
    vertical-align: text-top
}

.offcanvas .page-wrapper .page-body {
    position: relative
}

.offcanvas .page-wrapper .page-body:before {
    position: fixed;
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 10;
    right: 0;
    left: 255px
}

.page-main-header {
    max-width: -webkit-fill-available;
}

.page-main-header .main-header-right {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 10px 0 rgba(34, 41, 47, 0.1);
    box-shadow: 0px 0px 10px 0 rgba(34, 41, 47, 0.1);
    border-radius: 8px
}

.page-main-header .main-header-right svg {
    color:  var(--theme-color)
}

.page-main-header .main-header-right svg line,
.page-main-header .main-header-right svg polyline {
    color: var(--theme-color)
}

.page-main-header .main-header-right .nav-left i {
    margin-right: 20px
}

.page-main-header .main-header-right .nav-left input:focus {
    outline: 0 !important
}

.page-main-header .main-header-right .nav-right {
    text-align: right;
    padding-left: 0px
}

.page-main-header .main-header-right .nav-right ul li .media img {
    border-radius: 5px;
    -webkit-box-shadow: 0 0 15px rgba(35, 35, 35, 0.15);
    box-shadow: 0 0 15px rgba(35, 35, 35, 0.15)
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation {
    position: relative;
    right: -7px;
    top: -16px
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #ff4c3b;
    border-radius: 70px;
    -webkit-animation: heartbit 1s ease-out;
    animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
    width: 6px;
    height: 6px;
    border-radius: 30px;
    background-color: #ff4c3b;
    position: absolute;
    right: 6px;
    top: -10px
}

.page-main-header .main-header-right .nav-right ul li svg {
    margin-top: 10px;
    width: 18px;
    height: 18px
}

.page-main-header .main-header-right .nav-right ul li svg path {
    color: var(--theme-color)
}

.page-main-header .main-header-right .nav-right ul li .dot {
    width: 3px;
    height: 3px;
    border-radius: 30px;
    background-color: #ff4c3b;
    position: absolute;
    right: 17px;
    bottom: 6px;
    -webkit-animation: blink 1.5s infinite;
    animation: blink 1.5s infinite
}

.page-main-header .main-header-right .nav-right ul li .dot-chat {
    right: 4px;
    bottom: -11px
}

.page-main-header .main-header-right .nav-right .notification {
    position: absolute;
    top: 21px;
    right: -1px;
    font-size: 9px;
    -webkit-animation: blink 1.5s infinite;
    animation: blink 1.5s infinite
}

.page-main-header .main-header-right .nav-right .icon-user {
    font-size: 16px
}

.page-main-header .main-header-right .nav-right>ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.page-main-header .main-header-right .nav-right>ul>li {
    position: relative;
    border-left: 1px solid #eff0f1;
    padding: 0 20px
}

.page-main-header .main-header-right .nav-right>ul>li:first-child {
    width: 30%;
    border-left: none !important
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group {
    width: 100%;
    position: relative;
    margin-bottom: 0
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:focus {
    outline-color: transparent
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group input:focus {
    outline-color: transparent
}

/* .page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 25px;
    background: #e8ebf2;
    left: 51px;
    top: 9px
}

.page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:after {
    position: absolute;
    content: "\f002";
    font-family: FontAwesome;
    top: 11px;
    left: 22px;
    color: #8e8e8e
} */

.page-main-header .main-header-right .nav-right>ul>li:last-child {
    border-left: none !important;
    padding-right: 0
}

.page-main-header .main-header-right .nav-right>ul>li:nth-child(5) {
    padding-right: 0
}

.page-main-header .main-header-right .nav-right>ul>li h6 {
    margin-top: 4px;
    margin-bottom: 4px;
    color: var(--theme-color)
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul {
    left: inherit;
    right: -10px;
    width: 130px
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul:before,
.page-main-header .main-header-right .nav-right>ul>li h6 ul:after {
    left: inherit;
    right: 10px
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul li {
    display: block
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul li a {
    font-size: 14px;
    color: #2a3142
}

.page-main-header .main-header-right .nav-right>ul>li h6 ul li a i {
    margin-left: 10px;
    font-size: 13px;
    color: #2a3142
}

.page-main-header .main-header-right .nav-right>ul .flag-icon {
    font-size: 16px
}

.page-main-header .main-header-right .nav-right .notification-dropdown {
    top: 57px
}

.page-main-header .main-header-right .nav-right .language-dropdown {
    width: 160px;
    text-align: left;
    top: 57px
}

.page-main-header .main-header-right .nav-right .language-dropdown li {
    padding-top: 10px
}

.page-main-header .main-header-right .nav-right .language-dropdown li a {
    color: #2a3142
}

.page-main-header .main-header-right .nav-right .language-dropdown li a i {
    margin-right: 10px
}

.page-main-header .main-header-right .nav-right .language-dropdown li:first-child {
    padding-top: 0
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
    right: -10px;
    left: inherit;
    width: 210px;
    top: 63px
}

.page-main-header .main-header-right .nav-right .profile-dropdown:before,
.page-main-header .main-header-right .nav-right .profile-dropdown:after {
    left: inherit;
    right: 10px
}

.page-main-header .main-header-right .nav-right .profile-dropdown li {
    display: block;
    text-align: left;
    padding-top: 10px
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(3) {
    padding-bottom: 10px
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(4) {
    border-top: 1px solid #eff0f1
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:first-child {
    padding-top: 0
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a {
    color: #ff4c3b;
    -webkit-transition: .3s;
    transition: .3s
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg {
    color: #ff4c3b !important
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg path,
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg line,
.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg polyline {
    color: #ff4c3b !important
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a {
    color: #313131;
    -webkit-transition: .3s;
    transition: .3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
    margin-right: 10px;
    color: #313131
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a svg polyline {
    color: #313131
}

.page-main-header .main-header-right li {
    display: inline-block;
    position: relative
}

.nav-menus .notification-badge {
    position: absolute;
    right: 10px;
    top: 1px;
    padding: 4px 7px
}

.nav-menus .onhover-dropdown img {
    cursor: pointer;
    position: relative
}

.nav-menus .onhover-dropdown:before {
    display: none
}

ul.notification-dropdown.onhover-show-div {
    width: 330px;
    right: -18px;
    left: initial
}

ul.notification-dropdown.onhover-show-div:before,
ul.notification-dropdown.onhover-show-div:after {
    left: inherit !important;
    right: 35px !important
}

ul.notification-dropdown.onhover-show-div li {
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #f8f8f9;
    text-align: left
}

ul.notification-dropdown.onhover-show-div li h6 small {
    padding-top: 5px;
    color: #898989;
    font-size: 12px
}

ul.notification-dropdown.onhover-show-div li span svg {
    margin-top: 0 !important;
    margin-right: 10px;
    vertical-align: text-top
}

ul.notification-dropdown.onhover-show-div li span .shopping-color path,
ul.notification-dropdown.onhover-show-div li span .shopping-color line {
    color: #ff4c3b
}

ul.notification-dropdown.onhover-show-div li span .download-color path,
ul.notification-dropdown.onhover-show-div li span .download-color line {
    color: #81ba00
}

ul.notification-dropdown.onhover-show-div li span .alert-color path,
ul.notification-dropdown.onhover-show-div li span .alert-color line {
    color: #a5a5a5
}

ul.notification-dropdown.onhover-show-div li p {
    margin-left: 30px
}

ul.notification-dropdown.onhover-show-div li+li:hover {
    background-color: #f8f8f9
}

.onhover-show-div {
    top: 80px;
    position: absolute;
    z-index: 8;
    background-color: #fff;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s
}

.onhover-show-div li a svg {
    margin-top: 0 !important
}

.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
    color: #313131 !important
}

.search-form.search-box {
    width: 25%
}

.search-form.search-box input {
    background-color: #f8f8f9
}

.search-form .form-group {
    width: 100%;
    position: relative;
    margin-bottom: 0
}

.search-form .form-group:focus {
    outline-color: transparent
}

.search-form .form-group input:focus {
    outline-color: transparent
}

/* .search-form .form-group:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 25px;
    background: #e8ebf2;
    left: 51px;
    top: 9px
}

.search-form .form-group:after {
    position: absolute;
    content: "\f002";
    font-family: FontAwesome;
    top: 11px;
    left: 22px;
    color: #8e8e8e
} */

.search-form input {
    border: 1px solid #eff0f1;
    padding: 10px 10px 10px 70px;
    border-radius: 5px;
    background-color: #f8f8f9
}

.page-wrapper .page-body-wrapper .sidebar {
    height: calc(100vh - 80px);
    overflow: auto;
    -webkit-box-shadow: 0 0 11px rgba(143, 164, 232, 0.08);
    box-shadow: 0 0 11px rgba(143, 164, 232, 0.08);
    background-color: #2f2f2f
}

.page-wrapper .page-body-wrapper .page-sidebar {
    width: 255px;
    position: fixed;
    background: #fff;
    top: 0;
    height: calc(100vh);
    z-index: 9;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-back {
    position: fixed;
    left: 265px;
    top: 10px;
    font-size: 26px;
    color: #2f2f2f;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar .main-header-left {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    height: 80px;
    padding: 12px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #2f2f2f;
    z-index: 10;
    -webkit-box-shadow: -3px 1px 3px 1px rgba(68, 102, 242, 0.1);
    box-shadow: -3px 1px 3px 1px rgba(68, 102, 242, 0.1)
}

.page-wrapper .page-body-wrapper .page-sidebar .main-header-left .logo-wrapper {
    padding-left: 10px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user {
    padding: 25px 10px;
    -webkit-box-shadow: 3px 2px 7px -1px rgba(127, 151, 249, 0.1);
    box-shadow: 3px 2px 7px -1px rgba(127, 151, 249, 0.1);
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6 {
    color: var(--theme-color);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.5px;
    margin-bottom: 3px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    margin-bottom: 0px;
    color: #fff
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user img {
    -webkit-box-shadow: 0 0 15px rgba(35, 35, 35, 0.15);
    box-shadow: 0 0 15px rgba(35, 35, 35, 0.15);
    border-radius: 7px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 20px 0
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
    font-size: 14px;
    letter-spacing: .5px;
    padding-bottom: 12px;
    padding-top: 12px;
    text-transform: capitalize;
    font-weight: 500;
    color: #fff;
    padding: 15px 18px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
    width: 14px;
    height: 14px;
    /* margin-right: 14px; */
    stroke-width: 3px;
    vertical-align: text-bottom
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu.menu-open li.active .fa-angle-down:before {
    content: "\f104";
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a {
    display: block;
    -webkit-transition: .3s;
    transition: .3s;
    border: none
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a.active {
    color: #fff;
    background: #fff;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.2)), color-stop(80%, rgba(255, 255, 255, 0)));
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%);
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    font-weight: 600
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a.active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #fff
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a:not(.active):hover {
    color: #fff;
    background: #fff;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.2)), color-stop(80%, rgba(255, 255, 255, 0)));
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%);
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 5px;
    position: relative
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a:not(.active):hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background: #fff
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a i {
    margin-right: 7px;
    text-align: right;
    margin-top: 3px;
    font-size: 15px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a i:before {
    content: ""
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a i~i {
    margin-right: 0
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a i.pull-right {
    float: right
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li .label {
    margin-top: 3px;
    margin-right: 5px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li .badge {
    margin-left: 50px;
    text-transform: capitalize
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.sidebar-header {
    margin-bottom: 0;
    padding: 15px;
    color: #313131;
    font-weight: 600;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li>a>.fa-angle-down {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
    margin-top: 10px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active>a>.fa-angle-right:before {
    content: "\f105";
    font-family: FontAwesome;
    -webkit-transition: .3s;
    transition: .3s
}
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active>.sidebar-submenu {
    display: block;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: transform .3s, opacity .3s;
    transition: transform .3s, opacity .3s, -webkit-transform .3s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    margin-left: 15px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active>.sidebar-submenu a i.pull-right {
    margin: 10px 30px 0 0;
    float: right
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu a {
    text-decoration: none
}

/* .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu {
    display: none;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
    list-style: none
} */

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu .sidebar-submenu {
    padding-top: 0 !important
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a {
    padding-bottom: 7px;
    padding-top: 7px;
    font-size: 14px;
    color: #898989;
    -webkit-transition: .3s;
    transition: .3s;
    text-transform: capitalize;
    position: relative;
    /* padding-left: 30px; */
    line-height: 2.5;
    letter-spacing: 0.7px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a>.fa-circle {
    width: 12px;
    font-size: 4px;
    position: absolute;
    left: 12px;
    top: 13px
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a>i {
    width: auto
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a>.fa-angle-down {
    width: auto
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a:hover {
    color: #fff;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a.active {
    color: #ff4c3b
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li.active>a {
    color: #fff
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li.active>a.active {
    color: #fff
}

.page-wrapper .page-body-wrapper .page-sidebar~.page-body {
    margin-left: 255px;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar.open {
    display: block;
    margin-left: calc(-260px)
}

.page-wrapper .page-body-wrapper .page-sidebar.open .sidebar-back {
    left: -30px;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar.open~.page-body {
    margin-left: 0;
    -webkit-transition: .3s;
    transition: .3s
}

.page-wrapper .page-body-wrapper .page-sidebar.open~footer {
    margin-left: 0;
    padding-right: 15px
}

.page-wrapper .page-body-wrapper .page-sidebar.open~.footer-fix {
    width: calc(100% - 0px)
}

.page-wrapper .page-body-wrapper .sidebar-close .page-sidebar {
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transform: translate(-255px);
    transform: translate(-255px)
}

.page-wrapper .page-body-wrapper .sidebar-close .page-body {
    -webkit-transition: .3s;
    transition: .3s;
    margin-left: 0 !important
}

.right-sidebar {
    top: 81px;
    right: -285px;
    height: 100%;
    position: fixed;
    width: 285px;
    z-index: 9;
    background-color: #fff;
    -webkit-transition: .5s;
    transition: .5s;
    -webkit-box-shadow: 0 0 9px 2px rgba(35, 35, 35, 0.05);
    box-shadow: 0 0 9px 2px rgba(35, 35, 35, 0.05);
    border-radius: 5px
}

.right-sidebar.show {
    right: 0;
    -webkit-transition: .3s;
    transition: .3s
}

.right-sidebar .modal-header .modal-title {
    padding-top: 2px
}

.right-sidebar .friend-list-search {
    position: relative;
    background-color: #f8f8f9;
    padding: 20px
}

.right-sidebar .friend-list-search input {
    color: #898989;
    width: 100%;
    background-color: #fff;
    border: 1px solid #f8f8f9;
    padding: 10px 15px;
    border-radius: 25px;
    letter-spacing: 1px
}

.right-sidebar .friend-list-search i {
    position: absolute;
    right: 35px;
    top: 34px;
    font-size: 14px;
    color: #8e8e8e
}

.right-sidebar .chat-box .people-list ul {
    padding-top: 20px
}

.right-sidebar .chat-box .people-list ul li {
    position: relative
}

.right-sidebar svg {
    width: 16px;
    height: 16px;
    margin-top: 5px
}

.opacity-0 {
    opacity: 0
}

.shadow-0 {
    -webkit-box-shadow: none;
    box-shadow: none
}

.digits {
    font-family: work-Sans, sans-serif
}

.custom-scrollbar {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
    opacity: 1;
    background-color: rgba(255, 76, 59, 0.1)
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(255, 76, 59, 0);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.line pre {
    font-size: 100%
}

.list-circle {
    list-style: circle
}

.chat-box .toogle-bar {
    display: none
}

.chat-box .people-list .search {
    position: relative
}

.chat-box .people-list .search .form-control {
    background-color: #f1f4fb;
    border: 1px solid #f8f8f9
}

.chat-box .people-list .search .form-control::-webkit-input-placeholder {
    color: 999999
}

.chat-box .people-list .search .form-control::-moz-placeholder {
    color: 999999
}

.chat-box .people-list .search .form-control:-ms-input-placeholder {
    color: 999999
}

.chat-box .people-list .search .form-control::-ms-input-placeholder {
    color: 999999
}

.chat-box .people-list .search .form-control::placeholder {
    color: 999999
}

.chat-box .people-list .search i {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: #e8ebf2
}

.chat-box .people-list ul {
    padding: 0
}

.chat-box .people-list ul li {
    padding-bottom: 20px
}

.chat-box .people-list ul li:last-child {
    padding-bottom: 0
}

.chat-box .user-image {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 5px
}

.chat-box .about {
    float: left;
    margin-top: 5px;
    padding-left: 10px
}

.chat-box .about .name {
    color: #2a3142;
    letter-spacing: 1px;
    font-weight: 600
}

.chat-box .status {
    color: 999999;
    letter-spacing: 1px;
    font-size: 12px;
    margin-top: 5px
}

.chat-box .status .chat-status {
    font-weight: 600;
    color: #313131
}

.chat-box .status p {
    font-size: 14px
}

.chat-box .chat-right-aside .chat .chat-header {
    padding: 15px;
    border-bottom: 1px solid #f8f8f9
}

.chat-box .chat-right-aside .chat .chat-header img {
    float: left;
    width: 50px;
    height: 50px;
    -webkit-box-shadow: 1px 1px 4px 1px #e8ebf2;
    box-shadow: 1px 1px 4px 1px #e8ebf2
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons {
    margin-top: 15px
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li {
    margin-right: 24px
}

.chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    color: #777;
    font-size: 25px;
    cursor: pointer
}

.chat-box .chat-right-aside .chat .chat-msg-box {
    padding: 20px;
    overflow-y: auto;
    height: 560px;
    margin-bottom: 90px
}

.chat-box .chat-right-aside .chat .chat-msg-box .chat-user-img {
    margin-top: -35px
}

.chat-box .chat-right-aside .chat .chat-msg-box .message-data {
    margin-bottom: 10px
}

.chat-box .chat-right-aside .chat .chat-msg-box .message-data-time {
    letter-spacing: 1px;
    font-size: 12px;
    color: 999999;
    font-family: work-Sans, sans-serif
}

.chat-box .chat-right-aside .chat .chat-msg-box .message {
    color: #2a3142;
    padding: 20px;
    line-height: 1.9;
    letter-spacing: 1px;
    font-size: 14px;
    margin-bottom: 30px;
    width: 50%;
    position: relative
}

.chat-box .chat-right-aside .chat .chat-msg-box .my-message {
    border: 1px solid #f8f8f9;
    border-radius: 10px;
    border-top-left-radius: 0
}

.chat-box .chat-right-aside .chat .chat-msg-box .other-message {
    background-color: #f6f6f6;
    border-radius: 10px;
    border-top-right-radius: 0
}

.chat-box .chat-right-aside .chat .chat-message {
    padding: 20px;
    border-top: 1px solid #f1f4fb;
    position: absolute;
    width: calc(100% - 15px);
    background-color: #fff;
    bottom: 0
}

.chat-box .chat-right-aside .chat .chat-message .smiley-box {
    background: #eff0f1;
    padding: 10px;
    display: block;
    border-radius: 4px;
    margin-right: 0.5rem
}

.chat-box .chat-right-aside .chat .chat-message .text-box {
    position: relative
}

.chat-box .chat-right-aside .chat .chat-message .text-box .input-txt-bx {
    height: 50px;
    border: 2px solid #ff4c3b;
    padding-left: 18px;
    font-size: 12px;
    letter-spacing: 1px
}

.chat-box .chat-right-aside .chat .chat-message .text-box i {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    color: #e8ebf2;
    cursor: pointer
}

.chat-box .chat-right-aside .chat .chat-message .text-box .btn {
    font-size: 16px;
    font-weight: 500
}

.chat-box .chat-menu {
    border-left: 1px solid #f8f8f9
}

.chat-box .chat-menu .tab-pane {
    padding: 0 15px
}

.chat-box .chat-menu ul li .about .status i {
    font-size: 10px
}

.chat-box .chat-menu .user-profile {
    margin-top: 30px
}

.chat-box .chat-menu .user-profile .user-content h5 {
    margin: 25px 0
}

.chat-box .chat-menu .user-profile .user-content hr {
    margin: 25px 0
}

.chat-box .chat-menu .user-profile .user-content p {
    font-size: 16px
}

.chat-box .chat-menu .user-profile .image {
    position: relative
}

.chat-box .chat-menu .user-profile .image .icon-wrapper {
    position: absolute;
    bottom: 0;
    left: 55%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
    margin: 0 auto;
    font-size: 14px;
    -webkit-box-shadow: 1px 1px 3px 1px #f8f8f9;
    box-shadow: 1px 1px 3px 1px #f8f8f9
}

.chat-box .chat-menu .user-profile .image .avatar img {
    border-radius: 50%;
    border: 5px solid #f8f8f9
}

.chat-box .chat-menu .user-profile .border-right {
    border-right: 1px solid #f8f8f9
}

.chat-box .chat-menu .user-profile .follow {
    margin-top: 0
}

.chat-box .chat-menu .user-profile .follow .follow-num {
    font-size: 22px;
    color: #000
}

.chat-box .chat-menu .user-profile .follow span {
    color: #1b252a;
    font-size: 14px;
    letter-spacing: 1px
}

.chat-box .chat-menu .user-profile .social-media a {
    color: 999999;
    font-size: 15px;
    padding: 0 7px
}

.chat-box .chat-menu .user-profile .chat-profile-contact p {
    font-size: 14px;
    color: 999999
}

.chat-box .chat-menu .nav {
    margin-bottom: 20px
}

.chat-box .chat-menu .nav-tabs .nav-item {
    width: 33.33%
}

.chat-box .chat-menu .nav-tabs .nav-item a {
    padding: 15px !important;
    color: 999999 !important;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.chat-box .chat-menu .nav-tabs .nav-item .material-border {
    border-width: 1px;
    border-color: #ff4c3b
}

.chat-box .chat-menu .nav-tabs .nav-item .nav-link.active {
    color: #000 !important
}

.chat-box .chat-history .call-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-width: 300px
}

.chat-box .chat-history .total-time h2 {
    font-size: 50px;
    color: #eff0f1;
    font-weight: 600;
    margin-bottom: 30px
}

.chat-box .chat-history .receiver-img {
    margin-top: 55px
}

.chat-box .chat-history .receiver-img img {
    border-radius: 5px
}

.chat-box .chat-history .call-icons {
    margin-bottom: 35px
}

.chat-box .chat-history .call-icons ul li {
    width: 60px;
    height: 60px;
    border: 1px solid #f8f8f9;
    border-radius: 50%;
    padding: 12px
}

.chat-box .chat-history .call-icons ul li+li {
    margin-left: 10px
}

.chat-box .chat-history .call-icons ul li a {
    color: #999;
    font-size: 25px
}

.status-circle {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 40px;
    left: 40px;
    border-radius: 50%;
    border: 2px solid #fff
}

.away {
    background-color: #ffbc58
}

.online {
    background-color: #81ba00
}

.offline {
    background-color: #a5a5a5
}

.chat-container .aside-chat-left {
    width: 320px
}

.chat-container .chat-right-aside {
    width: 320px
}

.call-chat-sidebar {
    max-width: 320px
}

.call-chat-sidebar .card .card-body,
.chat-body .card .card-body {
    padding: 15px
}

.market-chart {
    height: 303px;
    min-width: 150px;
    max-width: 100%
}

.radial-bar {
    position: relative;
    display: inline-block;
    border-radius: 50%;
    background-color: transparent;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 70px;
    height: 70px;
    font-size: 18px;
    font-family: work-Sans, sans-serif;
    background-clip: content-box
}

.radial-bar:after {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    content: attr(data-label);
    background-color: #fff;
    z-index: 1;
    width: 60px;
    height: 60px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 56px
}

.radial-bar>img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    text-align: center;
    font-weight: 500;
    color: #455a64;
    z-index: 3;
    width: 60px;
    height: 60px;
    margin-left: 5px;
    margin-top: 5px;
    line-height: 56px
}

.radial-bar.radial-bar-0 {
    /* background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #eff0f1), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #448aff), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(90deg, #448aff 50%, #eff0f1 50%, #eff0f1) */
}

.radial-bar.radial-bar-5 {
    /* background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(108deg, #448aff 50%, #eff0f1 50%, #eff0f1) */
}

.radial-bar.radial-bar-10 {
    /* background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(126deg, #448aff 50%, #eff0f1 50%, #eff0f1) */
}

.radial-bar.radial-bar-15 {
    /* background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(144deg, #448aff 50%, #eff0f1 50%, #eff0f1) */
}

.radial-bar.radial-bar-20 {
    /* background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(162deg, #448aff 50%, #eff0f1 50%, #eff0f1) */
}

.radial-bar.radial-bar-25 {
    /* background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #eff0f1), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #448aff), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(180deg, #448aff 50%, #eff0f1 50%, #eff0f1) */
}

/* .radial-bar.radial-bar-30 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(198deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-35 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(216deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-40 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(234deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-45 {
    background-image: linear-gradient(90deg, #eff0f1 50%, transparent 50%, transparent), linear-gradient(252deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-50 {
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #448aff), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #448aff), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(270deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-55 {
    background-image: linear-gradient(288deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-60 {
    background-image: linear-gradient(306deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-65 {
    background-image: linear-gradient(324deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-70 {
    background-image: linear-gradient(342deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-75 {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #448aff), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #448aff), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(360deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-80 {
    background-image: linear-gradient(378deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-85 {
    background-image: linear-gradient(396deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-90 {
    background-image: linear-gradient(414deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-95 {
    background-image: linear-gradient(432deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar.radial-bar-100 {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #448aff), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #448aff), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(450deg, #448aff 50%, transparent 50%, transparent), linear-gradient(270deg, #448aff 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-0 {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #ff4c3b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, #ff4c3b), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(90deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-5 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(108deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-10 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(126deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-15 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(144deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-20 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(162deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-25 {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #ff4c3b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ff4c3b), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(180deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-30 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(198deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-35 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(216deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-40 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(234deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-45 {
    background-image: linear-gradient(90deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(252deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-50 {
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, #ff4c3b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #ff4c3b), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(270deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-55 {
    background-image: linear-gradient(288deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-60 {
    background-image: linear-gradient(306deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-65 {
    background-image: linear-gradient(324deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-70 {
    background-image: linear-gradient(342deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-75 {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(50%, #ff4c3b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #ff4c3b), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(360deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-80 {
    background-image: linear-gradient(378deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-85 {
    background-image: linear-gradient(396deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-90 {
    background-image: linear-gradient(414deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-95 {
    background-image: linear-gradient(432deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
}

.radial-bar-primary.radial-bar-100 {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #ff4c3b), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, right top, left top, color-stop(50%, #ff4c3b), color-stop(50%, #eff0f1), to(#eff0f1));
    background-image: linear-gradient(450deg, #ff4c3b 50%, transparent 50%, transparent), linear-gradient(270deg, #ff4c3b 50%, #eff0f1 50%, #eff0f1)
} */

.market-chart .ct-series-a .ct-point {
    stroke: #ff4c3b
}

.market-chart .ct-series-a .ct-line {
    stroke: #ff4c3b
}

.market-chart .ct-series-a .ct-bar {
    stroke: #ff4c3b
}

.market-chart .ct-series-a .ct-slice-donut {
    stroke: #ff4c3b
}

.market-chart .ct-series-b .ct-point {
    stroke: #a5a5a5
}

.market-chart .ct-series-b .ct-line {
    stroke: #a5a5a5
}

.market-chart .ct-series-b .ct-bar {
    stroke: #a5a5a5
}

.market-chart .ct-series-b .ct-slice-donut {
    stroke: #a5a5a5
}

.needs-validation .custom-select {
    background: #fff
}

.needs-validation .form-control {
    border-radius: 0.25rem;
    margin: 5px 0px;
    padding: 0.48rem 0.75rem
}

.license-key[disabled] {
    cursor: not-allowed
}

.checkbox label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px
}

.checkbox .form-check-input {
    margin-top: 13px;
    margin-bottom: 10px
}

.form-row .form-control {
    font-size: 14px;
    letter-spacing: 1px
}

.form-row .custom-select {
    font-size: 14px;
    letter-spacing: 1px
}

.bulk-pruduct .card .card-body p {
    font-weight: bold
}

.datepicker {
    -webkit-box-shadow: 0 4px 14px rgba(255, 76, 59, 0.15);
    box-shadow: 0 4px 14px rgba(255, 76, 59, 0.15)
}

.datepicker--day-name {
    color: #000;
    font-weight: bold
}

.datepicker--cell.-current- {
    color: #000;
    border-radius: 5px;
    font-weight: bold;
    border: 2px solid #ff4c3b
}

.datepicker--cell.-focus- {
    background: #ff4c3b;
    color: #fff
}

.datepicker--cell.-selected- {
    background: #ff4c3b
}

.daterangepicker.ltr {
    color: #898989;
    z-index: 8
}

.daterangepicker.ltr tr {
    font-size: 13px
}

.daterangepicker.ltr tr td {
    height: 34px;
    width: 34px
}

.form-check-inline {
    display: inline-block;
    padding: 0 !important;
    margin: 5px 1rem !important;
}

.form-check-label {
    margin: 2px 10px !important
}

.form-check-input{
    margin: 5px 2px !important;
}

.daterangepicker.ltr tr td.in-range {
    background-color: #fafafa
}

.daterangepicker.ltr tr td.active {
    background-color: #ff4c3b
}

.daterangepicker.ltr .ranges {
    float: none
}

.daterangepicker.ltr .ranges li {
    color: #ff4c3b
}

.daterangepicker.ltr .ranges li:hover {
    background-color: #ff4c3b;
    border: 1px solid #ff4c3b;
    color: #fff
}

.daterangepicker.ltr .ranges li.active {
    background-color: #ff4c3b;
    border: 1px solid #ff4c3b;
    color: #fff
}

.daterangepicker.ltr .calendar-time select {
    color: #898989
}

.datetime-picker .bootstrap-datetimepicker-widget.dropdown-menu {
    width: auto
}

.date-range-picker .theme-form {
    margin-bottom: 30px
}

.date-range-picker>div:last-child .theme-form {
    margin-bottom: 0px
}

.reset-pass .forgot-title {
    font-size: 32px;
    margin-bottom: 30px;
    color: #232323 !important
}

.reset-pass .reset-input {
    margin-bottom: 5px !important
}

.reset-pass .reset-input label {
    color: #777
}

.authentication-box {
    min-width: 100vw;
    min-height: 100vh;
    width: auto;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.authentication-box .custom-checkbox .form-check-input:checked~.form-check-label::before {
    background-color: #ff4c3b
}

.authentication-box .back-btn {
    float: right;
    margin: 0;
    font-size: 14px;
    position: relative;
    padding-left: 60px !important
}

.authentication-box .back-btn svg {
    position: absolute;
    left: 30px;
    height: 18px
}

.authentication-box .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.authentication-box .tab2-card .nav-tabs .nav-link {
    font-size: 18px
}

.authentication-box .btn-primary {
    border-radius: 25px;
    margin-top: 20px;
    font-weight: 400;
    padding: 11px 45px
}

.authentication-box .form-footer {
    position: relative;
    padding-top: 25px;
    border-top: 1px solid #f3f3f3;
    margin-top: 25px
}

.authentication-box .form-footer>span {
    position: absolute;
    top: -11px;
    padding-right: 20px;
    background-color: #fff;
    color: #909090
}

.authentication-box .form-footer .social {
    margin-bottom: 0;
    border-bottom: 0
}

.authentication-box .form-footer .social li {
    padding: 10px;
    border-radius: 100%;
    border: 1px solid var(--theme-color);
    height: 40px;
    width: 40px;
    margin-right: 10px;
    text-align: center;
    display: inline-block
}

.authentication-box .slick-dots li.slick-active button:before {
    color: #fff
}

.authentication-box .slick-dots li button:before {
    font-size: 12px;
    color: #fff
}

.authentication-box .container {
    max-width: 100%
}

.authentication-box .container .bg-primary {
    padding: 50px;
    background-image: url(../assets//login-bg.png);
    background-position: center;
    -webkit-box-shadow: 1px 5px 24px 0 #edf4dd;
    background-color: var(--theme-color) !important;
  color: #fff;
}

.authentication-box .container .form-group {
    margin-bottom: 24px
}

.authentication-box .container .svg-icon {
    padding: 24px;
    margin: 0 auto;
    border: 2px dashed #fff;
    border-radius: 100%;
    height: 130px;
    width: 130px;
    margin-bottom: 40px
}

.authentication-box .container .svg-icon svg {
    height: 80px
}

.authentication-box .container p {
    color: rgba(255, 255, 255, 0.9);
    font-size: 15px;
    line-height: 2;
    text-align: center
}

.authentication-box .container h3 {
    color: #fff;
    font-weight: 600;
    text-align: center
}

.auth-form .form-control {
    border-radius: 5px;
    padding: 9px 15px;
    border: 1px solid #eaeaea
}

.card-left {
    z-index: 1
}

.card-right {
    margin-left: -15px
}

.card-right .card {
    padding-left: 15px
}

.forgot-pass {
    padding: 0;
    margin-left: auto;
    color: var(--theme-color)
}

.custom-theme {
    position: fixed;
    right: 0;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: block !important
}

.custom-theme li {
    width: 40px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f7f7f7;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    margin-bottom: 15px;
    font-weight: 600
}

.custom-theme li.demo-li {
    width: 50px;
    height: 40px;
    padding: 4px;
    line-height: 16px
}

.custom-theme li.demo-li a {
    color: #222
}

.custom-theme li:last-child {
    margin-bottom: 0
}

.pagination-box {
    margin-top: 30px
}

.pagination-box .pagination-primary {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.pagination-box .pagination-primary .page-item.active {
    background-color: var(--theme-color);
    color: #fff
}

.pagination-box .pagination-primary .page-item.active .page-link {
    color: #fff;
    background-color: var(--theme-color);
    border-color: var(--theme-color)
}

.pagination-box .pagination-primary .page-item .page-link {
    color: var(--theme-color)
}

.order-left-image {
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.order-left-image .tracking-product-image {
    margin-right: 40px;
    width: 24%
}

[dir="rtl"] .order-left-image .tracking-product-image {
    margin-right: 0;
    margin-left: 40px
}

.order-left-image .order-image-contain {
    width: calc(100% - 30px)
}

.order-left-image .order-image-contain p {
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.05em
}

.order-left-image .order-image-contain p span {
    font-weight: 700;
    margin-left: 10px;
    color: #232323
}

.order-left-image .order-image-contain h4 {
    font-size: 22px;
    letter-spacing: 0.03em;
    margin-bottom: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700
}

.order-left-image .order-image-contain h5 {
    font-size: 18px;
    letter-spacing: 0.03em;
    margin-top: calc(12px + (23 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600
}

.progtrckr {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.progtrckr li {
    display: inline-block;
    text-align: center;
    margin: 10px 0
}

.progtrckr li h5 {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    font-size: calc(15px + (16 - 15) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.2;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden
}

.progtrckr li:before {
    position: relative;
    top: -2px;
    float: left;
    left: 50%;
    line-height: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.progtrckr li.progtrckr-todo {
    color: silver;
    border-top: 4px solid silver;
    width: 100%
}

.progtrckr li.progtrckr-todo:before {
    content: "\f111";
    font-family: FontAwesome;
    color: silver;
    background-color: #fff;
    font-size: 2.2em
}

.progtrckr li.progtrckr-todo h6 {
    font-size: 13px;
    margin-top: 8px
}

.progtrckr li.progtrckr-done {
    color: #232323;
    border-top: 4px solid var(--theme-color);
    width: 100% !important
}

.progtrckr li.progtrckr-done:before {
    content: "\f00c";
    font-family: FontAwesome;
    color: #fff;
    background-color: var(--theme-color);
    height: 30px;
    width: 30px;
    line-height: 2.2em;
    border: none;
    border-radius: 2.2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.progtrckr li.progtrckr-done h6 {
    font-size: 13px;
    margin-top: 8px;
    color: #7e7e7e
}

.select2-results__option {
    display: block
}

.sales-contain {
    margin-top: 16px
}

.wrapper .label {
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.3;
    margin-bottom: 16px
}

.wrapper .searchBar {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.wrapper #searchQueryInput {
    width: 100%;
    height: 45px;
    background-color: #f1f4fb;
    border: 1px solid #f5f5f5 !important;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0 56px 0 24px;
    font-size: 16px
}

.wrapper #searchQuerySubmit {
    width: 56px;
    height: 45px;
    margin-left: -56px;
    background: none;
    border: none;
    outline: none
}

.wrapper #searchQuerySubmit:hover {
    cursor: pointer
}

@media only screen and (max-width: 1660px) {
    .peity-chart-dashboard .peity {
        height: 148px
    }

    .products-table table tr td:first-child {
        min-width: 268px
    }
}

@media (min-width: 1630px) {
    .container-lg {
        max-width: 1610px
    }
}

@media (min-width: 1430px) {
    .container {
        max-width: 1400px
    }

    .left-sidebar_space {
        padding-left: 0
    }

    .product-right .product-icon .product-social li {
        padding-right: 10px
    }
}

@media screen and (max-width: 1440px) and (min-width: 1366px) {
    .peity-chart-dashboard .peity {
        height: 120px
    }

    .flot-chart-container {
        height: 350px
    }

    .product-adding .col-xl-5 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 36%;
        flex: 0 0 36%;
        max-width: 36%
    }

    .product-adding .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 64%;
        flex: 0 0 64%;
        max-width: 64%
    }
}

@media (max-width: 1367px) {
    h2 {
        font-size: 32px
    }

    .product-box .product-detail .rating i,
    .product-box .product-info .rating i {
        padding-right: 0
    }
}

@media only screen and (max-width: 1366px) {
    .page-main-header .main-header-right .nav-right>ul>li:first-child {
        width: 32%
    }

    .peity-chart-dashboard .peity {
        height: 180px
    }

    .xl-space {
        margin-top: 30px
    }

    .chart-vertical-center #myDoughnutGraph,
    .chart-vertical-center #myPolarGraph {
        height: 180px !important
    }

    .product-adding .add-product-form {
        margin-top: 30px
    }

    .user-list table tr td:nth-child(5),
    .user-list table tr th:nth-child(5) {
        width: 195px !important
    }

    .translation-list table tr td,
    .translation-list table tr th {
        width: 162px !important
    }

    .translation-list table tr td:first-child,
    .translation-list table tr td:nth-child(2),
    .translation-list table tr th:first-child,
    .translation-list table tr th:nth-child(2) {
        width: 274px !important
    }

    .order-datatable table {
        display: block
    }

    .order-datatable table tr th,
    .order-datatable table tr td {
        min-width: 121px
    }

    .order-datatable table tr th:nth-child(2),
    .order-datatable table tr td:nth-child(2) {
        min-width: 150px
    }

    .vendor-table table {
        display: block
    }

    .vendor-table table tr th,
    .vendor-table table tr td {
        min-width: 112px
    }

    .vendor-table table tr th:first-child,
    .vendor-table table tr td:first-child {
        min-width: 150px
    }

    .media-table table tr th:nth-child(3),
    .media-table table tr td:nth-child(3) {
        width: 146px !important
    }

    .media-table table tr th:last-child,
    .media-table table tr td:last-child {
        width: 248px !important
    }
}

@media screen and (max-device-width: 1366px) and (min-device-width: 1200px) {
    .xl-100 {
        max-width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%
    }

    .xl-50 {
        max-width: 50%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%
    }

    .product-adding .col-xl-5,
    .product-adding .col-xl-7 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .product-adding .add-product-form {
        margin-top: 30px
    }

    .category-table table tr td:first-child,
    .category-table table tr th:first-child {
        width: 90px !important
    }

    .order-table table tr td:nth-child(2),
    .order-table table tr th:nth-child(2) {
        width: 70px !important
    }

    .user-list table tr td:first-child,
    .user-list table tr th:first-child {
        width: 130px !important
    }

    .profile-table table tr th,
    .profile-table table tr td {
        width: 200px !important
    }
}

@media (max-width: 1199px) {
    .container-fluid.custom-container {
        padding-left: 50px;
        padding-right: 50px
    }

    section,
    .section-t-space {
        padding-top: 60px
    }

    .section-b-space {
        padding-bottom: 60px
    }

    .space_sm {
        padding-left: 0
    }

    .background .contain-bg {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .instagram .insta-decor {
        display: none
    }

    .product-right h2 {
        font-size: 20px
    }

    .product-right .product-buttons .btn-solid,
    .product-right .product-buttons .btn-outline {
        padding: 7px 15px
    }

    .product-right .timer {
        padding-left: 30px
    }

    .product-page-details {
        margin-top: 30px
    }

    .product-physical table tr th,
    .product-physical table tr td {
        width: 70px !important
    }

    .product-physical table tr th:nth-child(2),
    .product-physical table tr td:nth-child(2) {
        width: 173px !important
    }

    .product-physical table tr th:nth-child(5),
    .product-physical table tr td:nth-child(5) {
        width: 100px !important
    }

    .category-table table tr td,
    .category-table table tr th {
        width: 120px !important
    }

    .category-table table tr td:first-child,
    .category-table table tr th:first-child {
        width: 130px !important
    }

    .category-table table tr td:nth-child(3),
    .category-table table tr th:nth-child(3) {
        width: 225px !important
    }

    .category-table table tr td:last-child,
    .category-table table tr th:last-child {
        width: 150px !important
    }

    .media-table table tr td:last-child,
    .media-table table tr th:last-child {
        width: 370px !important
    }

    .order-table table tr td:nth-child(4),
    .order-table table tr th:nth-child(4) {
        width: 230px !important
    }

    .user-list table tr th,
    .user-list table tr td {
        width: 200px !important
    }

    .transactions table tr td:nth-child(4),
    .transactions table tr th:nth-child(4) {
        width: 100px !important
    }

    .transactions table tr td:first-child,
    .transactions table tr th:first-child {
        width: 100px !important
    }

    .transactions table tr td:nth-child(2),
    .transactions table tr th:nth-child(2) {
        width: 150px !important
    }
}

@media (max-width: 991px) {

    section,
    .section-t-space {
        padding-top: 50px
    }

    .section-b-space {
        padding-bottom: 50px
    }

    h2 {
        font-size: 28px
    }

    h4 {
        font-size: 16px
    }

    .small-section {
        padding: 30px 0
    }

    .background .contain-bg {
        padding-top: 25px;
        padding-bottom: 25px
    }

    .background .contain-bg h4 {
        font-size: 16px
    }

    .bundle .bundle_img {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .product-right {
        text-align: center;
        margin: 20px 0 10px 0
    }

    .product-right h2 {
        margin-top: 15px
    }

    .product-right .rating-section {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .product-right .detail-section,
    .product-right .product-icon {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .product-right .product-description .qty-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .product-right .size-text {
        text-align: left
    }

    .product-right .timer {
        text-align: left
    }

    .product-right .product-icon .product-social li {
        padding-right: 20px
    }

    .product-box .cart-info {
        bottom: 20px
    }

    .alert {
        max-width: 45% !important
    }

    .authentication-box {
        padding: 30px
    }

    .authentication-box .container .bg-primary {
        padding: 40px
    }

    .btn-popup {
        margin-bottom: 20px
    }

    .order-graph .order-graph-bottom {
        margin-top: 20px
    }

    .order-graph .order-graph-bottom.sales-location {
        margin-top: 15px
    }

    .xl-space {
        margin-top: 20px
    }

    .card .card-body {
        padding: 120px 70px
    }
    .card .card-header {
        padding: 20px
    }

    .card .card-body .card-header-right,
    .card .card-header .card-header-right {
        right: 11px;
        top: 15px
    }

    .page-wrapper .page-main-header.open {
        z-index: 11
    }

    .right-sidebar {
        top: 133px
    }

    .offcanvas .page-wrapper .page-body-wrapper .page-body:before {
        left: 0
    }

    .page-wrapper .page-body-wrapper .page-header .row h3 {
        font-size: 22px
    }

    .page-wrapper .page-body-wrapper .page-header .row .pull-right {
        float: none !important;
        margin-top: 25px
    }

    .page-wrapper .page-body-wrapper .page-sidebar {
        top: 0 !important;
        height: 100% !important;
        background: #2f2f2f;
        z-index: 11
    }

    .page-wrapper .page-body-wrapper .page-sidebar~.page-body {
        margin-left: 0 !important;
        margin-top: 80px
    }

    .page-wrapper .page-body-wrapper .page-sidebar~.page-body .page-header .row .page-header-left {
        display: inherit
    }

    .page-wrapper .page-body-wrapper .sidebar {
        height: 100%
    }

    .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body {
        margin-top: 80px
    }

    .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body .activity .media .gradient-round.gradient-line-1:after {
        bottom: -45px
    }

    .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body .activity .media .gradient-round.small-line:after {
        bottom: -28px
    }

    .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body .activity .media .gradient-round.medium-line:after {
        bottom: -41px
    }

    .page-wrapper .page-body-wrapper footer {
        margin-left: 0
    }

    .page-wrapper .page-main-header {
        height: 60px !important;
        margin-left: 0 !important;
        width: 100% !important;
        z-index: 11 !important
    }
/* 
    .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
        height: 23px;
        margin-top: 0;
        margin-right: 15px
    } */

    .page-wrapper .page-main-header .main-header-right .nav-right {
        position: unset;
        padding: 0
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul {
        top: 60px;
        position: absolute;
        z-index: -1;
        background-color: #fff;
        -webkit-transition: all linear 0.3s;
        transition: all linear 0.3s;
        -webkit-box-shadow: 0 2px 2px 2px #efefef;
        box-shadow: 0 2px 2px 2px #efefef;
        width: 100%;
        left: 0;
        padding: 0 40px;
        -webkit-transform: translateY(-35px) scaleY(0);
        transform: translateY(-35px) scaleY(0);
        opacity: 0;
        visibility: hidden
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul>li {
        margin: 10px 0
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>.mobile-toggle {
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>.mobile-toggle svg circle {
        color: #ff4c3b
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul.open {
        z-index: 1;
        opacity: 1;
        -webkit-transform: translateY(0px) scaleY(1);
        transform: translateY(0px) scaleY(1);
        visibility: visible
    }

    .page-header {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .page-header .row .page-header-left .breadcrumb {
        margin-top: 5px;
        margin-left: 0
    }

    .product-page-details {
        text-align: left !important
    }

    .product-page-main .owl-stage-outer {
        margin-top: 20px
    }

    .dataTables_wrapper .dataTables_length {
        margin-bottom: 20px
    }

    .add-product img {
        width: 460px
    }

    .zoomContainer {
        top: 209px !important
    }

    .product-adding .add-product-form {
        margin-top: 20px
    }

    .tab-coupon {
        margin-bottom: 20px
    }

    .deactivate-account {
        margin-top: 20px
    }

    .sell-graph canvas {
        height: 312px !important
    }

    .dark .page-wrapper .page-main-header .main-header-right .nav-right>ul {
        background-color: #2b2b2b;
        -webkit-box-shadow: none;
        box-shadow: none
    }

    .order-left-image {
        display: block !important
    }

    .order-left-image .tracking-product-image {
        margin-bottom: 18px;
        width: 50% !important;
        margin-left: auto;
        margin-right: auto !important
    }

    .order-left-image .order-image-contain {
        width: 100%
    }
}

@media only screen and (max-height: 800px) {
    .right-sidebar .chat-box .friend-list {
        max-height: calc(100vh - 150px);
        overflow: scroll
    }
}

@media (max-width: 767px) {
    .container-fluid.custom-container {
        padding-left: 30px;
        padding-right: 30px
    }

    section,
    .section-t-space {
        padding-top: 40px
    }

    .section-b-space {
        padding-bottom: 40px
    }

    .small-section {
        padding: 20px 0
    }

    .space_sm {
        padding-top: 0 !important
    }

    .background .col {
        -ms-flex-preferred-size: unset;
        flex-basis: unset
    }

    .background .contain-bg {
        margin: 10px 0
    }

    .team h2 {
        font-size: 25px
    }

    .product-box .img-wrapper .cart-box.style-1 {
        padding: 7px 4px;
        bottom: 7px
    }

    .product-box .img-wrapper .cart-box.style-1 i {
        padding-left: 4px;
        padding-right: 4px
    }

    .product-box .cart-detail {
        top: 5px;
        right: 10px
    }

    .product-box:hover .product-info .add-btn {
        -webkit-animation: none;
        animation: none
    }

    .product-box .img-wrapper .cart-box {
        padding: 8px 10px;
        bottom: 20px
    }

    .product-box .img-wrapper .cart-box i {
        font-size: 15px;
        padding-left: 4px;
        padding-right: 4px
    }

    .product-box .cart-info,
    .product-box .cart-detail {
        opacity: 1
    }

    .product-box .img-wrapper .cart-box {
        opacity: 1
    }

    .product-box:hover .img-wrapper .cart-box {
        -webkit-animation: none;
        animation: none
    }

    .product-box:hover .cart-info button,
    .product-box:hover .cart-detail button {
        -webkit-animation: none;
        animation: none
    }

    .product-box:hover .cart-info a i,
    .product-box:hover .cart-detail a i {
        -webkit-animation: none;
        animation: none
    }

    .product-box:hover .cart-info a:nth-child(2) i,
    .product-box:hover .cart-detail a:nth-child(2) i {
        -webkit-animation: none;
        animation: none
    }

    .product-box:hover .cart-info a:nth-child(3) i,
    .product-box:hover .cart-detail a:nth-child(3) i {
        -webkit-animation: none;
        animation: none
    }

    .product-box:hover .cart-info a:nth-child(4) i,
    .product-box:hover .cart-detail a:nth-child(4) i {
        -webkit-animation: none;
        animation: none
    }

    .product-box .product-info .add-btn {
        opacity: 1
    }

    .order-left-image .tracking-product-image {
        margin: 0 auto;
        margin-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)))
    }

    .order-left-image .order-image-contain h4 {
        font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)))
    }

    .page-wrapper .page-body-wrapper footer .pull-right {
        text-align: center !important
    }
}

@media (max-width: 577px) {
    .container-fluid.custom-container {
        padding-left: 15px;
        padding-right: 15px
    }

    section,
    .section-t-space {
        padding-top: 30px
    }

    h2 {
        font-size: 24px
    }

    .section-b-space {
        padding-bottom: 30px
    }

    .background {
        padding: 10px 10px
    }

    .background .contain-bg {
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 10px 0
    }

    .background .contain-bg:hover {
        background-color: unset
    }

    .bundle .bundle_img .img-box img {
        max-width: 70px
    }

    .alert {
        right: 0 !important;
        left: 0;
        max-width: 90% !important
    }

    .btn-solid,
    .btn-outline {
        padding: 7px 15px
    }

    footer {
        margin-bottom: 0 !important
    }

    .search-box {
        width: 55% !important
    }
}

@media (min-width: 576px) {
    .form-inline .form-group {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0
    }

    .card-right {
        margin-left: 0
    }

    .authentication-box .form-footer,
    .authentication-box .form-button {
        text-align: center
    }

    .authentication-box .form-footer>span,
    .authentication-box .form-button>span {
        padding: 0;
        left: 0;
        right: 0
    }

    .page-main-header .main-header-right .nav-right>ul>li:first-child {
        width: 40%
    }

    .products-table table tr td:first-child,
    .products-table table tr th:first-child {
        min-width: 280px
    }

    .products-table table tr td:nth-child(3),
    .products-table table tr th:nth-child(3) {
        min-width: 110px
    }

    .page-wrapper .page-body-wrapper .page-header .row .col {
        -ms-flex-preferred-size: unset;
        flex-basis: unset
    }

    .page-wrapper .page-body-wrapper .page-header .row .col .pull-right {
        float: none;
        margin-top: 20px
    }

    .page-wrapper .page-body-wrapper footer .row div {
        text-align: center
    }

    .page-wrapper .page-body-wrapper footer .row div .pull-right {
        float: none
    }

    .footer-fix .pull-right {
        float: none !important;
        text-align: center !important
    }

    .product-physical table tr th,
    .product-physical table tr td {
        width: 90px !important
    }

    .product-physical table tr th:nth-child(2),
    .product-physical table tr td:nth-child(2) {
        width: 196px !important
    }

    .add-product-form .radio-animated label {
        margin-bottom: 0
    }

    .add-product-form .editor-space {
        padding: 0 15px
    }

    .add-product-form textarea {
        margin: 0 15px
    }

    .add-product img {
        width: 400px
    }

    .digital-add textarea {
        margin: 0 !important
    }

    .digital-add .form-group label {
        margin-bottom: 0 !important
    }

    .digital-product table tr th,
    .digital-product table tr td {
        width: 120px !important
    }

    .report-table table tr th,
    .report-table table tr td {
        width: 150px !important
    }

    .needs-validation .form-group.row {
        margin-left: 0;
        margin-right: 0
    }

    .needs-validation .form-group label {
        margin-bottom: 4px
    }

    .needs-validation .radio-animated label {
        margin-bottom: 0
    }

    .needs-validation .editor-space {
        padding: 0 15px
    }

    .needs-validation textarea {
        margin: 0 15px
    }

    .dataTables_wrapper .dataTables_paginate {
        float: none !important;
        margin-top: 20px !important
    }

    .dataTables_wrapper .dataTables_length label,
    .dataTables_wrapper .dataTables_filter label {
        float: none !important
    }

    .dataTables_wrapper table.dataTable {
        margin-top: 20px !important
    }
}

@media only screen and (max-width: 575px) {
    .authentication-box .btn-primary {
        margin-top: 10px
    }

    .btn-popup {
        margin-bottom: 15px
    }

    .static-top-widget div.align-self-center svg {
        width: 20px;
        height: 20px
    }

    .progtrckr {
        display: block !important;
        margin: 30px 0 !important
    }

    .progtrckr li {
        margin: 0 !important;
        width: 50% !important
    }

    .progtrckr li h5 {
        display: block !important;
        text-align: left !important;
        margin-top: 0 !important
    }

    .progtrckr li::before {
        position: relative !important;
        top: 10px !important;
        float: left !important;
        left: -2px !important;
        line-height: 1 !important;
        -webkit-transform: translate(-50%, -50%) !important;
        transform: translate(-50%, -50%) !important
    }

    .progtrckr li.progtrckr-todo {
        position: relative !important;
        text-align: left !important;
        margin-left: 30px !important;
        -webkit-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
        padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))) !important;
        padding-left: 30px !important;
        border-top: unset !important;
        border-left: 4px solid silver !important
    }

    .progtrckr li::before {
        position: absolute !important;
        top: 10px !important;
        left: -17px !important;
        line-height: 1 !important;
        -webkit-transform: translateY(-50%) !important;
        transform: translateY(-50%) !important
    }

    .progtrckr li.progtrckr-done {
        position: relative !important;
        text-align: left !important;
        margin-left: 30px !important;
        -webkit-box-align: self-start !important;
        -ms-flex-align: self-start !important;
        align-items: self-start !important;
        padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320))) !important;
        border-top: unset !important;
        border-left: 4px solid var(--theme-color) !important
    }

    .progtrckr li.progtrckr-done h5 {
        font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
        padding-left: 25px
    }

    .progtrckr li.progtrckr-done h6 {
        font-size: calc(13px + (14 - 13) * ((100vw - 320px) / (1920 - 320)));
        padding-left: 25px
    }

    .latest-order-table table tr td {
        min-width: 100px
    }

    .latest-order-table table tr td+td {
        min-width: 150px
    }

    .latest-order-table table tr td:nth-child(3) {
        min-width: 200px
    }

    .card {
        margin-bottom: 15px
    }

    .card .card-header {
        padding: 15px;
        display: block
    }

    .card .card-header h5 {
        font-size: 17px
    }

    .card .card-header .card-header-right {
        right: 6px;
        top: 10px
    }

    .card .card-body {
        padding: 15px !important
    }

    .sm-order-space,
    .xl-space {
        margin-top: 15px
    }

    .order-graph .order-graph-bottom {
        margin-top: 15px
    }

    .order-graph .order-graph-bottom h6 {
        margin-right: 0
    }

    .order-graph .order-graph-bottom .media {
        margin-bottom: 15px
    }

    .offcanvas .page-wrapper .page-body-wrapper .page-header .row h3 {
        font-size: 22px
    }

    .offcanvas .page-wrapper .page-body-wrapper .page-body:before {
        left: 0
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .d-sm-none:after {
        display: none
    }

    ul.notification-dropdown.onhover-show-div {
        width: 284px;
        right: -112px
    }

    ul.notification-dropdown.onhover-show-div:before,
    ul.notification-dropdown.onhover-show-div:after {
        right: 135px !important
    }


    .page-wrapper .page-main-header .main-header-right .nav-right>ul {
        padding: 0 0;
        -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
        justify-content: space-evenly
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul>li:first-child {
        width: auto;
        margin: 0
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:before {
        display: none
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:after {
        display: none
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul .search-form .form-group {
        margin-bottom: 0
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul .search-form .form-control-plaintext.open {
        -webkit-transform: translateY(0px) scaleY(1);
        transform: translateY(0px) scaleY(1);
        opacity: 1;
        visibility: visible;
        -webkit-transition: all linear 0.3s;
        transition: all linear 0.3s
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul .search-form .form-control-plaintext {
        top: 60px;
        position: absolute;
        -webkit-transition: all linear 0.3s;
        transition: all linear 0.3s;
        left: 0;
        background-color: #fff;
        -webkit-transform: translateY(-35px) scaleY(0);
        transform: translateY(-35px) scaleY(0);
        opacity: 0;
        visibility: hidden;
        width: 180px;
        padding: 10px 10px 10px 15px
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul .search-form .mobile-search svg {
        color: #ff4c3b
    }

    .page-wrapper .search-form .form-group {
        margin-right: 0
    }

    .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body .activity .media .gradient-round.gradient-line-1:after {
        bottom: -41px
    }

    .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body .activity .media .gradient-round.small-line:after {
        bottom: -19px;
        height: 12px
    }

    .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body .activity .media .gradient-round.medium-line:after {
        bottom: -34px
    }

    .page-wrapper .page-body-wrapper .page-body {
        padding: 0
    }

    .page-wrapper .page-body-wrapper .page-header {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .product-page-main .owl-stage-outer {
        margin-top: 15px
    }

    .profile-details img {
        margin-bottom: 15px
    }

    .project-status {
        margin-top: 15px
    }

    .project-status .media {
        margin-top: 15px
    }

    .tab2-card ul {
        margin-bottom: 15px
    }

    .tab2-card ul li {
        display: block
    }

    .tab2-card ul li a {
        text-align: center
    }

    .profile-table table tbody tr td:first-child {
        min-width: 150px
    }

    .account-setting h5 {
        margin-bottom: 15px
    }

    .deactivate-account {
        margin-top: 15px
    }

    .deactivate-account .btn {
        margin-top: 15px
    }

    .sm-label-radio {
        margin-bottom: 4px !important
    }

    .permission-block .attribute-blocks .form-group {
        padding-bottom: 15px
    }

    .needs-validation h4 {
        margin-bottom: 15px
    }

    .needs-validation .permission-block .attribute-blocks .row {
        padding-left: 15px
    }

    .needs-validation .permission-block .attribute-blocks+.attribute-blocks h5 {
        margin-top: 20px
    }

    .needs-validation .radio_animated {
        margin: 0 6px 0 0
    }

    .needs-validation .radio-animated label {
        margin-right: 15px
    }

    .translation-list table tr td:nth-child(3),
    .translation-list table tr td:nth-child(4),
    .translation-list table tr th:nth-child(3),
    .translation-list table tr th:nth-child(4) {
        width: 180px !important
    }

    .product-list table tr td,
    .product-list table tr th {
        width: 100px !important
    }

    .product-list table tr td:nth-child(2),
    .product-list table tr th:nth-child(2) {
        width: 250px !important
    }

    .product-list table tr td:nth-child(3),
    .product-list table tr th:nth-child(3) {
        width: 120px !important
    }

    .digital-product table tr td:nth-child(2),
    .digital-product table tr th:nth-child(2) {
        width: 120px !important
    }

    .add-product-form .form-group .form-control {
        width: 93%;
        margin: 0 auto
    }

    .add-product-form .form-group label {
        padding: 0
    }

    .add-product-form .qty-box {
        width: 162px
    }

    .add-product-form .qty-box .input-group .form-control {
        width: 80px
    }

    .add-product-form .description-sm {
        padding: 0 !important
    }

    .zoomContainer {
        top: 184px !important
    }

    .add-product ul li .box-input-file {
        width: 30px;
        height: 30px
    }

    .dataTables_wrapper .dataTables_paginate {
        margin-top: 15px !important;
        margin-left: 0 !important
    }

    .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 1px 4px !important
    }

    .dataTables_wrapper .dataTables_length {
        margin-bottom: 15px
    }

    .dataTables_wrapper table.dataTable {
        margin-top: 15px !important
    }
}

@media (max-width: 420px) {
    h2 {
        font-size: 22px
    }

    .team h6 {
        line-height: 15px
    }

    .product-right .product-buttons .btn-solid,
    .product-right .product-buttons .btn-outline {
        padding: 7px 8px
    }

    .product-right.product-form-box .timer {
        padding-left: 29px
    }

    .product-right.product-form-box .timer span {
        width: 45px
    }

    .product-right .timer {
        padding-left: 35px
    }

    .product-right .timer span {
        width: 45px
    }

    .product-right .product-icon .product-social {
        margin-top: 0
    }

    .product-right .product-icon .product-social li {
        padding-right: 5px
    }

    .product-right .product-icon .product-social li a i {
        font-size: 14px
    }

    .product-right .product-icon .wishlist-btn i {
        font-size: 14px;
        padding-left: 10px;
        margin-left: 5px
    }

    .product-right .product-icon .wishlist-btn span {
        font-size: 14px
    }

    .order-box .sub-total .shipping {
        width: unset;
        float: unset;
        display: block
    }

    .order-box .sub-total .shipping .shopping-option label {
        margin-bottom: 0
    }

    .order-box .sub-total .shipping .shopping-option:last-child {
        padding-left: 0
    }

    .timer span .padding-l {
        padding-left: 5px
    }

    .search-box {
        width: 100% !important
    }
}

@media (max-width: 360px) {
    h2 {
        font-size: 25px
    }

    .product-right .timer {
        padding-left: 25px
    }

    .product-right .timer span .padding-l {
        padding-left: 10px
    }

    .product-right .product-buttons a:last-child {
        margin-left: 6px
    }

    .btn-solid,
    .btn-outline {
        padding: 10px 15px
    }

    .authentication-box {
        padding: 30px 15px
    }

    .authentication-box .forgot-pass {
        float: none;
        padding-top: 10px
    }

    .authentication-box .container .form-group {
        margin-bottom: 1rem
    }

    .authentication-box .container h3 {
        font-size: 20px
    }

    .authentication-box .container p {
        line-height: 1.6
    }

    .authentication-box .container .bg-primary {
        padding: 30px 15px
    }

    .authentication-box .container .svg-icon {
        padding: 17px;
        height: 90px;
        width: 90px;
        margin-bottom: 25px
    }

    .authentication-box .container .svg-icon svg {
        height: 50px
    }

    .nav-menus .notification-badge {
        right: 0
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul>li {
        padding: 0 13px
    }

    .page-wrapper .page-main-header .main-header-right .nav-right>ul>li .dot {
        right: 13px
    }

    .footer {
        padding-left: 0;
        padding-right: 0 !important
    }

    .footer p {
        font-size: 12px
    }

    .add-product img {
        width: 300px
    }

    .product-page-main .btn {
        padding: 5px 12px
    }
}

body.rtl {
    direction: rtl
}

.rtl ul {
    -webkit-padding-start: 0
}

.rtl .pre-2,
.rtl .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important
}

.rtl .me-1,
.rtl .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0 !important
}

.rtl .me-2,
.rtl .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important
}

.rtl .ms-2,
.rtl .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important
}

.rtl .me-3,
.rtl .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important
}

.rtl .ms-3,
.rtl .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important
}

.rtl .ms-auto,
.rtl .mx-auto {
    margin-left: unset !important;
    margin-right: auto !important
}

.rtl .pe-0,
.rtl .px-0 {
    padding-right: unset !important;
    padding-left: 0 !important
}

.rtl .pe-2 {
    padding-left: 0.5rem !important;
    padding-right: 0 !important
}

.rtl .text-end {
    text-align: left !important
}

.rtl .text-start {
    text-align: right !important
}

.rtl .theme-form .form-group {
    text-align: right
}

.rtl .form-check {
    padding-left: 0;
    padding-right: 1.5em
}

.rtl .form-check .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0
}

.rtl .card-details-title {
    background: var(--theme-color);
    background: -webkit-gradient(linear, right top, left top, from(rgba(255, 76, 59, 0.2)), color-stop(80%, rgba(255, 76, 59, 0)));
    background: linear-gradient(270deg, rgba(255, 76, 59, 0.2) 0%, rgba(255, 76, 59, 0) 80%);
    padding: 20px 10px;
    border-radius: 5px
}

.rtl .order-details-table .table-details .table tfoot tr td {
    text-align: right
}

.rtl .pixelstrap ul a {
    text-align: right
}

.rtl .pixelstrap a .sub-arrow {
    right: auto;
    left: 10px
}

.rtl .pixelstrap .clothing-menu .link-section {
    text-align: right
}

.rtl .sm,
.rtl .sm ul,
.rtl .sm li {
    direction: rtl
}

.rtl .onhover-dropdown .onhover-show-div {
    right: -90px !important;
    direction: ltr
}

.rtl .onhover-dropdown:before {
    left: 3px;
    right: unset
}

.rtl .pixelstrap .full-mega-menu ul a:before {
    right: 0
}

.rtl header .main-navbar .nav-menu>li {
    float: right
}

.rtl header .main-navbar .nav-menu>li>a {
    padding-right: 0;
    padding-left: 45px
}

.rtl header .main-navbar .nav-menu>li>a .sub-arrow {
    right: auto;
    left: 7px
}

.rtl header .main-navbar .nav-menu>li .mega-menu-container .mega-box {
    text-align: right
}

.rtl header .main-navbar .nav-menu>li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
    right: 0
}

.rtl header .main-navbar .nav-menu>li .nav-submenu {
    text-align: right
}

.rtl header .main-navbar .nav-menu>li .nav-submenu li a i {
    float: left
}

.rtl header .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu {
    left: -203px;
    right: unset
}

.rtl header .sidenav .sidebar-menu li {
    direction: rtl;
    text-align: right
}

.rtl .trans-table thead tr th:first-child {
    text-align: right !important
}

.rtl .trans-table thead tr th:last-child {
    text-align: right !important
}

.rtl .trans-table tbody tr td:first-child {
    text-align: right !important
}

.rtl .trans-table tbody tr td:last-child {
    text-align: right !important
}

.rtl .all-package thead tr th:first-child {
    text-align: center
}

.rtl .all-package tbody tr td:first-child {
    text-align: center
}

.rtl .sidenav {
    right: -300px;
    left: unset
}

.rtl .sidenav.open-side {
    right: 0
}

.rtl .full-slider {
    direction: ltr
}

.rtl .cart-qty-cls {
    right: unset;
    left: -8px
}

.rtl .product-box .product-detail,
.rtl .product-box .product-info {
    text-align: right;
    padding-left: 0;
    padding-right: 5px
}

.rtl .product-box .img-wrapper .product-thumb-list {
    right: 0;
    left: unset
}

.rtl .product-box .cart-info.cart-wrap,
.rtl .product-box .cart-wrap.cart-wrap {
    text-align: left;
    left: 0;
    right: unset
}

.rtl .product-box .product-info {
    text-align: center
}

.rtl .product-box .cart-detail {
    right: unset;
    left: 20px
}

.rtl .product-box .back {
    right: 0;
    left: unset;
    -webkit-transform: translateX(100px);
    transform: translateX(100px)
}

.rtl .product-right .product-buttons a:last-child,
.rtl .product-right .product-buttons button:last-child {
    margin-left: 0;
    margin-right: 10px
}

.rtl .left-sidebar_space {
    padding-left: 0;
    padding-right: 300px
}

.rtl .breadcrumb.pull-right {
    float: left !important
}

.rtl .breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding-left: 0;
    padding-right: 0.5rem
}

.rtl .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
    padding-left: 0.5rem;
    padding-right: 0;
    float: right
}

.rtl .custom-control {
    padding-left: 0;
    padding-right: 1.5rem;
    text-align: right
}

.rtl .custom-control-input {
    right: 0;
    left: unset
}

.rtl .custom-control-label:after {
    left: unset;
    right: -1.5rem
}

.rtl .custom-control-label:before {
    right: -1.5rem;
    left: unset
}

.rtl .product-filter-tags {
    text-align: right
}

.rtl .product-filter-tags li a i {
    margin-left: 0;
    margin-right: 5px
}

.rtl .product-right {
    text-align: right
}

.rtl .product-right h4 span {
    padding-left: 0;
    padding-right: 5px
}

.rtl .product-right .product-description h6 span {
    float: left
}

.rtl .product-right .size-box ul li {
    margin-right: 0;
    margin-left: 10px
}

.rtl .product-right .product-buttons a:last-child {
    margin-left: 0;
    margin-right: 10px
}

.rtl .product-right .product-icon .product-social li {
    padding-right: 0;
    padding-left: 30px
}

.rtl .product-right .product-icon .product-social li:last-child {
    padding-left: 0
}

.rtl .product-right .product-icon .wishlist-btn span {
    padding-left: 0;
    padding-right: 10px
}

.rtl .product-right .product-icon .wishlist-btn i {
    padding-left: 0;
    margin-left: 0;
    padding-right: 10px;
    margin-right: 5px;
    border-left: none;
    border-right: 1px solid #dddddd
}

.rtl .product-right.product-form-box {
    text-align: center
}

.rtl .product-right.product-form-box .timer p {
    text-align: right
}

.rtl .product-right.product-form-box .product-description {
    text-align: center
}

.rtl .product-right .product-count img {
    margin-right: 0;
    margin-left: 6px
}

.rtl .product-right .product-count ul li:first-child {
    margin-right: 0;
    margin-left: 14px
}

.rtl .product-right .rating-section h6 {
    margin-left: 0;
    margin-right: 10px
}

.rtl .product-right .label-section .label-text {
    padding-left: 0;
    padding-right: 5px
}

.rtl .project-status .media .media-body {
    margin-right: 0
}

.rtl .project-status .media .media-body h6 .pull-right {
    float: left
}

.rtl .timer {
    text-align: right
}

.rtl .timer span .padding-l {
    padding-left: 0;
    padding-right: 22px
}

.rtl .border-product {
    text-align: right
}

.rtl .element-detail {
    text-align: right
}

.rtl .modal-header .close {
    margin: -1rem auto -1rem -1rem
}

.rtl .modal-dialog .modal-content .modal-body {
    text-align: right
}

.rtl .dashboard .box .box-title>a {
    right: unset;
    left: 0
}

.rtl .order-box .title-box span {
    float: left
}

.rtl .order-box .qty li span {
    float: left
}

.rtl .order-box .sub-total .shipping {
    float: left
}

.rtl .order-box .sub-total li .count {
    float: left
}

.rtl .order-box .sub-total .shopping-option label {
    padding-right: 10px;
    padding-left: 0
}

.rtl .order-box .total li span {
    float: left
}

.rtl .comnt-sec li a i {
    margin-right: 0;
    margin-left: 5px
}

.rtl .blog-sidebar {
    text-align: right
}

.rtl.christmas {
    overflow-x: hidden !important
}

.rtl.modal-open {
    overflow-x: hidden !important
}

.rtl .product-box .img-wrapper .lable-block .lable3 {
    right: 7px;
    left: unset
}

.rtl .product-box .img-wrapper .lable-block .lable4 {
    left: 7px;
    right: unset
}

.rtl .space_sm {
    padding-left: 0;
    padding-right: 240px
}

.rtl .gradient-slider .product-box {
    direction: rtl
}

.custom-theme {
    position: fixed;
    right: 0;
    width: 40px;
    height: 38px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f7f7f7;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    cursor: pointer
}

body.rtl .m-r-15 {
    margin-left: 15px;
    margin-right: unset
}

body.rtl .me-2,
body.rtl .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important
}

body.rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem
}

body.rtl .ps-0,
body.rtl .px-0 {
    padding-right: 0 !important;
    padding-left: unset !important
}

body.rtl .m-r-10 {
    margin-left: 10px;
    margin-right: unset
}

body.rtl .radio_animated,
body.rtl .checkbox_animated {
    margin: 0 0 0 1rem
}

body.rtl .digital-add .form-group .radio_animated {
    margin-left: 8px;
    margin-right: unset
}

body.rtl .needs-validation .form-group {
    text-align: right
}

body.rtl .needs-validation .form-group .checkbox {
    padding-right: 0;
    padding-left: unset
}

body.rtl .needs-validation .permission-block .attribute-blocks {
    padding-right: 15px;
    padding-left: unset;
    text-align: right
}

body.rtl .needs-validation .permission-block .attribute-blocks .row {
    padding-right: 20px;
    padding-left: unset
}

body.rtl .needs-validation .checkbox {
    right: 0;
    margin-left: -16px
}

body.rtl .needs-validation .checkbox label {
    padding-right: 16px;
    padding-left: unset
}

body.rtl .needs-validation .checkbox label::before {
    right: 0;
    left: unset;
    margin-left: unset;
    margin-right: -16px
}

body.rtl .needs-validation .radio-animated label {
    margin-left: 20px;
    margin-right: unset
}

body.rtl .modal-footer>:not(:last-child) {
    margin-left: .25rem;
    margin-right: unset
}

body.rtl .jsgrid-button+.jsgrid-button {
    margin-right: 5px;
    margin-left: unset
}

body.rtl .custom-theme {
    left: 0;
    right: unset;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px
}

body.rtl .text-right {
    text-align: left !important
}

body.rtl .br-wrapper {
    text-align: right
}

body.rtl .custom-theme li {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset
}

body.rtl .card .card-header .card-header-right {
    left: 20px;
    right: unset
}

body.rtl .card .card-header .card-header-right .card-option {
    text-align: left
}

body.rtl .card .card-header .card-header-right .card-option.list-unstyled {
    padding-right: 0;
    padding-left: unset
}

body.rtl code[class*=language-],
body.rtl pre[class*=language-] {
    text-align: right
}

body.rtl .code-box-copy__btn {
    right: unset;
    left: 11px
}

body.rtl table thead tr th,
body.rtl table thead tr td,
body.rtl table tbody tr th,
body.rtl table tbody tr td {
    text-align: right
}

body.rtl .pull-right {
    right: unset;
    left: 10px
}

body.rtl .status-circle {
    right: 40px;
    left: unset
}

body.rtl .right-sidebar.show {
    left: 0;
    right: unset
}

body.rtl .right-sidebar .friend-list-search .fas {
    left: 35px;
    right: unset
}

body.rtl .right-sidebar .chat-box .about {
    float: right;
    padding-right: 10px;
    padding-left: unset;
    text-align: right
}

body.rtl .right-sidebar .chat-box .friend-list {
    text-align: right
}

body.rtl .right-sidebar .chat-box .user-image {
    float: right;
    margin-left: 5px;
    margin-right: 0
}

body.rtl .nav-menus .search-form.search-box input {
    padding: 10px 70px 10px 10px;
    right: 0 !important
}

body.rtl .nav-menus .search-form input {
    padding: 10px 70px 10px 10px;
    right: 0 !important
}

body.rtl ul.notification-dropdown.onhover-show-div li {
    text-align: right
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar {
    right: 0 !important
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open .sidebar-back {
    left: unset;
    right: -30px
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-back {
    left: unset;
    right: 265px
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6,
body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
    text-align: right
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a:not(.active):hover {
    background: #fff;
    background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.2)), color-stop(80%, rgba(255, 255, 255, 0)));
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%)
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu>li>a.active {
    background: #fff;
    background: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0.2)), color-stop(80%, rgba(255, 255, 255, 0)));
    background: linear-gradient(-90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%)
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li {
    text-align: right
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active>.sidebar-submenu {
    margin-right: 15px;
    margin-left: unset
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li a:not(.active):hover::before {
    right: 0;
    left: unset
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li a.active::before {
    left: unset;
    right: 0
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a {
    padding-right: 30px;
    padding-left: unset
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a>.fa-circle {
    right: 12px;
    left: unset
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu>li>a>.fa-angle-right {
    margin: 10px 0 0 30px;
    float: left
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
    margin-right: unset;
    margin-left: 14px
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header .pull-right {
    float: left
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar~.page-body {
    margin-right: 255px;
    margin-left: unset
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open {
    margin-right: calc(-260px);
    margin-left: unset
}

body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open~.page-body,
body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open~footer {
    margin-right: 0
}

body.rtl .page-wrapper .page-body-wrapper .page-header .row h3 {
    text-align: right
}

body.rtl .page-wrapper .page-body-wrapper footer {
    margin-right: 255px;
    margin-left: unset
}

body.rtl .page-wrapper .page-body-wrapper footer .footer-copyright {
    text-align: right !important
}

body.rtl .page-wrapper .page-body-wrapper footer .pull-right {
    text-align: left
}

body.rtl .page-wrapper .page-body-wrapper footer p i {
    margin-right: 5px;
    margin-left: unset
}

body.rtl .page-main-header {
    margin-right: 255px;
    margin-left: unset
}

body.rtl .page-main-header .main-header-right .logo-wrapper img {
    margin-left: 15px;
    margin-right: unset
}

body.rtl .page-main-header .main-header-right .nav-right {
    text-align: left
}

body.rtl .page-main-header .main-header-right .nav-right>.mobile-toggle.pull-right {
    float: left
}

body.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li svg {
    margin-right: unset;
    margin-left: 10px
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li {
    text-align: right;
    border-right: 1px solid #eff0f1;
    border-left: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li:nth-child(5) {
    padding-left: unset;
    padding-right: 20px;
    border-left: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li:before {
    right: 0;
    left: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li .dot {
    left: 17px;
    right: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li .media .dotted-animation {
    left: -3px;
    right: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li .media .dotted-animation .main-circle {
    left: 6px;
    right: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li .media .dotted-animation .animate-circle {
    left: -4px;
    right: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li.onhover-dropdown:hover .onhover-show-div:before {
    right: 80%;
    left: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li.onhover-dropdown:hover .onhover-show-div:after {
    right: 80%;
    left: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li.onhover-dropdown:last-child {
    padding-left: unset;
    padding-right: unset;
    padding-right: 20px;
    border-right: none
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li:first-child {
    border-right: none
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:after {
    right: 22px;
    left: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li:first-child .search-form .form-group:before {
    right: 51px;
    left: unset
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li:last-child {
    padding-right: 20px;
    padding-left: 0
}

body.rtl .page-main-header .main-header-right .nav-right>ul>li i.ml-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important
}

body.rtl .page-main-header .main-header-right .nav-right .language-dropdown {
    text-align: right
}

body.rtl .page-main-header .main-header-right .nav-right .language-dropdown li a i {
    margin-left: 10px
}

body.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
    margin-right: 10px;
    margin-left: unset
}

body.rtl .page-main-header .main-header-right .mobile-sidebar {
    padding-right: 20px
}

body.rtl .page-main-header.open {
    margin-right: 0
}

body.rtl ul.notification-dropdown.onhover-show-div {
    right: initial;
    left: -10px;
    padding: 0
}

body.rtl ul.notification-dropdown.onhover-show-div:before {
    right: inherit !important;
    left: 35px !important
}

body.rtl ul.notification-dropdown.onhover-show-div:after {
    right: inherit !important;
    left: 35px !important
}

body.rtl ul.notification-dropdown.onhover-show-div li {
    margin-right: 0 !important
}

body.rtl ul.notification-dropdown.onhover-show-div li span.badge {
    margin-left: unset !important
}

body.rtl ul.notification-dropdown.onhover-show-div li span svg {
    margin-right: 10px;
    margin-left: unset
}

body.rtl ul.notification-dropdown.onhover-show-div li .notification-icon {
    margin-left: 20px;
    margin-right: unset
}

body.rtl ul.notification-dropdown.onhover-show-div p {
    margin-left: 0
}

body.rtl .owl-carousel {
    direction: ltr
}

body.rtl .media .media-body {
    text-align: right;
    margin-right: 20px;
    margin-left: unset
}

body.rtl .order-graph .order-graph-bottom h6 {
    margin: 0 15px 0 30px !important
}

body.rtl .latest-order-table .btn {
    float: right
}

body.rtl .product-physical table tr th:nth-child(2),
body.rtl .product-physical table tr td:nth-child(2) {
    text-align: right
}

body.rtl .product-page-details .color-variant,
body.rtl .product-page-details .size-box,
body.rtl .product-right .color-variant,
body.rtl .product-right .size-box {
    text-align: right
}

body.rtl .add-product-form .offset-sm-4 {
    text-align: right
}

body.rtl .timer {
    float: right
}

body.rtl .timer~.m-t-15 {
    clear: both;
    text-align: right;
    padding-top: 15px
}

body.rtl .timer span .padding-l {
    right: 35px;
    left: unset;
    padding-right: 0
}

body.rtl .product-list table tr td,
body.rtl .product-list table tr th,
body.rtl .report-table table tr td,
body.rtl .report-table table tr th {
    text-align: right !important
}

body.rtl .product-list table tr td:last-child,
body.rtl .product-list table tr th:last-child,
body.rtl .report-table table tr td:last-child,
body.rtl .report-table table tr th:last-child {
    text-align: center !important
}

body.rtl .dropzone .dz-preview .dz-error-mark,
body.rtl .dropzone .dz-preview .dz-success-mark {
    left: unset;
    right: 48%;
    margin-right: -27px;
    margin-left: unset
}

body.rtl .dropzone.dz-clickable .dz-message * {
    text-align: center
}

body.rtl .digital-add .col-form-label {
    display: block;
    text-align: right
}

body.rtl .dataTables_wrapper .dataTables_filter input[type="search"] {
    margin-right: 10px;
    margin-left: unset
}

body.rtl .dataTables_wrapper table.dataTable .vendor-list img {
    margin-left: 20px;
    margin-right: unset
}

body.rtl .category-table table tr td:nth-child(3),
body.rtl .category-table table tr th:nth-child(3) {
    text-align: right
}

body.rtl .media-table table tr td:last-child,
body.rtl .media-table table tr th:last-child {
    text-align: right !important
}

body.rtl .profile-details h5 {
    text-align: center
}

body.rtl .account-setting {
    text-align: right
}

body.rtl .table-category thead tr th:nth-child(2) {
    text-align: right
}

body.rtl .table-category tbody tr td:nth-child(2) {
    text-align: right
}

body.rtl .table-category thead tr th {
    text-align: center
}

body.rtl .table-category thead tr th:first-child {
    text-align: right
}

body.rtl .table-category tbody tr td {
    text-align: center
}

body.rtl .table-category tbody tr td:first-child {
    text-align: right
}

@media (min-width: 576px) {
    .rtl .me-sm-3 {
        margin-left: 1rem !important;
        margin-right: 0 !important
    }
}

@media only screen and (max-width: 991px) {
    .page-wrapper .page-main-header {
        margin-right: 0 !important;
        width: 100%
    }

    .page-wrapper .page-body-wrapper .page-sidebar~.page-body,
    .page-wrapper .page-body-wrapper .page-sidebar~footer {
        margin-right: 0
    }
}

@media only screen and (max-width: 767px) {
    .needs-validation .form-group .checkbox {
        padding-right: 15px;
        padding-left: unset
    }

    .page-wrapper .page-body-wrapper footer p {
        text-align: center
    }
}

@media (min-width: 768px) {
    .rtl .offset-md-2 {
        margin-left: 0;
        margin-right: 16.66667%
    }
}

@media (min-width: 992px) {
    .rtl .offset-lg-2 {
        margin-right: 16.66667%;
        margin-left: unset
    }

    .rtl .offset-lg-4 {
        margin-left: unset;
        margin-right: 33.3333333333%
    }

    .rtl .offset-lg-3 {
        margin-right: 25%;
        margin-left: 0
    }
}

@media (min-width: 1200px) {
    .rtl .offset-xl-1 {
        margin-right: 8.33333%;
        margin-left: 0
    }

    .rtl header .main-navbar .nav-menu>li .nav-submenu li a:before {
        right: 35px
    }

    .rtl .sm-vertical a {
        text-align: right
    }

    .rtl .sm-horizontal>li {
        float: right
    }

    .rtl .sm-horizontal>li>a,
    .rtl .sm-horizontal>li>a:hover,
    .rtl .sm-horizontal>li>a:active {
        padding-left: 45px;
        padding-right: 0
    }

    .rtl .pixelstrap ul a:before {
        right: 35px
    }

    .rtl .pixelstrap .full-mega-menu .mega-box {
        text-align: right
    }

    .rtl .pixelstrap .mega ul {
        margin-left: auto !important
    }

    .rtl .pixelstrap.sm-vertical .mega-menu ul a:before {
        right: 0
    }

    .rtl .center-margin {
        margin-left: -30px;
        margin-right: 0
    }

    .rtl .offset-xl-3 {
        margin-right: 25%;
        margin-left: unset
    }
}

@media (max-width: 1430px) {
    .rtl .left-sidebar_space {
        padding-right: 0
    }

    .rtl .product-right .product-icon .product-social li {
        padding-left: 10px
    }

    .rtl .product-form-box .timer {
        padding-right: 17px
    }
}

@media (max-width: 1367px) {
    .rtl .timer span .padding-l {
        padding-right: 5px
    }
}

@media (max-width: 1199px) {
    .rtl .pixelstrap .link-section .menu-title .according-menu:before {
        left: -18px;
        right: unset
    }

    .rtl .pixelstrap .link-section .menu-title.active .according-menu:before {
        left: -18px;
        right: unset
    }

    .rtl .pixelstrap li a {
        text-align: right
    }

    .rtl .pixelstrap li .lable-nav {
        right: 15px;
        left: unset
    }

    .rtl .pixelstrap .full-mega-menu .mega-box {
        text-align: right
    }

    .rtl .space_sm {
        padding-right: 0
    }

    .rtl header .main-navbar .nav-menu li {
        text-align: right
    }

    .rtl header .main-navbar .nav-menu>li a .sub-arrow {
        left: 0;
        right: unset
    }

    .rtl header .main-navbar .nav-menu>li .nav-submenu li .nav-sub-childmenu {
        left: 0
    }

    .rtl .sidenav .sidebar-menu li {
        direction: rtl;
        text-align: right
    }

    .rtl .sidenav .sidebar-menu li a .sub-arrow {
        right: auto;
        left: 15px
    }

    .rtl .main-nav-center .toggle-nav {
        left: 175px;
        right: unset
    }

    .rtl .product-right .product-icon .product-social li {
        padding-left: 4px
    }

    .rtl .product-right .product-icon .wishlist-btn span {
        padding-right: 4px
    }

    .rtl .product-form-box.product-right .timer {
        padding-left: 0
    }
}

@media (max-width: 991px) {
    .rtl .product-right {
        text-align: center
    }

    .rtl .product-right .size-text {
        text-align: right
    }

    .rtl .product-right .timer {
        text-align: right
    }

    .rtl .product-right .product-icon .product-social li {
        padding-left: 20px
    }

    .rtl .border-product {
        text-align: center
    }
}

@media (max-width: 767px) {
    .rtl .footer-title h4 {
        text-align: right
    }

    .rtl .footer-title .according-menu:before {
        left: 2px;
        right: unset
    }

    .rtl .footer-title.active .according-menu:before {
        left: 2px;
        right: unset
    }

    .rtl .footer-contant {
        text-align: right
    }

    .rtl .product-box .cart-detail {
        left: 10px;
        right: unset
    }

    .rtl .layout2-logo {
        padding-left: 70px;
        padding-right: 0
    }
}

@media (max-width: 577px) {
    .rtl .main-nav-center .toggle-nav {
        right: unset;
        left: 15px
    }

    .rtl .layout2-logo {
        padding-left: 0
    }
}

@media (max-width: 420px) {
    .rtl .product-right .product-icon .product-social li {
        padding-left: 5px
    }

    .rtl .timer span .padding-l {
        padding-right: 5px
    }

    .rtl .order-box .sub-total .shipping .shopping-option:last-child {
        padding-left: 0;
        padding-right: 20px
    }
}

@media (max-width: 360px) {
    .rtl .timer span .padding-l {
        padding-right: 10px
    }

    .rtl .onhover-dropdown .onhover-show-div {
        right: -170px !important;
        direction: ltr;
        width: 294px;
        margin: 0 25px
    }
}
.image-bar{
    /* width: 200px; */
    width: 150px;
    height:auto 
}
.cursor-pointer {
    cursor: pointer;
  }

  .all-package{
  font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
color: #232323;
text-align: center;
min-width: 180px;
background-color: #f8f8f9;
padding: 30px 15px;
  }

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--theme-color) !important;
}